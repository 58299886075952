import React, {Component} from "react";
import {connect} from "react-redux";
import {
  getContributingLawyer,
  acceptContributingLawyer,
  deleteContributingLawyer
} from "./../../store/actions/admin";
import BreadcrumbsDashboard from "./breadcrumbsDashboard";
import Modal from "react-responsive-modal";
import {ORG_TYPES, JURISDICTIONS, PRACTICE_AREA} from "./Organizations";

class ContributingLawyersView extends Component {
  state = {
    openAccess: false,
    openDelete: false,
    error: ""
  };

  async componentDidMount() {
    await this.props.getContributingLawyer(this.props.match.params.id);
  }

  onOpenAccessModal = () => {
    this.setState({openAccess: true});
  };

  onCloseAccessModal = () => {
    this.setState({openAccess: false});
  };
  onOpenDeleteModal = () => {
    this.setState({openDelete: true});
  };
  onCloseDeleteModal = () => {
    this.setState({openDelete: false});
  };
  approveContributor = async id => {
    const res = await this.props.acceptContributingLawyer(id);

    if (res && res.payload.status === 200) {
      this.onCloseAccessModal();
      this.props.history.push("/admin/contribute-lawyers");
    } else {
      this.setState({error: "somthing went wrong"});
    }
  };
  deleteContributor = async id => {
    const res = await this.props.deleteContributingLawyer(id);

    if (res && res.payload.status === 204) {
      this.onCloseAccessModal();
      this.props.history.push("/admin/contribute-lawyers");
    } else {
      this.setState({error: "somthing went wrong"});
    }
  };

  render() {
    const contributingLawyer = this.props.contributingLawyer;
    const {openAccess, openDelete, error} = this.state;

    return (
      <div>
        <BreadcrumbsDashboard type={'lawyer'} />
        <main className="admin-content manage">
          <div className="container-sm">
            <h2 className="font-secondary fw-600 mb-5">
              CONTRIBUTING LAWYER INFORMATION
            </h2>
            {contributingLawyer && (
              <form action="">
                <div className="form-details flex border-bottom black-10">
                  <ul className="list-view w-50 mr-2">
                    <li>
                      <label className="label">First Name</label>
                      <p className="value">{contributingLawyer.first_name}</p>
                    </li>
                    <li>
                      <label className="label">Last name</label>
                      <p className="value">{contributingLawyer.last_name}</p>
                    </li>
                    <li>
                      <label className="label">Email address</label>
                      <p className="value">{contributingLawyer.email}</p>
                    </li>
                    <li>
                      <label className="label">Phone number</label>
                      <p className="value">{contributingLawyer.phone_number}</p>
                    </li>
                    <li>
                      <label className="label">Other country</label>
                      <p className="value">{contributingLawyer.other_country}</p>
                    </li>
                    <li>
                      <label className="label">Organization name</label>
                      <p className="value">
                        {contributingLawyer.organization_name}
                      </p>
                    </li>
                    <li>
                      <label className="label">Organization type</label>
                      <p className="value">
                        {ORG_TYPES[contributingLawyer.organization_type]}
                      </p>
                    </li>
                  </ul>
                  <ul className="list-view w-50 ml-2">
                    <li>
                      <label className="label">
                        Address of the Organization
                      </label>
                      <p className="value">
                        {contributingLawyer.organization_address}
                      </p>
                    </li>
                    <li>
                      <label className="label">City of Organization</label>
                      <p className="value">
                        {contributingLawyer.organization_city}
                      </p>
                    </li>
                    <li>
                      <label className="label">Organization's Website</label>
                      <p className="value">
                        {contributingLawyer.organization_url}
                      </p>
                    </li>
                    <li>
                      <label className="label">
                        Are you licensed to practice law?
                      </label>
                      <p className="value">
                        {contributingLawyer.is_licensed ? "Yes" : "No"}
                      </p>
                    </li>
                    <li>
                      <label className="label">
                        In which jurisdictions are you licensed?
                      </label>

                      {contributingLawyer.jurisdictions.map(data => (
                        <p className="value">{`${
                          contributingLawyer.jurisdictions.length > 1
                            ? JURISDICTIONS[data] + ","
                            : JURISDICTIONS[data]
                        }`}</p>
                      ))}
                    </li>
                    <li>
                      <label className="label">
                        Which are your primary areas of practice?
                      </label>
                      <p className="value">
                        {PRACTICE_AREA[contributingLawyer.practice_areas]}
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="buttons mt-6 flex justify-end">
                  <button
                    type="button"
                    className="btn outline border-black-20 text-warning br-round h-lg w-lg mr-2"
                    onClick={this.onOpenDeleteModal}
                  >
                    <i className="icon-close mr-3">{""}</i>DELETE
                  </button>
                  {/*  Delete confirmation popup start  */}
                  <Modal
                    open={openDelete}
                    onClose={this.onCloseDeleteModal}
                    center
                  >
                    <div className="popup-content">
                      <form action="">
                        <p className="fs-lg text-xs-left color-black-60 mt-5 ml-2">
                          {`Are you sure that you want to delete Contributing
                          Lawyer ${contributingLawyer.first_name} ${
                            contributingLawyer.last_name
                          }?`}
                        </p>

                        <div className="buttons mt-10 text-xs-right">
                          {error && <p className="popup-error-msg">{error}</p>}
                          <button
                            className="btn filled primary br-round h-lg w-md fs-md shadow ml-3"
                            type="button"
                            onClick={() =>
                              this.deleteContributor(contributingLawyer.id)
                            }
                          >
                            Yes
                          </button>
                          <button
                            className="btn link primary h-lg w-md fs-md"
                            type="button"
                            onClick={this.onCloseDeleteModal}
                          >
                            No
                          </button>
                        </div>
                      </form>
                    </div>
                  </Modal>
                  {/* Delete confirmation popup end */}
                  {/*
                  <button
                    type="button"
                    className="btn filled primary br-round h-lg w-xl ml-2 flex align-center justify-center"
                    onClick={this.onOpenAccessModal}
                  >
                    <i className="icon-check mr-3 fs-lg">{""}</i>APPROVE
                  </button>
                  */}
                  {/*  Approve confirmation popup start */}
                  <Modal
                    open={openAccess}
                    onClose={this.onCloseAccessModal}
                    center
                  >
                    <div className="popup-content">
                      <form action="">
                        <p className="fs-lg text-xs-left color-black-60 mt-5 ml-2">
                          {`Are you sure that you want to approve Contributing
                          Lawyer's permissions for ${
                            contributingLawyer.first_name
                          } ${contributingLawyer.last_name}?`}
                        </p>

                        <div className="buttons mt-10 text-xs-right">
                          {error && <p className="popup-error-msg">{error}</p>}
                          <button
                            type="button"
                            className="btn filled primary br-round h-lg w-md fs-md shadow ml-3"
                            onClick={() =>
                              this.approveContributor(contributingLawyer.id)
                            }
                          >
                            Yes
                          </button>
                          <button
                            type="button"
                            className="btn link primary h-lg w-md fs-md"
                            onClick={this.onCloseAccessModal}
                          >
                            No
                          </button>
                        </div>
                      </form>
                    </div>
                  </Modal>
                  {/* Approve confirmation popup end */}
                </div>
              </form>
            )}
          </div>
        </main>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    contributingLawyer: state.admin.contributingLawyer
  };
}

export default connect(
  mapStateToProps,
  {getContributingLawyer, acceptContributingLawyer, deleteContributingLawyer}
)(ContributingLawyersView);
