import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Icons from "./popUpIcon";

class SuccessPopup extends Component {
  render() {
    const {modal_name, onClose} = this.props;

    console.log(modal_name)

    return (
      /* Success password popup start */
      <div className="popup-content">
        {this.props.title && (
          <h3 className="popup-title">THANK YOU</h3>
        )}
        <form action="" className="flex column align-center">
          <div className="popup-img">
            <img src={Icons[this.props.icon]} alt="Envelop_image" />
          </div>
          <div className="message pt-10">{this.props.text}</div>
          {/* "An email message was sent to your email address. Please check your email to reset the password." */}
          <button
            className="btn filled primary shadow br-round h-lg w-lg mb-4"
            type="button"
            onClick={() => onClose(modal_name? modal_name :'modalSignIn')} >
            DONE
          </button>
        </form>
      </div>
      /* Success password popup end */
    );
  }
}

export default withRouter(SuccessPopup);
