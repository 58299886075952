import React from 'react';

export const  HaveAccount = props => {
    return (
        <p className="fs-md">
            Already have an account?{" "}
            <a
                href="/"
                className="color-primary"
                onClick={e =>{
                    props.handleComponent(e, "login");
                } }
            >
                Login
            </a>
        </p>
    );
};

