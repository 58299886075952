import styled from "styled-components";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Modal from "react-responsive-modal";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import Header from "../header";
import Footer from "../footer";
import SignIn from "../login/login";
import Plan from "./plan";

import {
  getPlans,
  getBenefits,
  getSubscription,
  removeSubscription,
  cancelSubscription,
  getAccessDate,
} from "../../../store/actions/payment";
import { loggedOut } from "../../../store/actions/auth";
import { roleChecker, handleLoguot } from "../../../helpers/improve";
import TitleBar from "../../shared/TitleBar";
import { colors, fontFamilies, mainContentWidth } from "../../../styles";
import OneTimeFeatures from "./OneTimeFeatures";
import AnnualFeatures from "./AnnualFeatures";

const Boxes = styled.div`
  display: flex;
  justify-content: center;
  gap: 96px;
`;

const MainContent = styled.div`
  width: ${mainContentWidth};
  margin: auto;
`;

const Description = styled.div`
  font-size: 18px;
  line-height: 32px;
  margin-top: 80px;
  letter-spacing: 0;
  font-family: ${fontFamilies.serif};
  margin-bottom: 40px;
`;

const BestPlanRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 96px;
  margin-bottom: 16px;
`;

const BestPlanEmptyLeft = styled.div`
  width: 448px;
`;

const BestPlan = styled.div`
  width: 448px;
  text-align: center;
  color: ${colors.red};
  font-weight: 700;
  font-size: 14px;
  line-height: 40px;
`;

const descriptionText =
  "Select a plan to access the model legal documents you need.";

class Pricing extends Component {
  state = {
    component: null,
    modalSignIn: false,
    modalSignUp: false,
    modalNotify: false,
    modalCancellationNotify: false,
    modalCancellationNotifyContent: false,
    modalCancelation: false,
    document_id: null,
    token: "",
    role: "",
    loading: true,
    is_bought: null,
  };

  async componentDidMount() {
    this.props.getPlans();
    this.props.getBenefits();
    const role = roleChecker();
    const token = localStorage.auth || sessionStorage.auth;
    if (role) {
      this.props.getSubscription();
    }
    const { state } = this.props.history.location;
    const document_id = state ? state.document_id : null;
    const is_bought = state ? state.is_bought : null;

    this.setState({
      role,
      token,
      document_id: document_id,
      loading: false,
      is_bought,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.auth.isLoggedIn &&
      this.props.auth.isLoggedIn !== prevProps.auth.isLoggedIn
    ) {
      this.props.getSubscription();
    }
  }

  onOpenModal = (name, component) => this.setState({ [name]: true, component });

  onCloseModal = (name) => {
    const role = roleChecker();
    const token = localStorage.auth || sessionStorage.auth;

    this.setState({ [name]: false, role, token, modalSignIn: false });
  };

  purchaseHandler = async (planData) => {
    const { role, document_id } = this.state;
    const { history, subscription, getAccessDate } = this.props;

    let modalName = "modalSignIn";
    if (role === "user") {
      if (planData.title.startsWith("ANNUAL")) {
        if (
          subscription &&
          subscription.subscription_type === 1 &&
          subscription.cancel_at_period_end
        ) {
          const response = await getAccessDate(subscription.id);
          const message = response.message.replace("[Company name]", "Form");
          return this.setState({
            modalCancellationNotify: true,
            modalCancellationNotifyContent: message,
          });
        } else {
          return history.push("user/order", {
            oneTimePayment: false,
            plan_id: planData.id,
          });
        }
      } else if (document_id) {
        return history.push("user/order", {
          oneTimePayment: true,
          plan_id: planData.id,
          document_id,
        });
      } else {
        modalName = "modalNotify";
      }
    } else {
      this.setState({ component: SignIn });
    }
    this.setState({ [modalName]: true });
  };

  handleLogOut = () => {
    this.props.removeSubscription();
    this.props.loggedOut();
    handleLoguot();
    this.setState({ token: "", role: "" });
  };

  cancelSubscription = async () => {
    const { id } = this.props.subscription;
    try {
      await this.props.cancelSubscription(id);
      this.onCloseModal("modalCancelation");
      this.props.getSubscription();
    } catch (e) {}
  };

  render() {
    const {
      modalNotify,
      modalCancelation,
      is_bought,
      modalCancellationNotify,
      modalCancellationNotifyContent,
    } = this.state;
    const { plans, benefits, subscription } = this.props;
    // hack to make single-document plan appear first in list
    const sortedPlans = _.sortBy(plans, ["price"]);

    return (
      <div>
        <Header />

        <TitleBar
          topMargin="160px"
          title="Pricing"
          backgroundImage="/jd_form_hero_mountain.png"
        />

        <MainContent>
          <Description>{descriptionText}</Description>
          <BestPlanRow>
            <BestPlanEmptyLeft />
            <BestPlan>BEST VALUE</BestPlan>
          </BestPlanRow>
          <Boxes>
            {sortedPlans.length > 0 &&
              sortedPlans.map((plan, index) => (
                <Plan
                  plan={plan}
                  subscription={subscription}
                  purchaseHandler={this.purchaseHandler}
                  cancelSubscription={() =>
                    this.onOpenModal("modalCancelation")
                  }
                  benefits={benefits}
                  key={plan.id}
                  isBought={is_bought}
                  FeatureListComponent={
                    index === 0 ? OneTimeFeatures : AnnualFeatures
                  }
                  title={
                    index === 0 ? "Single Document" : "Annual Subscription"
                  }
                  titleBackgroundColor={
                    index === 0 ? colors.blueDark : colors.blue
                  }
                  price={index === 0 ? "$49.00" : "$499.00"}
                />
              ))}
          </Boxes>
        </MainContent>

        {/* <section className="pricing-header-wrap">
          <div className="container">
            <div className="pricing-header">
              <h2>PLANS AND PRICING</h2>
              <p>Select a plan to access the model legal documents you need.</p>
            </div>
          </div>
        </section> */}

        {/* <section className="user-pricing">
          <div className="container">
            <ul className="pricing-plans">
              {plans.length > 0 &&
                plans.map((plan) => (
                  <Plan
                    plan={plan}
                    subscription={subscription}
                    purchaseHandler={this.purchaseHandler}
                    cancelSubscription={() =>
                      this.onOpenModal("modalCancelation")
                    }
                    benefits={benefits}
                    key={plan.id}
                    isBought={is_bought}
                  />
                ))}
            </ul>
          </div>
        </section> */}
        <Modal
          onClose={() => this.onCloseModal("modalNotify")}
          open={modalNotify}
        >
          <div className="popup-content">
            <p>Please, first choose a document to get access.</p>
          </div>
        </Modal>

        <Modal
          onClose={() => this.onCloseModal("modalCancellationNotify")}
          open={modalCancellationNotify}
        >
          <div className="popup-content">
            <p>{modalCancellationNotifyContent}</p>
            <div className="buttons mt-10 text-xs-">
              <button
                className="btn filled primary br-round h-lg w-lg shadow text-uppercase"
                onClick={() => this.onCloseModal("modalCancellationNotify")}
                type="button"
              >
                Close
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          onClose={() => this.onCloseModal("modalCancelation")}
          open={modalCancelation}
        >
          <div className="popup-content">
            <form action="">
              <p className="fs-lg text-xs-left color-black-60 mt-5 ml-2">
                If you cancel your annual subscription after your payment has
                been processed, you will have access to the services for the
                remainder of your paid subscription period. You will not be
                eligible for a refund of any portion of the subscription fee.
              </p>
              <div className="buttons mt-10 text-xs-right">
                <button
                  type="button"
                  className="btn filled primary br-round h-lg w-md fs-md shadow ml-3"
                  onClick={this.cancelSubscription}
                >
                  Confirm
                </button>
                <button
                  className="btn link primary h-lg w-md fs-md"
                  onClick={() => this.onCloseModal("modalCancelation")}
                  type="button"
                >
                  Cancel
                </button>
              </div>
              {/*{error && <p>{error}</p>}*/}
            </form>
          </div>
        </Modal>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  plans: state.payment.plans,
  benefits: state.payment.benefits,
  subscription: state.payment.subscription,
  auth: state.auth,
  loggedIn: state.auth.loggedIn,
});

const mapDispatchToProps = {
  getPlans,
  getBenefits,
  getSubscription,
  cancelSubscription,
  removeSubscription,
  loggedOut,
  getAccessDate,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Pricing)
);
