import React, { Component } from 'react'
import { Form } from 'formik'


class AddPricingPlan extends Component {
  render () {
    return (
      <div className="popup-content add-credit-card-popup">
        <h3 className="popup-title">Add a Pricing Plan</h3>
        <Form>
          <div className="text-field br-corner">
            <input
              type="text"
              placeholder="Plan Title*"
              name="plan_title"
            />
          </div>
          <div className="text-field br-corner">
            <input
              type="text"
              placeholder="Plan Sum*"
              name="plan_sum"
            />
          </div>
          <button
            className="btn filled primary br-round h-lg shadow w-lg text-uppercase disabled"
            type="submit"
          >
            Save
          </button>
        </Form>
      </div>
    );
  }
}

export default AddPricingPlan
