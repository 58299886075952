import styled from "styled-components";

import { colors, titleWidth } from "../../styles";

const Container = styled.div`
  ${(p) =>
    p.backgroundImage
      ? `background-image: url("${p.backgroundImage}");`
      : `background-color: ${p.backgroundColor};`}
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  padding: ${(p) => p.topMargin} 0 40px 0;
  color: white;
  font-size: 40px;
  @media (min-width: 850px) {
    font-size: 60px;
  }
  line-height: 65px;
  letter-spacing: -0.6px;
  font-weight: 700;
  max-width: ${titleWidth};
  text-align: center;
  font-weight: 700;
`;

const TitleBar = ({
  topMargin = "112px",
  title,
  backgroundImage = null,
  backgroundColor = colors.blueDark,
}) => {
  return (
    <Container
      backgroundImage={backgroundImage}
      backgroundColor={backgroundColor}
    >
      <Content topMargin={topMargin}>{title}</Content>
    </Container>
  );
};

export default TitleBar;
