import React, { Component } from "react";
import { withRouter } from "react-router";
import styled from "styled-components";
import { roleChecker } from "../../helpers/improve";
import logoFooter from "../../assets/img/logoFooter.svg";
import { colors } from "../../styles";
import { connect } from "react-redux";
import { BASE_URL } from "../../constants";
import { getAbout } from "../../store/actions/admin";

const Container = styled.footer`
  margin-top: 80px;
  background-color: ${colors.blueDark};
`;

const LogoWrapper = styled.div`
  margin-top: 64px;
  display: flex;
  justify-content: center;
`;

const PrivacyAndTermsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 64px;
  margin-top: 48px;
`;

const PrivacyTerms = styled.a`
  font-size: 12px;
  line-height: 20px;
  color: white;
`;

const CopyrightWrapper = styled.div`
  margin-top: 24px;
`;

const Copyright = styled.p`
  font-size: 12px;
`;

class Footer extends Component {
  componentDidMount() {
    this.props.getAboutFiles();
  }

  render() {
    const role = roleChecker();
    const copyrightText = `© ${new Date().getFullYear()} JDForm LLC, a subsidiary of John Dorsey PLLC. All rights reserved.`;

    return (
      /* Footer start */
      <Container className={`${role && role !== "user" ? "dashboard" : ""}`}>
        <div className="container">
          <LogoWrapper>
            <img src={logoFooter} alt="Brand logo" />
          </LogoWrapper>
          <PrivacyAndTermsWrapper>
            <PrivacyTerms
              href="/legal"
              className="color-primary fw-600"
              target="_blank"
            >
              {"Agreement"}
            </PrivacyTerms>
            <PrivacyTerms
              href="/privacy"
              className="color-primary fw-600"
              target="_blank"
            >
              {"Privacy Policy"}
            </PrivacyTerms>
          </PrivacyAndTermsWrapper>
          <CopyrightWrapper>
            <Copyright className="color-white font-xs font-secondary text-xs-center">
              {copyrightText}
            </Copyright>
          </CopyrightWrapper>
        </div>
      </Container>
      /* Footer end */
    );
  }
}

function mapStateToProps(state) {
  return {
    aboutFiles: state.admin.about,
  };
}

export default connect(mapStateToProps, {
  getAboutFiles: getAbout,
})(withRouter(Footer));
