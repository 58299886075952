import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
// import Modal from "react-responsive-modal";
import styled from "styled-components";
import { colors, fontFamilies } from "../../styles";

const ResultContainer = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid ${colors.gray};
`;

const TitleRow = styled.div`
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.35px;
  display: flex;
  gap: 8px;
`;

const Title = styled.span`
  color: black;
  cursor: pointer;
  &:hover {
    color: ${colors.blue};
  }
`;

const Free = styled.span`
  text-transform: uppercase;
  flex-shrink: 0;
  color: ${colors.red};
`;

const Excerpt = styled.div`
  display: none;
  margin-top: 16px;
  font-size: 15px;
  font-family: ${fontFamilies.serif};
  line-height: 24px;
`;

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
`;

const LoadMoreButton = styled.button`
  background-color: inherit;
  border: none;
  font-size: 14px;
  color: ${colors.blue};
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.35px;
  cursor: pointer;
  &:hover {
    color: ${colors.blueHover};
  }
`;

const LoadMore = ({ onClick }) => {
  return (
    <LoadMoreContainer>
      <LoadMoreButton onClick={onClick}>LOAD MORE...</LoadMoreButton>
    </LoadMoreContainer>
  );
};

class Documents extends Component {
  state = {
    open: false,
    showDocument: false,
    documentId: null,
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  handleClick = (e, id) => {
    if (e.target.tagName === "A") {
      return e.target.click();
    }
    this.setState({ showDocument: true });
    this.props.history.push(`/document/${id}`);
  };

  renderDocuments = (documents) => {
    return documents.map((data) => {
      return (
        <Fragment key={data.id}>
          <ResultContainer>
            <div className="file-info">
              <div className="details">
                <TitleRow>
                  <Title onClick={(e) => this.handleClick(e, data.id)}>
                    {data.name}
                  </Title>
                  {data.is_sample && <Free>FREE</Free>}
                </TitleRow>
                <Excerpt>
                  {data.excerpt || "No excerpt is available for this document."}
                </Excerpt>
              </div>
            </div>

            {data.is_bought && (
              <p className="paid-document">
                <span className="dot" />
                <span className="tooltip-text">
                  You have full access to this document
                </span>
              </p>
            )}
          </ResultContainer>
          {/* change implementation */}
          {/* <Modal open={this.state.open} onClose={this.onCloseModal}>
            <div className="popup-content">
              <h3 className="popup-title">Document</h3>
              Document will be soon
            </div>
          </Modal> */}

          {/* {this.props.component && (
            <Modal
              open={this.props.open}
              center
              onClose={this.props.onCloseModal}
            >
              {this.props.open &&
                this.props.modalBody(
                  this.props.component,
                  this.props.onCloseModal
                )}
            </Modal>
          )} */}
        </Fragment>
      );
    });
  };

  render() {
    const { documents } = this.props;
    const {
      match: { url },
      hasMore,
      onClickLoadMore,
    } = this.props;

    if (url.startsWith("/document")) {
      return null;
    }

    return (
      documents && (
        <>
          {this.renderDocuments(documents)}
          {hasMore && <LoadMore onClick={onClickLoadMore} />}
        </>
      )
    );
  }
}

export default withRouter(Documents);
