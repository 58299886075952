export const ORG_TYPES = {
  1: "Sole Practitioner",
  2: "Small / Boutique Firm ( 2-10 attorneys)",
  3: "Mid-sized Firm  (11-29 attorneys)",
  4: "Large full-scale Firm (30 or more attorneys)",
  5: "Financial institution",
  6: "Government",
  7: "Law School",
  8: "Other corporation or Business"
};

export const JURISDICTIONS = {
  1: "Alabama",
  2: "Alaska",
  3: "Arizona",
  4: "Arkansas",
  5: "California",
  6: "Colorado",
  7: "Connecticut",
  8: "Delaware",
  9: "District of Columbia",
  10: "Florida",
  11: "Georgia",
  12: "Hawaii",
  13: "Idaho",
  14: "Illinois",
  15: "Indiana",
  16: "Iowa",
  17: "Kansas",
  18: "Kentucky",
  19: "Louisiana",
  20: "Maine",
  21: "Maryland",
  22: "Massachusetts",
  23: "Michigan",
  24: "Minnesota",
  25: "Mississippi",
  26: "Missouri",
  27: "Montana",
  28: "Nebraska",
  29: "Nevada",
  30: "New Hampshire",
  31: "New Jersey",
  32: "New Mexico",
  33: "New York",
  34: "North Carolina",
  35: "North Dakota",
  36: "Ohio",
  37: "Oklahoma",
  38: "Oregon",
  39: "Pennsylvania",
  40: "Rhode Island",
  41: "South Carolina",
  42: "South Dakota",
  43: "Tennessee",
  44: "Texas",
  45: "Utah",
  46: "Vermont",
  47: "Virginia",
  48: "Washington",
  49: "West Virginia",
  50: "Wisconsin",
  51: "Wyoming"
};

export const PRACTICE_AREA = {
  1: "Corporate",
  2: "Commercial contracts",
  3: "M&A",
  4: "Technology transactions",
  5: "Intellectual property",
  6: "Entertainment",
  7: "Litigation",
  8: "Other"
};
