import React, { Component } from "react";
import Aside from "../common/aside/aside";
import AdminHeader from "../common/profileHeader";
import { resourcePaginate } from "./../../helpers/resourcePaginate";
import PerfectScrollbar from "react-perfect-scrollbar";
import Modal from "react-responsive-modal";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getContributingLawyers,
  acceptContributingLawyer,
  deleteContributingLawyer,
  clearContributingLawyers,
} from "./../../store/actions/admin";
import { ORG_TYPES } from "./Organizations";
import Successfull from './successfull'
import CreateUser from './createUser'

class ContributeLawyers extends Component {
  myRef = React.createRef();
  state = {
    open: false,
    openAccess: false,
    openDelete: false,
    tab: "waiting",
    success: false,
    lawyer: null,
  };
  async componentDidMount() {
    await this.props.getContributingLawyers(null, 'True');
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false, success: false });
  };

  onSuccess = data => {
    this.setState(prevState => ({ success: !prevState.success, data }));
  };

  onOpenAccessModal = id => {
    this.setState({ openAccess: true, id });
  };

  onCloseAccessModal = () => {
    this.setState({ openAccess: false, id: "" });
  };

  onOpenDeleteModal = id => {
    const lawyers = this.props.contributingLawyers.contributingLawyers;
    const lawyer = lawyers.find( lawyer => lawyer.id === id );
    this.setState({
      openDeleteModal: true,
      id,
      lawyer,
    });
    this.setState({ openDelete: true, id });
  };

  onCloseDeleteModal = () => {
    this.setState({ openDelete: false, id: "" });
  };

  /*waitingForAprovalContributors = async () => {
    const { scrollbar } = this.refs;

    scrollbar._ps.element.scrollTop = 0;
    this.props.getContributingLawyers(null, "False");
  };
  approvedContributors = async () => {
    const { scrollbar } = this.refs;
    scrollbar._ps.element.scrollTop = 0;
    this.props.getContributingLawyers(null, "True");
  };

  approveContributor = async parameter => {
    const res = await this.props.acceptContributingLawyer(this.state.id);
    if (res && res.payload.status === 200) {
      this.onCloseAccessModal();
      this.props.getContributingLawyers(null, parameter);
    } else {
      this.setState({ error: "somthing went wrong" });
    }
  };*/

  deleteContributor = async () => {
    const res = await this.props.deleteContributingLawyer(this.state.id);

    if (res && res.payload.status === 204) {
      this.onCloseDeleteModal();
      const parameter = this.state.tab === "approved" ? "True" : "False";
      // await this.props.clearContributingLawyers();
      // this.props.getContributingLawyers(null, 'True');
    } else {
      this.setState({ error: "something went wrong" });
    }
  };

  render() {
    const { openAccess, tab, openDelete, open, success, data, lawyer } = this.state;
    const contributingLawyers = this.props.contributingLawyers && this.props.contributingLawyers.contributingLawyers;
    const count = this.props.contributingLawyers && this.props.contributingLawyers.count;
    const index = this.props.contributingLawyers.next && this.props.contributingLawyers.next.lastIndexOf("=");
    const page = this.props.contributingLawyers.next && this.props.contributingLawyers.next.substr(index + 1);
    const parameter = this.props.contributingLawyers && this.props.contributingLawyers.isActive;

    return (
      /* Area of low page start */
      <div>
        <main className="admin-content manage">
          <div className="container-sm">
            <div className="flex align-center justify-between mb-5">
              <h2 className="font-secondary fw-600"> CONTRIBUTING LAWYERS{" "} </h2>
              <button
                className="btn filled primary h-lg br-round shadow w-md flex justify-center align-center"
                onClick={this.onOpenModal}
              >
                <i className="icon-plus fs-xl mr-1"></i> ADD NEW
              </button>
              {/*  Add new editor popup start */}
              <Modal open={open} center onClose={this.onCloseModal}>
                {/* When user will click on "Send invitation" button and the request will be successful t following content needs to be displayed in the pop up */}
                {/*   */}
                {success ? (
                  <Successfull
                    onClose={this.onCloseModal}
                    data={data}
                    user={"lawyer"}
                  />
                ) : (
                  <CreateUser
                    onSuccess={this.onSuccess}
                    getUsers={this.props.getEditors}
                    user={"lawyer"}
                  />
                )}
              </Modal>
              {/* Add new editor popup end */}
            </div>


            {/*  Approve confirmation popup start */}
            <Modal open={openAccess} onClose={this.onCloseAccessModal} center>{/*

            */}</Modal>
            {/* Approve confirmation popup end */}
            {/*  Delete confirmation popup start */}
            <Modal open={openDelete} onClose={this.onCloseDeleteModal} center>
              <div className="popup-content">
                <form action="">
                  <p className="fs-lg text-xs-left color-black-60 mt-5 ml-2">
                    {
                      lawyer && (
                        lawyer.have_document?
                          'This user is an author of one or more documents. ' +
                          'If you delete the user, those documents will be deleted too.'
                          : `Are you sure that you want to delete User ${lawyer.first_name} ${ lawyer.last_name }?`
                      )
                    }
                  </p>
                  <div className="buttons mt-10 text-xs-right">
                    <button
                      type="button"
                      onClick={this.deleteContributor}
                      className="btn filled primary br-round h-lg w-md fs-md shadow ml-3"
                    >
                      { lawyer && lawyer.have_document? "Ok": "Yes" }
                    </button>
                    <button
                      type="button"
                      onClick={this.onCloseDeleteModal}
                      className="btn link primary h-lg w-md fs-md"
                    >
                      { lawyer && lawyer.have_document? "Cancel": "No" }
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
            {/* Delete confirmation popup end */}

            {contributingLawyers && (
              <PerfectScrollbar
                style={{ height: "500px" }}
                ref="scrollbar"
                onYReachEnd={() => {
                  if (page) {
                    resourcePaginate(
                      contributingLawyers,
                      count,
                      this.props.getContributingLawyers,
                      null,
                      page
                    );
                  }
                }}
              >
                {contributingLawyers.map(data => {
                  return (
                    <div
                      className="card br-corner manage-file"
                      key={data.id + Math.random()}
                      id="scrollbar"
                    >
                      <div className="card-item-left word-break-all mr-1">
                        <Link
                          className="inline-block"
                          to={`/admin/contributing-lawyers-view/${data.id}`}
                        >
                          <h4 className="title">{`${data.first_name} ${
                            data.last_name
                          }`}</h4>
                        </Link>
                        <p className="sub-content">{data.email}</p>
                      </div>
                      <div className="card-item-right word-break-all ml-1 text-xs-right">
                        <p>{data.organization_name}</p>
                        <p className="sub-content">
                          {ORG_TYPES[data.organization_type]}
                        </p>
                      </div>

                      <ul className="actions">
                        {!data.is_active && (
                          <li>
                            <button
                              className="btn-icon approve"
                              onClick={() => this.onOpenAccessModal(data.id)}
                            >
                              <i className="icon-check">{""}</i>
                            </button>
                          </li>
                        )}
                        <li>
                          <button
                            className="btn-icon delete"
                            onClick={() => this.onOpenDeleteModal(data.id)}
                          >
                            <i className="icon-delete">{""}</i>
                          </button>
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </PerfectScrollbar>
            )}
          </div>
        </main>
      </div>
      /* Area of low page end */
    );
  }
}

function mapStateToProps(state) {
  return {
    contributingLawyers: state.admin
  };
}

export default connect(
  mapStateToProps,
  {
    getContributingLawyers,
    acceptContributingLawyer,
    deleteContributingLawyer,
    clearContributingLawyers,
  }
)(ContributeLawyers);
