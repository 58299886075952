import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";

import logo from "../../../assets/img/logo.svg";
import {
  confirmUser,
  removeToken,
  saveResetToken,
} from "../../../store/actions/auth";
import { colors, maxContentWidth } from "../../../styles";
import NavItemGroup from "./NavItemGroup";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";

const Container = styled.div`
  display: flex;
  justify-content: center;
  min-height: 80px;
  flex-direction: column;
  @media (min-width: 768px) {
    height: 120px;
    position: sticky;
    flex-direction: row;
  }
  background-color: white;
  align-items: stretch;
  top: 0;
  z-index: 1;
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
`;

const ContentContainer = styled.div`
  max-width: ${maxContentWidth};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  flex: 1 1 auto;
  @media (min-width: 768px) {
    margin-top: 40px;
    flex: 1 1 auto;
  }
`;

const ByJohnDorsey = styled.a`
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
  flex: 1 0 0;
  color: ${colors.blue};
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.35px;
`;

const NavItemsAndLoginOuter = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
  justify-content: flex-end;
  flex: 1 0 0;
`;

const NavItemsAndLoginInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-end;
`;

const MenuBars = styled.div`
  display: inline-block;
  cursor: pointer;
  @media (min-width: 768px) {
    display: none;
  }
`;

const Bars = styled.div`
  width: 35px;
  height: 5px;
  background-color: ${colors.blue};
  margin: 6px 0;
`;

const Menu = styled.div`
  display: ${(p) => (p.menuOpen ? "flex" : "none")};
  height: 248px;
  width: 100%;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
  justify-content: space-evenly;
  margin-top: -20px;
  @media (min-width: 768px) {
    display: none;
  }
`;

const Header = ({ lawyerModal, lawyerToken, handleClick, lawyerConfirm }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [menuOpen, setMenuOpen] = useState(false);

  function menuClick() {
    setMenuOpen(!menuOpen);
  }

  return (
    <Container>
      <ContentContainer>
        <ByJohnDorsey href="http://jmdorsey.com" target="_blank">
          BY JOHN DORSEY PLLC
        </ByJohnDorsey>
        <a href="/">
          <img src={logo} alt="Brand logo" />
        </a>
        <MenuBars onClick={menuClick}>
          <Bars> </Bars>
          <Bars> </Bars>
          <Bars> </Bars>
        </MenuBars>

        <NavItemsAndLoginOuter>
          <NavItemsAndLoginInner>
            <NavItemGroup />
            {isLoggedIn ? (
              <LogoutButton />
            ) : (
              <LoginButton
                lawyerModal={lawyerModal}
                lawyerToken={lawyerToken}
                handleClick={handleClick}
                lawyerConfirm={lawyerConfirm}
              />
            )}
          </NavItemsAndLoginInner>
        </NavItemsAndLoginOuter>
      </ContentContainer>
      <Menu menuOpen={menuOpen}>
        <NavItemGroup />
        {isLoggedIn ? (
          <LogoutButton />
        ) : (
          <LoginButton
            lawyerModal={lawyerModal}
            lawyerToken={lawyerToken}
            handleClick={handleClick}
            lawyerConfirm={lawyerConfirm}
          />
        )}
      </Menu>
    </Container>
  );
};

export default connect(null, { removeToken, confirmUser, saveResetToken })(
  Header
);
