import React from "react";

export default function IAgreeWith({ values, handleChange, handleBlur }) {
  return (
    <label className="checkbox round mt-4 mb-6 checkbox-no-hover">
      <input
        onChange={handleChange}
        onBlur={handleBlur}
        type="checkbox"
        className="checkbox-control"
        name="searchable"
        checked={values.searchable}
      />
      <span className="check-icon"> {""} </span>
      <span className="checkbox-label">
        {"I agree to the "}
        <a href={"/legal"} target="_blank" className="color-primary fw-600">
          {"Agreement"}
        </a>
        {" and "}
        <a href={"/privacy"} target="_blank" className="color-primary fw-600">
          {"Privacy Policy"}
        </a>
      </span>
    </label>
  );
}
