export default url => {
  if (!url) return '';
  let newUrl = url;
  if (url.slice(0, 4) === 'www.') {
    newUrl = 'https://' + url;
  }else if(url.slice(0, 7) !== 'http://' && url.slice(0, 8) !== 'https://'){
    newUrl = 'https://www.' + url
  }
  return newUrl
};

export const roleChecker = () => {
  const role = localStorage.role || sessionStorage.role;
  const token = localStorage.auth || sessionStorage.auth;
  if (!role || !token) {
    handleLoguot();
    return false;
  }
  const rolesArray = ['user', 'admin', 'editor', 'lawyer'];
  return rolesArray[role - 1];
};

export const handleLoguot = () => {
  localStorage.removeItem('auth');
  localStorage.removeItem('role');
  localStorage.removeItem('id');
  sessionStorage.removeItem('auth');
  sessionStorage.removeItem('role');
};

export const getToken = () => {
  return !!(localStorage.auth || sessionStorage.auth);
};

export const socialLogIn = (token, id, role) => {
  localStorage.setItem('auth', token)
  localStorage.setItem('role', role)
  localStorage.setItem('id', id)
}


/**
 *  @description URL normalizer with params.
 *  @param {Object} urlOption - Url object that contain all params.
 *  @param defaultUrl - default url option.
 *  @return {String} - Final url.
 */
export function urlNormalizer (urlOption, defaultUrl) {
  let url = defaultUrl, isInitialParam = true;
  Object.keys(urlOption).forEach( key => {
    if (urlOption[key].trim()){
      if (isInitialParam){
        isInitialParam = false;
        url += '?';
      } else {
        url += '&';
      }
      url += `${key}=${urlOption[key]}`;
    }});
  return url;
}