import "react-phone-number-input/style.css";
// import "react-responsive-ui/style.css";
import {
  //   formatPhoneNumber,
  isValidPhoneNumber
} from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import React from "react";

export default class MyNumber extends React.Component {
  render() {
    return (
      <div>
        <PhoneInput
          name="phone_number"
          placeholder="Enter phone number"
          value={this.props.value}
          onChange={value => this.props.handleNumber(value)}
        />
      </div>
    );
  }
}

