import React, {Component, Fragment} from "react";
import {withFormik, Form, Field} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import {forgotPassword} from "./../../../store/actions/auth";
import SuccessPopup from "../successPopup";
import Login from "./../login/login";

class ForgotPassword extends Component {
    state = {
        component: "forgotPassword"
    };
    handleComponent = (e, component) => {
        e.preventDefault();
        this.setState({component});
    };

    render() {
        const {values, errors, touched} = this.props;
        const {component} = this.state;
        return (
            /* Forgot password popup start */
            <Fragment>
                {component === "forgotPassword" && !values.success && (
                    <div className="popup-content">
                        <h3 className="popup-title">Forgot password</h3>
                        <Form action="">
                            <div
                                className={`text-field br-corner ${touched.email &&
                                errors.email &&
                                "invalid"}`}
                            >
                                <Field
                                    type="text"
                                    placeholder="Email address*"
                                    value={values.email}
                                    name="email"
                                />
                                {touched.email && errors.email && (
                                    <p className="error-msg">{errors.email}</p>
                                )}
                            </div>

                            <button className="btn filled primary br-round shadow h-lg w-lg mb-2">
                                SUBMIT
                            </button>
                        </Form>
                        <a
                            href="/"
                            className="btn link primary h-lg w-lg"
                            onClick={e => this.handleComponent(e, "login")}
                        >
                            Log in
                        </a>
                    </div>
                )}

                {values.success && (
                    <SuccessPopup
                        text={`An email message was sent to your email address. Please check your email to reset the password.`}
                        onClose={this.props.onClose}
                        icon="envelop"
                        title={true}
                        modalName="modalSignIn"
                    />
                )}
                {component === "login" && <Login/>}
            </Fragment>
            /* Forgot password popup end */
        );
    }
}

const ForgotPasswordFormik = withFormik({
    mapPropsToValues({email}) {
        return {
            email: email || "",
            success: false
        };
    },
    validationSchema: Yup.object().shape({
        email: Yup.string()
            .matches(/^[^@]+@[^@]+$/, {
                message: "Please provide a valid email.",
                excludeEmptyString: true
            })
            .max(200, "Maximum number of symbols is 200.")
            .required("This field is mandatory.")
    }),

    async handleSubmit(values, {props, setErrors, setValues}) {
        const data = {
            email: values.email
        };

        const res = await props.forgotPassword(data);
        if (res && res.payload && res.payload.status === 200) {
            setValues({success: true});
        } else if (res && res.payload && res.payload.status === 404) {
            setErrors({ email: "We couldn't find your email address." });
        }else if (res && res.payload && res.payload.data.length && res.payload.data[0].includes('you are signed up with')){
            setErrors({
                email: res.payload.data[0],
            });
        }
    }
})(ForgotPassword);

export default connect(
    null,
    {forgotPassword}
)(ForgotPasswordFormik);
