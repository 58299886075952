import React, { Component } from "react";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { resetPassword } from "./../../../store/actions/auth";
import SuccessPopup from "./../successPopup";

class ResetPassword extends Component {
  render() {
    const { values, errors, touched } = this.props;

    return (
      <div className="popup-content">
        {!values.success && <h3 className="popup-title">Reset Password</h3>}
        {!values.success && (
          <Form action="">
            <div
              className={`text-field br-corner ${touched.password &&
                errors.password &&
                "invalid"}`}
            >
              <Field
                type="password"
                placeholder="Password*"
                name="password"
                value={values.password}
              />
              {touched.password && errors.password && (
                <p className="error-msg">{errors.password}</p>
              )}
            </div>
            <div
              className={`text-field br-corner ${touched.confirmPassword &&
                errors.confirmPassword &&
                "invalid"}`}
            >
              <Field
                type="password"
                placeholder="Confirm password*"
                name="confirmPassword"
                value={values.confirmPassword}
              />
              {touched.confirmPassword && errors.confirmPassword && (
                <p className="error-msg">{errors.confirmPassword}</p>
              )}
            </div>

            {errors.error && <p className="popup-error-msg">{errors.error}</p>}
            {/* Please show success message when the info will be submitted successfully.  */}
            {/* Start of the success message */}
            <button
              className="btn filled primary br-round shadow h-lg w-lg mb-4 mt-2"
              type="submit"
            >
              SUBMIT
            </button>
          </Form>
        )}

        {values.success && (
          <SuccessPopup
            text="Your password has been changed successfully"
            onClose={this.props.onClose}
            modalName="modalReset"
            icon="lock"
            title={false}
          />
        )}
      </div>
    );
  }
}

const ResetPasswordFormik = withFormik({
  mapPropsToValues({ password, confirmPassword }) {
    return {
      password: password || "",
      confirmPassword: confirmPassword || "",
      success: false
    };
  },
  validationSchema: Yup.object().shape({
    password: Yup.string()
      .min(6, "The Password field must be 6 or more characters.")
      .max(20, "Please provide a valid password.")
      .required("This field is mandatory."),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password")],
        "The confirm password confirmation does not match."
      )
      .required("This field is mandatory.")
  }),

  async handleSubmit(values, { props, setErrors, setValues }) {
    const data = {
      new_password: values.password
    };
    const res = await props.resetPassword(data, props.user.reset_token);
    res && res.payload.status === 200 && setValues({ success: true });
   if (res && res.payload && res.payload.status === 400) {
     const isActivationToken = res.payload.data && res.payload.data.activation_token && res.payload.data.activation_token
       && res.payload.data.activation_token[0];

     if (isActivationToken === "Incorrect activation code" ) {
        setErrors({ error: "You have reset your password with the provided link. If you want to reset again, please " +
            "request for another reset link"})
      } else if(isActivationToken){
       setErrors({ error: isActivationToken });
     }
    }
  }
})(ResetPassword);
function mapStateToProps(state) {
  return {
    user: state.auth.user
  };
}
export default connect(
  mapStateToProps,
  { resetPassword }
)(ResetPasswordFormik);
