import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getLowAreas,
  createLowAreas,
  clearLowAreas,
  deleteLowAreas
} from "../../store/actions/documents";
import TemplateTypes from "./templateofTypes";

class AreaofLow extends Component {
  state = {
    open: false,
    hasMore: true,
    resourceName: "",
    deleteId: null,
    openDel: false,
    error: ""
  };
  async componentDidMount() {
    await this.props.getLowAreas();
  }
  onOpenModal = () => {
    this.setState({ open: true, error: "", resourceName: "" });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };
  onCloseModalDel = () => {
    this.setState({ deleteId: null, openDel: false });
  };
  onOpenModalDel = id => {
    this.setState({ deleteId: id, openDel: true, error: "" });
  };
  addResource = async type => {
    const { resourceName } = this.state;
    if (resourceName && resourceName.length <= 200) {
      const res = await this.props.createLowAreas({
        name: resourceName
      });
      if (res.payload && res.payload.status === 201) {
        this.props.clearLowAreas();
        await this.props.getLowAreas();
        this.setState({
          resourceName: "",
          open:false,
        });
      } else {
        this.setState({ error: "Two legal areas can't have the same name." });
      }
    } else {
      this.setState({
        error: !this.state.resourceName
          ? "This filed is required"
          : "Maximum number of symbols is 200."
      });
    }
  };
  handleChange = e => {
    this.setState({
      error: "",
      resourceName: e.target.value
    });
  };
  deleteResource = async id => {

    const res = await this.props.deleteLowAreas(id);
    if (res.payload && res.payload.status === 204) {
      await this.props.clearLowAreas();
      const response = await this.props.getLowAreas();
      if (response && response.payload && response.payload.status === 200) {
        await this.setState({
          resourceName: ""
        });
        this.onCloseModalDel();
      }
    } else {
      this.setState({ error: Object.values(res.payload.data)[0] });
    }
  };
  render() {
    const resourcesCount = this.props.lowAreas.count;
    const query = this.props.lowAreas.allLowAreas && {
      resources: [...this.props.lowAreas.allLowAreas]
    };
    const index =
      this.props.lowAreas.next && this.props.lowAreas.next.indexOf("=");
    const page =
      this.props.lowAreas.next && this.props.lowAreas.next.substr(index + 1);

    return (
      /* Resource types page start */
      <div>
        <TemplateTypes
          type="lowAreas"
          text="LEGAL AREA"
          modalText="ADD NEW LEGAL AREA"
          placeholder="New Legal Area name"
          stateValue={this.state}
          query={query}
          resourcesCount={resourcesCount}
          handleChange={this.handleChange}
          addResource={this.addResource}
          deleteResource={this.deleteResource}
          page={page}
          method={this.props.getLowAreas}
          onOpenModal={this.onOpenModal}
          onCloseModal={this.onCloseModal}
          onOpenModalDel={this.onOpenModalDel}
          onCloseModalDel={this.onCloseModalDel}
        />
      </div>
      /* Resource types page end */
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    lowAreas: state.documents.lowAreas
  };
}

export default connect(
  mapStateToProps,
  {
    createLowAreas,
    getLowAreas,
    clearLowAreas,
    deleteLowAreas
  }
)(withRouter(AreaofLow));
