import React, { Component } from "react";
import { connect } from "react-redux";

import { handleLoguot } from "./improve";

const requireAuth = (ChildComponent) => {
  class ComposedComponent extends Component {
    componentDidMount() {
      this.shouldNavigateAway();
    }

    shouldNavigateAway() {
      const {
        location: { pathname },
        history,
      } = this.props;
      const firstPart = pathname.split("/")[1];
      let role;
      if (localStorage.getItem("auth")) {
        role = localStorage.getItem("role");
      } else if (sessionStorage.getItem("auth")) {
        role = sessionStorage.getItem("role");
      }
      const returnedValues = [
        "contributor_verification",
        "reset_pass",
        "user_confirmation",
        "email_update",
        "confirmation",
      ];

      if (returnedValues.includes(firstPart)) {
        return handleLoguot();
      }

      if (role === "2" && pathname.split("/")[1] !== "admin") {
        history.push("/admin");
      } else if (role === "4" && pathname.split("/")[1] !== "lawyer") {
        history.push("/lawyer");
      } else if (role === "3" && pathname.split("/")[1] !== "editor") {
        history.push("/editor");
      }
    }

    render() {
      return <ChildComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      user: state.auth.user,
    };
  }

  return connect(mapStateToProps)(ComposedComponent);
};

export default requireAuth;
