import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { withRouter } from "react-router-dom";

import {
  addDocumentDataToEditor,
  initializeEditorFunctionality,
  addButtonInitialization,
  commandToClipboard,
  getPositionOfElement,
  makeDocumentPrintable,
  cleanDocument,
} from "./helper";

import improveUrl from "../../../helpers/improve";

class Editor extends Component {
  state = {
    responseError: false,
    isOpenHyperlink: false,
    anchorElement: null,
    anchorHref: null,
    hyperlinkValue: null,
  };

  constructor(props) {
    super(props);
    this.editor = React.createRef();
    this.button = React.createRef();
    this.addNoteAtTopRef = React.createRef();
    this.hyperlinkPopup = React.createRef();
    this.hyperlinkEditor = React.createRef();
    this.hyperlinkRedirector = React.createRef();
  }

  async componentDidMount() {
    const toolbar = document.getElementById("toolbar");
    const sticky = toolbar && toolbar.offsetTop;
    const { draftingNote, documentHtml } = this.props;
    const editor = this.editor.current;
    const newTag = document.createElement("div");
    newTag.innerHTML = documentHtml;
    const cleanupTag = newTag.querySelector("#forCleanUp");

    window.onscroll = () => {
      window.pageYOffset > sticky
        ? toolbar.classList.add("sticky")
        : toolbar.classList.remove("sticky");
    };

    editor.innerHTML = cleanupTag ? cleanupTag.innerHTML : newTag.innerHTML;
    editor.oninput = this._onChangeDocument;
    editor.onclick = this._onClickForAnchor;

    cleanDocument(this.editor.current);
    document.addEventListener("click", (e) => {
      if (
        this.state.isOpenHyperlink &&
        e.target !== this.state.anchorElement &&
        e.target !== this.hyperlinkPopup.current &&
        e.target.parentNode !== this.hyperlinkPopup.current &&
        e.target.parentNode.parentNode !== this.hyperlinkPopup.current &&
        e.target.parentNode.parentNode.parentNode !==
          this.hyperlinkPopup.current &&
        e.target.className !== "icon-hyperlink" &&
        this.hyperlinkEditor.current
      ) {
        this.hyperlinkEditor.current.style.display = "none";
        this.hyperlinkRedirector.current.style.display = "none";
        this.setState({ isOpenHyperlink: false });
      }
    });

    addButtonInitialization(
      this.editor.current,
      this.button.current,
      this.addNoteAtTopRef.current
    );
    if (draftingNote.length) {
      await addDocumentDataToEditor(draftingNote, this.editor.current);
    }
    this.props.printableDocument.current.innerHTML = this.editor.current.innerHTML;
    initializeEditorFunctionality(this.editor.current, this.button.current);
    makeDocumentPrintable(
      this.editor.current,
      this.props.printableDocument.current,
      null,
      "edit"
    );
    const paragraphs = document.querySelectorAll(`.edit-doc-section`);
    paragraphs.forEach((paragraph) => {
      if (paragraph.style.lineHeight === "100%") {
        paragraph.style.lineHeight = "120%";
      }
    });
    this._cleanTableContent();
  }

  onCloseModal = () => {
    this.props.history.push("/admin/documents");
  };

  onChangeHyperlinkValue = (e) => {
    this.setState({ hyperlinkValue: e.target.value });
  };

  _cleanTableContent = () => {
    if (!this.props.cleaned) {
      const paragraphs = document.querySelectorAll(`#editor .edit-doc-section`);
      paragraphs.forEach((paragraph) => {
        if (
          paragraph.className.includes("MsoToc1") ||
          paragraph.className.includes("MsoToc2")
        ) {
          if (paragraph.firstElementChild.tagName === "A") {
            const text = paragraph.firstElementChild.innerText;
            const splitIndex = text.lastIndexOf(" ");
            const firstPart = text.slice(0, splitIndex);
            const lastPart = " " + text.slice(splitIndex);
            paragraph.firstElementChild.innerText = firstPart;
            while (
              paragraph.offsetWidth - 56 >
              paragraph.firstElementChild.offsetWidth
            ) {
              paragraph.firstElementChild.innerText += ".";
            }
            paragraph.firstElementChild.innerText += lastPart;
          }
        }
        if (paragraph.style.lineHeight === "100%") {
          paragraph.style.lineHeight = "120%";
        }
      });
    }
  };

  // _onClickForAnchor = e => {
  //   this.hyperlinkRedirector.current.style.display = 'block';
  //   this.hyperlinkEditor.current.style.display = 'none';

  //   //newConvert
  //   const isAnchor = e.path.filter( elem => elem.tagName === 'A')

  //   if (e.target.tagName === 'A' || isAnchor.length){
  //     const obj = getPositionOfElement(e.target);
  //     const top = obj.top + obj.height / 2;
  //     const left = obj.left;
  //     //new convert
  //     let hyperlinkValue = e.target.tagName === 'A'? e.target.getAttribute('href'): isAnchor[0].href;
  //     if (hyperlinkValue === '/'){
  //       hyperlinkValue = '';
  //     }else {
  //       hyperlinkValue = improveUrl(hyperlinkValue)
  //     }

  //     this.setState({
  //       isOpenHyperlink: true,
  //       top,
  //       left,
  //       anchorElement: e.target,
  //       hyperlinkValue,
  //     })
  //   }
  // };

  _onClickForAnchor = (e) => {
    this.hyperlinkRedirector.current.style.display = "block";
    this.hyperlinkEditor.current.style.display = "none";

    if (e.target.tagName === "A") {
      const obj = getPositionOfElement(e.target);
      const top = obj.top + obj.height / 2;
      const left = obj.left;
      let hyperlinkValue = e.target.getAttribute("href");
      if (hyperlinkValue === "/") {
        hyperlinkValue = "";
      } else {
        hyperlinkValue = improveUrl(hyperlinkValue);
      }

      this.setState({
        isOpenHyperlink: true,
        top,
        left,
        anchorElement: e.target,
        hyperlinkValue,
      });
    }
  };

  handleToolbarLink = (e) => {
    commandToClipboard(e, "createLink");
    const selection = window.getSelection();
    if (selection && selection.type !== "None" && selection.type !== "Caret") {
      const anchor = selection.focusNode.parentNode;
      const href = improveUrl(anchor.getAttribute("href"));
      this.hyperlinkRedirector.current.style.display = "none";
      this.hyperlinkEditor.current.style.display = "block";
      const obj = getPositionOfElement(anchor);
      const top = obj.top + obj.height / 2;
      const left = obj.left;

      this.setState({
        isOpenHyperlink: true,
        top,
        left,
        anchorElement: anchor,
        hyperlinkValue: href,
      });
    }
  };

  hyperlinkChange = (e) => {
    const { hyperlinkValue, anchorElement } = this.state;
    this.hyperlinkEditor.current.style.display = "block";
    this.hyperlinkRedirector.current.style.display = "none";
    if (hyperlinkValue.trim()) {
      const url = improveUrl(hyperlinkValue);
      anchorElement.href = url;
      this.setState({ anchorElement, isOpenHyperlink: false });
    } else {
      anchorElement.href = "/";
      this.setState({ anchorElement, isOpenHyperlink: false });
    }
  };

  hyperLinkDelete = (e) => {
    const { anchorElement } = this.state;
    const textNode = document.createTextNode(anchorElement.innerHTML);
    anchorElement.parentNode.insertBefore(textNode, anchorElement);
    anchorElement.remove();
    this.setState({ isOpenHyperlink: false });
  };

  hyperlinkEdit = () => {
    this.hyperlinkEditor.current.style.display = "block";
    this.hyperlinkRedirector.current.style.display = "none";
  };

  _onChangeDocument = async (e) => {
    if (this.editor.current && !this.editor.current.innerText.trim()) {
      this.editor.current.innerHTML = '<p class="edit-doc-section">&nbsp;</p>';
    }

    if (
      e.inputType === "insertOrderedList" ||
      e.inputType === "insertUnorderedList"
    ) {
      // addParagraphInLists(this.editor.current);
    }

    await initializeEditorFunctionality(
      this.editor.current,
      this.button.current,
      e
    );
  };

  render() {
    const open = this.state.responseError;
    const {
      top,
      left,
      isOpenHyperlink,
      hyperlinkValue,
      anchorHref,
    } = this.state;
    return (
      <>
        <Modal open={open} onClose={this.onCloseModal}>
          <div className="popup-content">Something went wrong :)</div>
        </Modal>
        <div
          className="link-box card br-corner"
          ref={this.hyperlinkPopup}
          style={{
            position: "absolute",
            top: top + 10 + "px",
            left: left + "px",
            display: `${isOpenHyperlink ? "block" : "none"}`,
          }}
        >
          <div ref={this.hyperlinkRedirector}>
            <p className="color-primary">
              <span className="color-black-60">Go to link: </span>
              <a href={hyperlinkValue} target={"_blank"}>
                {hyperlinkValue}
              </a>
            </p>
            <div className="flex justify-end mt-2">
              <button
                type={"button"}
                onClick={this.hyperLinkDelete}
                className="btn-icon color-black-60"
              >
                remove
              </button>
              <button
                type={"button"}
                onClick={this.hyperlinkEdit}
                className="btn-icon color-primary"
              >
                edit
              </button>
            </div>
          </div>
          <div
            className="text-field br-corner text-right mb-0"
            ref={this.hyperlinkEditor}
          >
            <input
              value={`${hyperlinkValue}`}
              onChange={this.onChangeHyperlinkValue}
            />
            <button
              type={"button"}
              onClick={this.hyperlinkChange}
              className="btn-icon color-primary inline-block mt-2"
            >
              save
            </button>
          </div>
        </div>
        <div className={"editor"}>
          <div id="toolbar-row" className="editor-toolbar-row">
            <div id={"toolbar"} className={"editor-toolbar"}>
              <button
                ref={(ref) => (this.bold = ref)}
                type={"button"}
                className={"text-format bold"}
                id={"bold"}
                onClick={(e) => commandToClipboard(e, "bold")}
              >
                <i className="icon-bold">{""}</i>
              </button>

              <button
                ref={(ref) => (this.italic = ref)}
                type={"button"}
                className={"text-format italic "}
                id={"italic"}
                onClick={(e) => commandToClipboard(e, "italic")}
              >
                <i className="icon-italic">{""}</i>
              </button>

              <button
                ref={(ref) => (this.strikeThrough = ref)}
                type={"button"}
                className={"text-format strike"}
                id={"strikeThrough"}
                onClick={(e) => commandToClipboard(e, "strikeThrough")}
              >
                <i className="icon-strikethrough">{""}</i>
              </button>

              <button
                ref={(ref) => (this.underline = ref)}
                type={"button"}
                className={"text-format underline"}
                id={"underline"}
                onClick={(e) => commandToClipboard(e, "underline")}
              >
                <i className="icon-underline">{""}</i>
              </button>

              <button
                type={"button"}
                className={"text-format undo"}
                onClick={(e) => commandToClipboard(e, "undo")}
              >
                <i className="icon-undo">{""}</i>
              </button>

              <button
                type={"button"}
                className={"text-format redo"}
                onClick={(e) => commandToClipboard(e, "redo")}
              >
                <i className="icon-redo">{""}</i>
              </button>

              <button
                type={"button"}
                className={"text-format link"}
                onClick={this.handleToolbarLink}
              >
                <i className="icon-hyperlink">{""}</i>
              </button>
            </div>
            <button ref={this.addNoteAtTopRef} className="add-note-at-top">
              +
            </button>
          </div>
          <div>
            <div
              suppressContentEditableWarning={true}
              ref={this.editor}
              contentEditable={"true"}
              id={"editor"}
              onMouseMove={this._onMouseMove}
            ></div>
            <button
              id={"plus"}
              ref={this.button}
              className={"btn-add"}
              style={{
                top: "307px",
                left: "500px",
              }}
            >
              +
            </button>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    documents: state.documents,
    documentData: state.documents.documentById.data,
  };
}

export default withRouter(connect(mapStateToProps)(Editor));
