import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { clearDocuments } from "../../store/actions/documents";
import styled from "styled-components";
import { colors } from "../../styles";

const Icon = styled.i`
  font-size: 18px;
`;

const Input = styled.input`
  margin-left: 8px;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.35px;
  border: none;

  ::placeholder {
    color: ${colors.gray};
    font-weight: 700;
  }
`;

const Container = styled.div`
  border-bottom: 1px solid ${colors.gray};
  padding-bottom: 4px;
  width: flex;
  @media (min-width: 576px) {
    width: 536px;
  }
`;

class SearchComponent extends Component {
  state = {
    value: "",
  };

  handleChange = (e) => {
    this.setState({ value: e.target.value });
    this.props.onHandleChange({ searchValue: e.target.value });
  };

  handleClick = (e) => {
    e.preventDefault();
    this.props.clearDocuments();
    this.props.searchResults(this.props.searchValue);
    this.props.history.push("/");
  };

  render() {
    return (
      <Container>
        <form onSubmit={(e) => this.handleClick(e)}>
          <span className="icon pointer" onClick={this.handleClick}>
            <Icon className="icon-search">{""}</Icon>
          </span>
          <Input
            type="text"
            placeholder="SEARCH..."
            value={this.props.searchValue}
            onChange={this.handleChange}
          />
        </form>
      </Container>
    );
  }
}

export default withRouter(connect(null, { clearDocuments })(SearchComponent));
