import styled from "styled-components";
import SearchComponent from "../searchComponent";

const Container = styled.div`
  margin-top: 80px;
`;

const Search = ({ searchResults, onHandleChange, searchValue }) => {
  return (
    <Container className="search">
      <SearchComponent
        searchResults={searchResults}
        headerClass="mb-0"
        onHandleChange={onHandleChange}
        searchValue={searchValue}
      />
    </Container>
  );
};

export default Search;
