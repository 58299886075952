import React, { Component } from "react";
import Envelop from "./../../assets/img/envelop.png";

export default class Successfull extends Component {
  render() {
    const { last_name, first_name, email, user } = this.props.data;
    return (
      <div>
        <div className="popup-content">
          <h3 className="popup-title">THANK YOU</h3>
          <form action="" className="flex column align-center">
            <div className="popup-img">
              <img src={Envelop} alt="Envelop_image" />
            </div>
            <div className="message pt-10">
              <p>
                {`An invitation message was sent to ${first_name} ${last_name}, 
                ${email} to join Form as ${ user === "editor"? 'Editor': "Contributing Lawyer" }.`}
              </p>
            </div>
            <button
              className="btn filled primary shadow br-round h-lg w-lg mb-4"
              onClick={ () => {
                this.props.onClose()
              }}
              type="button"
            >
              DONE
            </button>
          </form>
        </div>
      </div>
    );
  }
}
