import MainButton from "../../shared/MainButton";
import { handleLoguot } from "../../../helpers/improve";
import { useDispatch } from "react-redux";
import { LOGGED_OUT } from "../../../store/actions/types";

const LogoutButton = () => {
  const dispatch = useDispatch();

  const onClick = () => {
    handleLoguot();
    dispatch({ type: LOGGED_OUT });
    window.location.reload();
  };
  return <MainButton onClick={onClick}>{"LOG OUT →"}</MainButton>;
};

export default LogoutButton;
