import { useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import styled from "styled-components";
import MainButton from "../components/shared/MainButton";
import modalMap from "../components/shared/modalMap";
import { modalBody } from "../helpers/modalBody";
import { colors } from "../styles";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
  padding-top: 16px;
`;
const Text = styled.span``;
const Feature = styled.div`
  font-size: 14px;
  font-weight: 700;
  padding-left: 40px;
  padding-right: 16px;
`;
const PurchaseButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 26px;
`;

const AlreadyAvailable = styled.div`
  background-color: ${colors.blueDark};
  color: white;
  padding: 4px;
`;

const features = [
  "Access to document for 10 days",
  "View drafting tips for the document",
  "Download or print as Word/PDF",
];

const FeatureList = ({ purchaseHandler, is_sample }) => {
  const [currentModal, setCurrentModal] = useState(null);
  const onCloseModal = () => setCurrentModal(null);
  const onOpenModal = (modalName) => setCurrentModal(modalName);
  const ModalComponent = modalMap[currentModal];
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const document = useSelector((state) => state.documents.documentById);
  const documentData = document?.data;
  const is_bought = documentData?.is_bought;
  const subscription = useSelector((state) => state.payment.subscription);
  const is_annual = subscription?.subscription_type === 1;

  const onClick = () => {
    if (isLoggedIn) {
      purchaseHandler();
    } else {
      setCurrentModal("modalSignUpFirst");
    }
  };

  return (
    <Container>
      {(is_sample
        ? ["This document is free for the public to download."]
        : features
      ).map((item) => (
        <Feature key={item}>
          <Text>{item}</Text>
        </Feature>
      ))}
      <PurchaseButtonWrapper>
        {is_sample ? null : is_annual ? (
          <AlreadyAvailable>
            This document is available through your annual subscription.
          </AlreadyAvailable>
        ) : is_bought ? (
          <AlreadyAvailable>You have purchased this document.</AlreadyAvailable>
        ) : (
          <MainButton color={colors.red} onClick={onClick}>
            PURCHASE
          </MainButton>
        )}
      </PurchaseButtonWrapper>
      {currentModal && (
        <Modal open onClose={onCloseModal}>
          {modalBody(
            ModalComponent,
            onCloseModal,
            null,
            null,
            null,
            onOpenModal,
            null
          )}
        </Modal>
      )}
    </Container>
  );
};

export default FeatureList;
