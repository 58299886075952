import styled from "styled-components";
import Footer from "./components/Homepage/footer";
import Header from "./components/Homepage/header";
import TitleBar from "./components/shared/TitleBar";
import { mainContentWidth } from "./styles";

const docFontSize = "11pt";
const docfontFamily = '"Calibri", sans-serif';
const indentSize = "30pt";

const MainContent = styled.div`
  max-width: ${mainContentWidth};
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Document = styled.div`
  margin: 32px 0;
  max-width: 6.5in;
  display: flex;
  flex-direction: column;
  gap: ${docFontSize};
`;

const P = styled.p`
  font-size: ${docFontSize};
  font-family: ${docfontFamily};
`;

const Indent = styled.span`
  width: ${indentSize};
  display: inline-block;
  font-size: ${docFontSize};
  font-family: ${docfontFamily};
`;

const U = styled.span`
  text-decoration: underline;
  font-size: ${docFontSize};
  font-family: ${docfontFamily};
`;

const SaasAgreement = () => {
  return (
    <div>
      <Header />

      <TitleBar
        topMargin="160px"
        title="Agreement"
        backgroundImage="/jd_form_hero_mountain.png"
      />

      <MainContent>
        <Document>
          <P>Last Modified: August 20, 2022</P>

          <P>
            This SaaS Agreement (this “<b>Agreement</b>”) is a binding contract
            between you (“<b>you</b>”) and JDForm LLC, a Washington limited
            liability company (“<b>JDForm</b>,” “<b>we</b>,” or “<b>us</b>”).
            This Agreement governs your use of the services (the “
            <b>Services</b>”) provided by us at{" "}
            <a href="http://www.jdform.com">www.</a>JDForm.com (the “<b>Site</b>
            ”).
          </P>

          <P>
            This Agreement takes effect on the first to occur of when you (i)
            check or click the “I agree” button as part of the registration
            process for the Services or (ii) access or use the Services (the “
            <b>Effective Date</b>”). You represent and warrant that you have the
            power and authority to enter into this Agreement and, if entering
            into this Agreement for an organization, that you have the legal
            authority to bind the organization. &nbsp;
          </P>

          <P>
            <Indent />
            <Indent>1.</Indent>
            <U>Access and Use</U>. Subject to the terms of this Agreement,
            JDForm grants you a revocable, non-exclusive, non-transferable,
            non-sublicensable, limited right to access and use the Services
            during the Term (defined below) solely for your internal business
            use in accordance with this Agreement, including the right to
            revise, modify, customize, and use the documents and drafting tips
            available in the Services (collectively, “<b>Documents</b>”) solely
            for your internal law practice, research, education, and other
            internal business use in accordance with this Agreement. The
            Services and Documents are not legal or other professional advice,
            and are not a substitute for obtaining legal advice from a qualified
            attorney licensed in your jurisdiction.
          </P>

          <P>
            <Indent />
            <Indent>2.</Indent>
            <U>Use Restrictions</U>. You shall not, and shall not permit any
            person or entity to, use the Services or Documents for any purpose
            beyond the scope of the access granted in this Agreement. You shall
            not at any time, directly or indirectly, and shall not permit any
            person or entity to: (i) copy, modify, or create derivative works of
            the Services or Documents, in whole or in part (except, with respect
            to the Documents only, modifications expressly permitted under{" "}
            <U>Section 1</U> of this Agreement); (ii) rent, lease, lend, sell,
            license, sublicense, assign, distribute, publish, transfer, or
            otherwise make available the Services or Documents; (iii) reverse
            engineer, disassemble, decompile, decode, adapt, or otherwise
            attempt to derive or gain access to any software component of the
            Services, in whole or in part; (iv) remove any proprietary notices
            from the Services or Documents; (v) use the Services or Documents in
            any manner or for any purpose that infringes, misappropriates, or
            otherwise violates any intellectual property right or other right of
            any person or entity (including JDForm), or that violates any
            applicable law, regulation, or rule; or (v) access or use the
            Services or Documents to compete, directly or indirectly, with
            JDForm or for any other competitive purposes.
          </P>

          <P>
            <Indent />
            <Indent>3.</Indent>
            <U>Suspension</U>. Notwithstanding anything to the contrary in this
            Agreement, JDForm may temporarily suspend your access to any portion
            or all of the Services if: (i) JDForm determines that; (A) your use
            of the Services, Documents, or any other intellectual property
            provided to you in connection with the foregoing (collectively, “
            <b>JDForm IP</b>”) disrupts or poses a security other risk to the
            JDForm IP or to any other customer or vendor of JDForm; (B) there is
            a threat or attack on any of the JDForm IP; (C) you are using the
            JDForm IP for (x) fraudulent or illegal activities, or (y) in
            violation of, or in a manner otherwise otherwise inconsistent with,
            this Agreement; (D) JDForm’s provision of the Services to you is
            prohibited by applicable law, regulation, or rule; (ii) any vendor
            of JDForm has suspended or terminated JDForm’s access to or use of
            any third-party services or products required to enable your access
            the Services; or (iii) you fail to pay any Fees (defined below) when
            due.
          </P>

          <P>
            <Indent />
            <Indent>4.</Indent>
            <U>Reservation of Rights</U>. JDForm reserves all rights not
            expressly granted to you in this Agreement. Except for the limited
            rights expressly granted under this Agreement, nothing in this
            Agreement grants, by implication, waiver, estoppel, or otherwise, to
            you or any third party, any intellectual property rights or other
            right, title, or interest in or to the JDForm IP.
          </P>

          <P>
            <Indent />
            <Indent>5.</Indent>
            <U>Passwords</U>. You are responsible for keeping your passwords and
            access credentials associated with the Services confidential. You
            shall not sell, transfer, or otherwise make your passwords or access
            credentials available to any other person or entity, including
            individuals within your organization.
          </P>

          <P>
            <Indent />
            <Indent>6.</Indent>
            <U>Fees and Payment</U>. Where applicable, you shall pay JDForm the
            fees for the Services as described on the Site (“
            <b>Fees</b>”).
          </P>

          <P>
            <Indent />
            <Indent>7.</Indent>
            <U>Intellectual Property</U>. JDForm owns all right, title, and
            interest, including all intellectual property rights, in and to the
            JDForm IP. If you send or transmit any communications or materials
            to us suggesting or recommending changes to the Services or
            Documents (“<b>Feedback</b>”), we are free to use such Feedback
            irrespective of any other obligation or limitation between you and
            us governing such Feedback. All Feedback is and will be treated as
            non-confidential. You hereby assign to us right, title, and interest
            in, and we are free to use, without any attribution or compensation
            to you or any third party, any ideas, know-how, concepts,
            techniques, or other intellectual property rights contained in the
            Feedback, for any purpose whatsoever.
          </P>

          <P>
            <Indent />
            <Indent>8.</Indent>
            <U>Privacy Policy</U>. JDForm complies with its privacy policy,
            available on the Site (“
            <b>Privacy Policy</b>”), in providing the Services. You acknowledge
            that you have reviewed and accepted our Privacy Policy, and you
            consent to all actions taken by us with respect to your information
            in compliance with the then-current version of our Privacy Policy.
          </P>

          <P>
            <Indent />
            <Indent>9.</Indent>
            <U>No Warranty</U>. THE SERVICES AND DOCUMENTS ARE PROVIDED “AS IS”
            AND JDFORM SPECIFICALLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS,
            IMPLIED, STATUTORY, OR OTHERWISE. JDFORM SPECIFICALLY DISCLAIMS ALL
            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, TITLE, AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING
            FROM COURSE OF DEALING, USAGE, OR TRADE PRACTICE. JDFORM MAKES NO
            WARRANTY OF ANY KIND THAT SERVICES OR DOCUMENTS, OR ANY RESULTS OF
            THE USE THEREOF, WILL MEET YOUR OR ANY OTHER PERSON’S OR ENTITY’S
            REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED
            RESULT, BE COMPATIBLE OR WORK WITH ANY OF YOUR OR ANY THIRD PARTY’S
            SOFTWARE, SYSTEM, OR OTHER SERVICES, OR BE SECURE, ACCURATE,
            COMPLETE, FREE OF HARMFUL CODE, OR ERROR-FREE, OR THAT ANY ERRORS OR
            DEFECTS CAN OR WILL BE CORRECTED. THE SERVICES ARE NOT LEGAL ADVICE
            AND NO ATTORNEY-CLIENT RELATIONSHIP IS CREATED BY YOUR USE OF THE
            SERVICES OR DOCUMENTS.
          </P>

          <P>
            <Indent />
            <Indent>10.</Indent>
            <U>Indemnification</U>. You shall indemnify, hold harmless, and, at
            JDForm’s option, defend JDForm and its officers, directors,
            managers, members, employees, agents, affiliates, successors, and
            assigns from and against any and all losses, damages, liabilities,
            costs (including attorneys’ fees) (“
            <b>Losses</b>”) arising from or relating to any third-party claim,
            suit, action, or proceeding (“<b>Third-Party Claim</b>”) based on
            your negligence or willful misconduct or your use of the Services
            and Documents; provided that you shall not settle any Third-Party
            Claim against JDForm unless JDForm consents to such settlement, and
            further provided that JDForm will have the right, at its option, to
            defend itself against any such Third-Party Claim or to participate
            in the defense thereof by counsel of its own choice.
          </P>

          <P>
            <Indent />
            <Indent>11.</Indent>
            <U>Limitation of Liability</U>. IN NO EVENT WILL JDFORM BE LIABLE
            UNDER OR IN CONNECTION WITH THIS AGREEMENT UNDER ANY LEGAL OR
            EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING
            NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE, FOR ANY: (a)
            CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED,
            OR PUNITIVE DAMAGES; (b) INCREASED COSTS, DIMINUTION IN VALUE OR
            LOST BUSINESS, PRODUCTION, REVENUES, OR PROFITS; (c) LOSS OF
            GOODWILL OR REPUTATION; (d) USE, INABILITY TO USE, LOSS,
            INTERRUPTION, DELAY OR RECOVERY OF ANY DATA, OR BREACH OF DATA OR
            SYSTEM SECURITY; OR (e) COST OF REPLACEMENT GOODS OR SERVICES, IN
            EACH CASE REGARDLESS OF WHETHER JDFORM WAS ADVISED OF THE
            POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE
            OTHERWISE FORESEEABLE. IN NO EVENT WILL JDFORM’S AGGREGATE LIABILITY
            ARISING OUT OF OR RELATED TO THIS AGREEMENT UNDER ANY LEGAL OR
            EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING
            NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE EXCEED THE TOTAL AMOUNTS
            PAID TO JDFORM UNDER THIS AGREEMENT IN THE 12 MONTH PERIOD PRECEDING
            THE EVENT GIVING RISE TO THE CLAIM OR $10.00, WHICHEVER IS LESS.
          </P>

          <P>
            <Indent />
            <Indent>12.</Indent>
            <U>Payment Processor</U>. Stripe is our payment processor. Stripe’s
            payment processing services are subject to the Stripe Connected
            Account Agreement, which includes the Stripe Terms of Service
            (collectively, the “<b>Stripe Services Agreement</b>”). By agreeing
            to these Terms, or by using or accessing the Services, you agree to
            be bound by the Stripe Services Agreement.
          </P>

          <P>
            <Indent />
            <Indent>13.</Indent>
            <U>Term and Termination</U>. The term of this Agreement begins on
            the Effective Date and continues until terminated. Your access to
            the Services will have the duration specified on the Site for the
            Services plan you purchase. Services that are specified to
            automatically renew will renew for successive 12-month terms unless
            earlier terminated pursuant to this Agreement or either party gives
            the other party written notice of non-renewal at least 30 days prior
            to the expiration of the then-current services period. Services that
            are specified to continue for a certain number of days will end upon
            expiration of that number of days unless earlier terminated pursuant
            to this Agreement. In addition to any other termination right set
            forth in this Agreement, JDForm may terminate this Agreement (a) for
            any reason upon 10 days’ advance notice or (b) effective on written
            notice to you, if you breach or otherwise violate this Agreement.
          </P>

          <P>
            <Indent />
            <Indent>14.</Indent>
            <U>Effect of Termination</U>. Upon termination of this Agreement,
            you shall immediately discontinue use of Services; provided that you
            may continue to use Documents you have downloaded prior to the
            effective termination date in accordance with the terms of this
            Agreement. No expiration or termination of this Agreement will
            affect your obligation to pay all Fees that may have become due
            before such expiration or termination, or entitle you to any refund,
            except if JDForm terminates this Agreement pursuant to{" "}
            <U>Section 13(a)</U> (relating to voluntary termination by JDForm)
            and you have purchased an annual subscription for the Services,
            JDForm will refund the pro rata portion of Fees that you paid for
            the Services not provided.
          </P>

          <P>
            <Indent />
            <Indent>15.</Indent>
            <U>Survival</U>. This <U>Section 15</U>,<U>Sections 6</U> (Fees and
            Payment), <U>7</U> (Intellectual Property), <U>9</U>
            (No Warranty), <U>10</U> (Indemnification), <U>11</U> (Limitation of
            Liability),
            <U>12</U> (Payment Processor Terms), <U>14</U> (Effect of
            Termination), <U>16</U>
            (Modifications), <U>17</U> (Governing Law and Jurisdiction), and{" "}
            <U>18</U>
            (Miscellaneous) survive any termination or expiration of this
            Agreement. No other provisions of this Agreement survive the
            expiration or earlier termination of this Agreement.
          </P>

          <P>
            <Indent />
            <Indent>16.</Indent>
            <U>Modifications</U>. You acknowledge and agree that we have the
            right, in our sole discretion, to modify this Agreement, and that
            modified terms become effective on posting. You will be notified of
            modifications through notifications or posts on the Site. Your
            continued use of the Services after the effective date of the
            modifications will be deemed acceptance of the modified terms.
          </P>

          <P>
            <Indent />
            <Indent>17.</Indent>
            <U>Governing Law and Jurisdiction</U>. This agreement is governed by
            and construed in accordance with the laws of the State of Washington
            without giving effect to any choice or conflict of law provision or
            rule that would require or permit the application of the laws of any
            jurisdiction other than those of the State of Washington. Any legal
            suit, action, or proceeding arising out of or related to this
            Agreement or the rights granted hereunder must be instituted
            exclusively in the courts of the State of Washington located in
            Kitsap County, Washington, or at the exclusive option of JDForm, the
            United States District Court for the Western District of Washington
            (Tacoma Division), and each party irrevocably submits to the
            exclusive jurisdiction of such courts in any such suit, action, or
            proceeding.
          </P>

          <P>
            <Indent />
            <Indent>18.</Indent>
            <U>Miscellaneous</U>. This Agreement constitutes the entire
            agreement and understanding between the parties hereto with respect
            to the subject matter hereof and supersedes all prior and
            contemporaneous understandings, agreements, representations, and
            warranties, both written and oral, with respect to such subject
            matter. Any notices to us must be sent to our headquarters address
            available on the Site and must be delivered either in person, by
            certified or registered mail, return receipt requested and postage
            prepaid, or by recognized overnight courier service, and are deemed
            given upon receipt by us. Notwithstanding the foregoing, you hereby
            consent to receiving electronic communications from us. These
            electronic communications may include notices about applicable fees
            and charges, transactional information, and other information
            concerning or related to the Services. You agree that any notices,
            agreements, disclosures, or other communications that we send to you
            electronically will satisfy any legal communication requirements,
            including that such communications be in writing. In no event will
            we be liable to you for any failure or delay in performing its
            obligations under this Agreement, if such failure or delay is caused
            by any circumstances beyond our control. The invalidity, illegality,
            or unenforceability of any provision herein does not affect any
            other provision herein or the validity, legality, or enforceability
            of such provision in any other jurisdiction. Any failure to act by
            us with respect to a breach of this Agreement by you or others does
            not constitute a waiver and will not limit our rights with respect
            to such breach or any subsequent breaches. This Agreement is
            personal to you and may not be assigned or transferred for any
            reason whatsoever without our prior written consent and any action
            or conduct in violation of the foregoing will be void and without
            effect. We expressly reserve the right to assign this Agreement and
            to delegate any of its obligations hereunder.
          </P>
        </Document>
      </MainContent>

      <Footer />
    </div>
  );
};

export default SaasAgreement;
