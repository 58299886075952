import React from 'react';

export default function CreditCard({ card }) {
  return (
    <div className="card br-corner py-3 px-5 w-md">
      <div className="flex align-center justify-between mb-1">
        <p className="text-uppercase fs-md fw-700 mr-8">
          {`${ card.name}`}
        </p>
        <p className="color-black-50 fs-xs">Expires</p>
      </div>
      <div className="flex align-center justify-between">
        <p className="color-black-70 fs-md">xxxx xxxx xxxx {card.card_last4}</p>
        <p className="color-black fs-md">
          { card.card_exp_month > 9? card.card_exp_month: '0' + card.card_exp_month}
          /{ card.card_exp_year % 1000 }
        </p>
      </div>
    </div>
  );
}
