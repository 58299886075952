import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { getEditors, deleteEditors } from "./../../store/actions/admin";
import { resourcePaginate } from "./../../helpers/resourcePaginate";
import CreateUser from "./createUser";
import Successfull from "./successfull";

class Editors extends PureComponent {
  state = {
    id: null,
    data: null,
    count: null,
    error: null,
    open: false,
    editors: [],
    successfull: false,
    openDeleteModal: false
  };
  async componentDidMount() {
    await this.props.getEditors();
  }
  onOpenModal = () => {
    this.setState({ open: true });
  };

  onOpenDelModal = id => {
    this.setState({ openDeleteModal: true, id });
  };

  onCloseModal = () => {
    this.setState({ open: false, successfull: false });
  };

  onCloseDelModal = () => {
    this.setState({ openDeleteModal: false });
  };
  onSuccess = data => {
    this.setState(prevState => ({ successfull: !prevState.successfull, data }));
  };
  handleDelete = async () => {
    const res = await this.props.deleteEditors(this.state.id);
    if (res && res.payload && res.payload.status === 204) {
      await this.props.getEditors();
      this.onCloseDelModal();
    } else {
      this.setState({ error: "Something went wrong" });
    }
  };

  render() {
    const {
      open,
      successfull,
      data,
      openDeleteModal,
      error
    } = this.state;
    const editors = this.props.editors.editors;
    const count = this.props.editors.count;

    const index =
      this.props.editors.next && this.props.editors.next.indexOf("=");
    const page =
      this.props.editors.next && this.props.editors.next.substr(index + 1);
    return (
      /* Editors page start */
      <div>
        <main className="admin-content manage">
          <div className="container-sm">
            <div className="flex align-center justify-between mb-5">
              <h2 className="font-secondary fw-600">EDITORS</h2>
              <button
                className="btn filled primary h-lg br-round shadow w-md flex justify-center align-center"
                onClick={this.onOpenModal}
              >
                <i className="icon-plus fs-xl mr-1"></i> ADD NEW
              </button>
              {/*  Add new editor popup start */}
              <Modal open={open} center onClose={this.onCloseModal}>
                {/* When user will click on "Send invitation" button and the request will be successful t following content needs to be displayed in the pop up */}
                {/*   */}
                {successfull ? (
                  <Successfull
                    onClose={this.onCloseModal}
                    data={data}
                    user={"editor"}
                  />
                ) : (
                  <CreateUser
                    onSuccess={this.onSuccess}
                    getUsers={this.props.getEditors}
                    user={"editor"}
                  />
                )}
              </Modal>
              {/* Add new editor popup end */}
            </div>

            {editors && editors.length > 0 && count && (
              <PerfectScrollbar
                style={{ height: "660px" }}
                onYReachEnd={() => {
                  resourcePaginate(
                    editors,
                    count,
                    this.props.getEditors,
                    null,
                    page
                  );
                }}
              >
                {editors.map(data => {
                  return (
                    <div
                      className={`card br-corner manage-file ${!data.is_active &&
                        "disabled"}`}
                      key={data.id}
                    >
                      <div className="content">
                        <h4 className="title">{`${data.first_name} ${
                          data.last_name
                        }`}</h4>
                        <p className="sub-content">{`${data.email}`}</p>
                      </div>
                      <ul className="actions">
                        {data.is_active ? (
                          <li>
                            <button
                              className="btn-icon delete"
                              onClick={id => this.onOpenDelModal(data.id)}
                            >
                              <i className="icon-delete">{""}</i>
                            </button>
                          </li>
                        ) : (
                          <p className="status">Invited</p>
                        )}
                      </ul>
                    </div>
                  );
                })}
              </PerfectScrollbar>
            )}
            {/*  Delete confirmation popup start */}
            <Modal open={openDeleteModal} onClose={this.onCloseDelModal} center>
              <div className="popup-content">
                <form action="">
                  <p className="fs-lg text-xs-left color-black-60 mt-5 ml-2">
                    Are you sure that you want to delete this editor?
                  </p>
                  <div className="buttons mt-10 text-xs-right">
                    <button
                      type="button"
                      className="btn filled primary br-round h-lg w-md fs-md shadow ml-3"
                      onClick={this.handleDelete}
                    >
                      Yes
                    </button>
                    <button
                      className="btn link primary h-lg w-md fs-md"
                      onClick={this.onCloseDelModal}
                      type="button"
                    >
                      No
                    </button>
                  </div>
                  {error && <p>{error}</p>}
                </form>
              </div>
            </Modal>
            {/* Delete confirmation popup end */}
            {/* <Pagination /> */}
          </div>
        </main>
      </div>
      /* Editors page end */
    );
  }
}

function mapStateToProps(state) {
  return {
    editors: state.admin
  };
}

export default connect(
  mapStateToProps,
  { getEditors, deleteEditors }
)(Editors);
