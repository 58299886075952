import React, { Component } from 'react'
import { removeToken, getContributor, getEditor } from '../../store/actions/auth'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom';
import logo_blue from "./../../assets/img/logo-blue.png";

class ProfileHeader extends Component {

  searchEvent = new Event('search');

  handleLoguot = e => {
    e.preventDefault();
    localStorage.removeItem('auth');
    localStorage.removeItem('role');
    localStorage.removeItem('id');
    sessionStorage.removeItem('auth');
    sessionStorage.removeItem('role');
    this.props.removeToken();
    this.props.history.push('/');
  }

  state = {
    open: false,
    inputValue: '',
    nameSortCut: null,
    show: false,
    searching: false,
  }

  async componentDidMount () {
    const role = localStorage.getItem('role') || sessionStorage.getItem('role');
    const id = localStorage.getItem('id') || sessionStorage.getItem('id');
    role === '4' && (await this.props.getContributor(id));
    role === '3' && (await this.props.getEditor(id));

    const dropLabel = document.getElementById('dropdown-label')
    // const dropdown = document.getElementById("dropdown");
    const body = document.body;
    body.onclick = () => {
      this.setState({ open: false });
    };

    dropLabel.onclick = e => {
      this.setState({ open: !this.state.open });
      e.stopPropagation();
    }
  }

  componentWillUnmount() {
    const { setSearchValue } = this.props;

    if(!this.props.searching){
      setSearchValue && setSearchValue('');
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { pathname, } = this.props.location;
    const { searching } = this.state;
    if (prevProps.location.pathname.includes('/documents') && !pathname.includes('/documents')){
      if (!searching && this.props.searchValue) {
        this.props.setSearchValue('');
        this.setState({searching: false});
      }
    }
  }


  searchDocuments = e => {
    e && e.preventDefault();
    const role = localStorage.role ? localStorage.role : sessionStorage.role
    let {inputValue} = this.state;
    if (role === '1') {
      this.props.history.push('/', { value: inputValue })
    } else {
      this.searchDocument.dispatchEvent(this.searchEvent);
      this.setState({ searching: true });
    }
  };

  render () {
    const {show} = this.state;
    const role = localStorage.role ? localStorage.role : sessionStorage.role;
    const profile = this.props.profile && this.props.profile.data;
    const pathName = this.props.location.pathname.split('/')[1];
    const isDocuments = this.props.location.pathname.includes('/documents');
    const { open } = this.state;
    let first_name, last_name;
    if (profile) {
      first_name = profile.first_name && profile.first_name[0].toUpperCase();
      last_name = profile.last_name && profile.last_name[0].toUpperCase();
    }


    return (
      /* admin header start */
      <header className={`dashboard ${role === '1' ? 'full' : ''}`}>
        <div className="container">
          {role === '1' &&
            <div className="profile-left nav-items flex align-center w-50">
              <div className="brand-logo">
                <a href="/">
                  <img src={logo_blue} alt="Brand logo" />
                </a>
              </div>
              <ul className="nav">
                <li>
                  <a href="http://jmdorsey.com/" target="_blank">
                    By John Dorsey PLLC
                  </a>
                </li>
                <li>
                  <Link to="/pricing">PRICING</Link>
                </li>
              </ul>
            </div>
          }

          <div className="content flex align-center justify-end">
            {
              isDocuments ? (
                <>
                  <div className={`text-field search mb-0 ${show ? 'show': ''}`}>
                    <form onSubmit={this.searchDocuments} className="w-100">
                      <input
                        ref={ref => this.searchDocument = ref }
                        id='searchDocument'
                        name={'search'}
                        value={this.props.searchValue}
                        type="text"
                        placeholder="Search"
                        autoFocus
                        onChange={(e) => this.onChange(e.target.value)}
                      />
                    </form>
                    <button
                    className="btn-icon close-search fs-lg mr-8"
                    onClick={(e) => this.onChloseSearchHandler()}
                    >
                      <i className="icon-close color-black">{''}</i>
                    </button>
                  </div>
                  <button
                   className="btn-icon open-search fs-xl mr-5"
                   onClick={(e) => this.onOpenSearchHandler()}
                   >
                    <i className="icon-search color-primary">{''}</i>
                  </button>
                </>
              ): null
            }
            <div className="details">
              <button className="name-avatar btn-icon" id="dropdown-label">
                { role === '2'? 'A' : profile? first_name + last_name: '...' }
              </button>
              <div className="details-dropdown" id="dropdown" style={{ display: open ? 'block' : 'none' }}>
                {profile ? (
                  <Link to={`/${pathName}/profile`} className="inline-block">
                    <div className="flex dropdown-content">
                      <p className="name-avatar mr-3">{first_name + last_name}</p>
                      <div className="content">
                        <h3>{`${profile.first_name} ${profile.last_name}`}</h3>
                        <p>{`${profile.email}`}</p>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div className="flex dropdown-content">
                    <p className="content">Administrator</p>
                  </div>
                )}
                <div className="text-xs-right mr-9 mt-1">
                  <button
                    className="btn-icon color-primary inline-block"
                    onClick={this.handleLoguot}
                  >
                    Sign out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      /* admin header end */
    )
  }

  onChange = async inputValue => {
    this.setState({ inputValue, searching: false });
    await this.props.setSearchValue(inputValue);
    if (!inputValue){
      this.searchDocument.dispatchEvent(this.searchEvent);
    }
  }

  onChloseSearchHandler = () => {
    const { show } = this.state;
    this.setState({ show: !show });
    this.props.setSearchValue('');
  }

  onOpenSearchHandler = async() => {
    const { show } = this.state;

    this.setState({ show: !show });
  }
}

function mapStateToProps (state) {
  return {
    profile: state.auth.profile
  }
}

export default connect(
  mapStateToProps,
  {
    removeToken,
    getContributor,
    getEditor,
  }
)(withRouter(ProfileHeader))
