import React from "react";
import styled from "styled-components";
import { colors } from "../../../styles";

const PurchaseBox = styled.div`
  box-shadow: 0px 3px 6px #00000029;
  width: 448px;
`;

const PurchaseTitleBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.backgroundColor};
  color: white;
  font-size: 30px;
  letter-spacing: -0.3px;
  font-weight: 700;
  height: 72px;
`;

const Price = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.blueDark};
  font-size: 40px;
  letter-spacing: -0.3px;
  font-weight: 700;
  height: 72px;
`;

/**
 * @description Component for user plans.
 * @param plan - plan item.
 * @param subscription - Subscription of the user.
 * @param benefits - Plans benefits.
 * @param purchaseHandler - Purchases the plan.
 * @param cancelSubscription - Cancel plan subscription.
 * @param is_bought - Document is bought or not by user.
 */
export default function Plan({
  plan,
  benefits,
  subscription,
  purchaseHandler,
  cancelSubscription,
  is_bought,
  FeatureListComponent,
  title,
  titleBackgroundColor,
  price,
}) {
  let isCurrent = false;
  let isCancelable = false;
  let isDisable = false;

  if (subscription && subscription.plan) {
    if (subscription.subscription_type === 1) {
      //Subscription is annual
      if (plan.id === subscription.plan.id) {
        isCancelable = !subscription.cancel_at_period_end;
        isCurrent = true;
      } else {
        isDisable = true;
      }
    } else {
      if (plan.id === subscription.plan.id) {
        isCurrent = true;
        isDisable = !!is_bought;
      }
    }
  }

  return (
    <PurchaseBox>
      <PurchaseTitleBar backgroundColor={titleBackgroundColor}>
        <div>{title}</div>
      </PurchaseTitleBar>
      <Price>{price}</Price>
      {isCurrent && <p className="current-plan">Your current plan</p>}
      <FeatureListComponent
        benefits={plan.plan_benefits.map((b) => benefits[b])}
        purchaseHandler={() => purchaseHandler(plan)}
      />
      {/* {isCancelable ? (
        <button
          type="button"
          className="btn filled outline text-primary border-black-20 h-lg br-round w-xl"
          onClick={() => cancelSubscription(subscription.id)}
        >
          Cancel Subscription
        </button>
      ) : (
        <button
          onClick={() => purchaseHandler(plan)}
          className="btn filled primary h-lg br-round shadow w-lg"
          disabled={isDisable}
        >
          Purchase
        </button>
      )} */}
    </PurchaseBox>
  );
}
