import styled from "styled-components";
import draftWatermark from "../../assets/img/draftWatermark.svg";

/** The parent must have position: relative */
const DraftWatermark = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${draftWatermark});
  background-repeat: repeat-y;
  background-position: center top;
  opacity: 0.5;
`;

export default DraftWatermark;
