import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";
import { LinkedIn } from "react-linkedin-login-oauth2";

import { HaveAccount } from "./haveAccount";
import {
  signupUser,
  signUpWithGoogle,
  signUpWithLinkedIn,
} from "../../../store/actions/auth";
import { getAbout } from "../../../store/actions/admin";
import Login from "../login/login";
import SuccessPopup from "../successPopup";
import linkedin from "./../../../assets/img/linkedin.svg";
import { socialLogIn } from "../../../helpers/improve";
import IAgreeWith from "./components/iAgreeWith";
import { FRONTEND_BASE_URL, LINKEDIN_CLIENT_ID } from "../../../constants";
import { GoogleLogin } from "@react-oauth/google";
import styled from "styled-components";

const CenteredImg = styled.img`
  margin: auto;
`;

const SocialLoginGroup = styled.div`
  gap: 16px;
`;

class UserSignUp extends Component {
  state = {
    component: "signUp",
  };

  componentDidMount() {
    this.props.getAboutFiles();
  }

  signInWithGoogleSuccess = async (res) => {
    const response = await this.props.signUpWithGoogle(res.credential);
    if (response.type === "sign_in_user") {
      const { token, id } = response.payload.data;
      socialLogIn(token, id, "1");
      this.props.onClose("modalSignIn");
      window.location = "/";
    } else {
      this.props.setErrors({
        ...this.props.errors,
        signUp: "The provided email already exists.",
      });
    }
  };

  signWithGoogleFailure = (err) => {};

  signInWithLinkedInSuccess = async (code) => {
    const response = await this.props.signUpWithLinkedIn(code);
    if (response.type === "sign_in_user") {
      const { token, id } = response.payload.data;
      socialLogIn(token, id, "1");
      this.props.onClose("modalSignUp");
      window.location = "/";
    } else if (response.type === "auth_error") {
      this.props.setErrors({
        ...this.props.errors,
        signUp: "The provided email already exists.",
      });
    }
  };

  signInWithLinkedInFailure = async (res) => {
    this.props.setErrors({
      ...this.props.errors,
      signUp: "The provided email already exists.",
    });
  };

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      aboutFiles,
    } = this.props;
    const { component } = this.state;

    console.log(errors, touched);

    return (
      <Fragment>
        {!values.success && component === "signUp" && (
          <Form action="">
            {/* User Content start */}
            <div>
              <div className="flex">
                <div
                  className={`text-field br-corner w-50 mr-2 ${touched.first_name &&
                    errors.first_name &&
                    "invalid"}`}
                >
                  <Field
                    type="text"
                    placeholder="First name*"
                    name="first_name"
                    value={values.first_name}
                  />
                  {touched.first_name && errors.first_name && (
                    <p className="error-msg">{errors.first_name}</p>
                  )}
                </div>
                <div
                  className={`text-field br-corner w-50 ml-2 ${touched.last_name &&
                    errors.last_name &&
                    "invalid"}`}
                >
                  <Field
                    type="text"
                    placeholder="Last name*"
                    name="last_name"
                    value={values.last_name}
                  />
                  {touched.last_name && errors.last_name && (
                    <p className="error-msg">{errors.last_name}</p>
                  )}
                </div>
              </div>
              <div
                className={`text-field br-corner ${touched.email &&
                  errors.email &&
                  "invalid"}`}
              >
                <Field
                  type="text"
                  placeholder="Email address*"
                  value={values.email}
                  name="email"
                />
                {touched.email && errors.email && (
                  <p className="error-msg">{errors.email}</p>
                )}
              </div>
              <div
                className={`text-field br-corner ${touched.password &&
                  errors.password &&
                  "invalid"}`}
              >
                <Field
                  type="password"
                  placeholder="Password*"
                  name="password"
                  value={values.password}
                />
                {touched.password && errors.password && (
                  <p className="error-msg">{errors.password}</p>
                )}
              </div>
              <div
                className={`text-field br-corner ${touched.confirmPassword &&
                  errors.confirmPassword &&
                  "invalid"}`}
              >
                <Field
                  type="password"
                  placeholder="Confirm password*"
                  name="confirmPassword"
                  value={values.confirmPassword}
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <p className="error-msg">{errors.confirmPassword}</p>
                )}
              </div>
            </div>
            <div />

            <IAgreeWith
              values={values}
              handleChange={handleChange}
              handleBlure={handleBlur}
              aboutFiles={aboutFiles}
            />

            <div>
              {errors.searchable && touched.searchable && (
                <p className="popup-error-msg mt-0">{errors.searchable}</p>
              )}
            </div>

            <div>
              {errors.signUp && (
                <p className="popup-error-msg mt-0">{errors.signUp}</p>
              )}
            </div>

            <div className="mb-4">
              <button
                className="btn filled primary br-round shadow h-lg w-lg"
                type="submit"
              >
                CREATE AN ACCOUNT
              </button>
            </div>
            <div className="flex align-center justify-center mt-4 mb-4">
              <SocialLoginGroup className="social-signup flex column justify-center">
                <div className="fs-md color-black-60 mr-2">
                  or create an account with:
                </div>
                <div>
                  <GoogleLogin
                    onSuccess={this.signInWithGoogleSuccess}
                    onError={this.signWithGoogleFailure}
                  />
                </div>
                <LinkedIn
                  clientId={LINKEDIN_CLIENT_ID}
                  onFailure={this.signInWithLinkedInFailure}
                  onSuccess={this.signInWithLinkedInSuccess}
                  redirectUri={`${FRONTEND_BASE_URL}/linkedin`}
                  scope="r_liteprofile,r_emailaddress"
                >
                  {({ linkedInLogin }) => (
                    <button
                      className="btn-icon"
                      type={"button"}
                      onClick={linkedInLogin}
                    >
                      <CenteredImg src={linkedin} alt="Linkedin" />
                    </button>
                  )}
                </LinkedIn>
              </SocialLoginGroup>
            </div>
            <HaveAccount handleComponent={this.props.handleComponent} />
          </Form>
        )}

        {values.success && (
          <SuccessPopup
            text={
              "An email was sent to your email address. Please check your email to finalize your  registration."
            }
            onClose={this.props.onClose}
            icon="envelop"
            title={true}
            modal_name={"modalSignUp"}
          />
        )}
        {component === "login" && <Login onClose={this.props.onClose} />}
      </Fragment>
    );
  }
}

const SignUpFormik = withFormik({
  mapPropsToValues({
    first_name,
    last_name,
    email,
    password,
    confirmPassword,
    organization_name,
    organization_type,
  }) {
    return {
      first_name: first_name || "",
      last_name: last_name || "",
      email: email || "",
      password: password || "",
      confirmPassword: confirmPassword || "",
      organization_name: organization_name || "",
      organization_type: organization_type || "",
      searchable: false,
    };
  },
  validationSchema: Yup.object().shape({
    first_name: Yup.string()
      .required("This field is mandatory.")
      .max(200, "Maximum number of symbols is 200."),
    last_name: Yup.string()
      .required("This field is mandatory.")
      .max(200, "Maximum number of symbols is 200."),
    email: Yup.string()
      .email("Please provide a valid email.")
      .max(200, "Maximum number of symbols is 200.")
      .required("This field is mandatory."),
    password: Yup.string()
      .min(6, "The Password field must be 6 or more characters.")
      .max(20, "Maximum number of symbols is 20.")
      .required("This field is mandatory."),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password")],
        "The confirm password confirmation does not match."
      )
      .required("This field is mandatory."),
    searchable: Yup.mixed().oneOf(
      [true],
      "You must be agree with our Terms of Use and Pricing Policy."
    ),
  }),

  async handleSubmit(values, { props, setErrors, setValues }) {
    const handleErrors = {
      email: "The provided email already exists.",
      role: "You should choose one of the options",
    };
    const data = {
      email: values.email.trim(),
      password: values.password,
      first_name: values.first_name,
      last_name: values.last_name,
      organization_name: values.organization_name
        ? values.organization_name
        : undefined,
      organization_type: values.organization_type.value,
      role: 1,
    };

    const res = await props.signupUser(data, "user");

    if (res && res.payload.status === 201) {
      props.openSuccess();
      setValues({ success: true });
    }

    if (res && res.payload.status === 400) {
      const errors = {};
      if (
        res.payload.data.email &&
        res.payload.data.email.length &&
        res.payload.data.email[0] === "Enter a valid email address."
      ) {
        errors.email = "Please provide a valid email.";
      } else {
        Object.keys(res.payload.data).map((data) => {
          errors[data] = handleErrors[data];
        });
      }
      setErrors({ ...errors });
    }
  },
})(UserSignUp);

const mapStateToProps = ({ admin: { about } }) => ({
  aboutFiles: about,
});

export default withRouter(
  connect(mapStateToProps, {
    signupUser,
    signUpWithGoogle,
    signUpWithLinkedIn,
    getAboutFiles: getAbout,
  })(SignUpFormik)
);
