import React, { Component, Fragment } from 'react'
import { withFormik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { signUpStepper, checkEmail } from './../../../store/actions/auth'
import PhoneNumber from './../../../helpers/phoneNumberInput'
import { HaveAccount } from './haveAccount'

class ContributorSignUp extends Component {
  state = {
    phone_number: '',
    phoneNumberError: '',
    isFirstRender: true,
  };

  componentDidMount () {
    if (this.props.stepperData.first_name) {
      this.props.setValues({
        ...this.props.stepperData,
        confirmPassword: this.props.stepperData.password,
      });
      this.setState({ phone_number: this.props.stepperData.phone_number })
    }
    // if ( this.props.contributorData && this.state.isFirstRender ){
    //   const { first_name, last_name, email } = this.props.contributorData;
    //   this.props.setValues({
    //     first_name, last_name, email
    //   });
    // }
  }

  checkNumber = () => {
    const { phone_number } = this.state;
    if (phone_number && !this.state.phoneNumberError) {
      const value = this.state.phone_number;
      !value && this.setState({ phoneNumberError: 'This field is mandatory' });
      if (value && !isValidPhoneNumber(value)) {
        this.props.setValues({
          ...this.props.values,
          phoneNumberError: 'Invalid phone number',
          phone_number: value
        });
      } else {
        this.props.setValues({
          ...this.props.values,
          phoneNumberError: '',
          phone_number: value
        });
      }
    }
    this.setState({ isFirstRender: false });
  };

  handleNumberTouched = value => {
    !value && this.setState({ phoneNumberError: 'This field is mandatory' });
  };

  handleNumber = value => {
    if (value) {
      this.props.setValues({
        ...this.props.values,
        phone_number: value,
      })
    }else{
        this.props.setValues({
            ...this.props.values,
            phoneNumberError: '',
        })
    }
    this.setState({ phone_number: value, phoneNumberError: '' })
  }

  render () {
    const {
      values,
      errors,
      touched,
      handleSubmit,
      contributorData
    } = this.props;


    return (
      <Fragment>
        <div>
          {/* Contributing lower first step content start */}
          <Form onSubmit={handleSubmit}>
            {
              contributorData && <div className="flex">
                <div
                  className={`text-field br-corner w-50 mr-2 disabled`}
                >
                  <Field
                    type="text"
                    placeholder="First name*"
                    name="first_name"
                    value={contributorData.first_name}
                  />
                </div>
                <div
                  className={`text-field br-corner w-50 ml-2 disabled `}
                >
                  <Field
                    type="text"
                    placeholder="Last name*"
                    name="last_name"
                    value={contributorData.last_name}
                  />
                </div>
              </div>
            }
            {
              contributorData &&
              <div
                className={`text-field br-corner disabled `}
              >
                <Field
                  type="text"
                  placeholder="Email address"
                  value={contributorData.email}
                  name="email"
                />
              </div>
            }
            <div
              className={`text-field br-corner ${touched.password &&
              errors.password &&
              'invalid'}`}
            >
              <Field
                type="password"
                placeholder="Password*"
                name="password"
                value={values.password}
              />
              {touched.password && errors.password && (
                <p className="error-msg">{errors.password}</p>
              )}
            </div>
            <div
              className={`text-field br-corner ${touched.confirmPassword &&
              errors.confirmPassword &&
              'invalid'}`}
            >
              <Field
                type="password"
                placeholder="Confirm password*"
                name="confirmPassword"
                value={values.confirmPassword}
              />
              {touched.confirmPassword && errors.confirmPassword && (
                <p className="error-msg">{errors.confirmPassword}</p>
              )}
            </div>
            <div
              className={`text-field br-corner ${this.state.phoneNumberError || values.phoneNumberError && 'invalid'}`}
            >
              <PhoneNumber
                name="phone_number"
                handleNumber={this.handleNumber}
                value={this.state.phone_number}
                onBlur={this.handleNumberTouched}
              />

              {(values.phoneNumberError) && (
                <p className="error-msg"> {values.phoneNumberError}</p>
              )}
            </div>
            {/* Contributing lower first step content end */}
            <div className="mb-4" onClick={this.checkNumber}>
              <button
                className="btn filled primary br-round shadow h-lg w-lg"
                type="submit"
                onClick={this.checkNumber}
              >
                NEXT
              </button>
            </div>
            <HaveAccount
              handleComponent={this.props.handleComponent}
            />
          </Form>
        </div>
      </Fragment>
    )
  }
}

const SignUpFormik = withFormik({
  mapPropsToValues ({
    first_name,
    last_name,
    email,
    password,
    confirmPassword,
    phone_number,
    linkedin_url
  }) {
    return {
      first_name: first_name || '',
      last_name: last_name || '',
      email: email || '',
      password: password || '',
      confirmPassword: confirmPassword || '',
      phone_number: phone_number || '',
      linkedin_url: linkedin_url || '',
      success: false

    }
  },
  validationSchema: Yup.object().shape({

    linkedin_url: Yup.string().url('The provided link should be valid.'),
    email: Yup.string()
    .trim()
    .matches(/^[^@]+@[^@]+$/, {
      message: 'Please provide a valid email.',
      excludeEmptyString: true
    })
    .max(200, 'Maximum number of symbols is 200.'),
    password: Yup.string()
    .min(6, 'The Password field must be 6 or more characters.')
    .max(20, 'Maximum number of symbols is 20.')
    .required('This field is mandatory.'),
    confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'The confirm password confirmation does not match.' )
    .required('This field is mandatory.'),
    // phone_number: Yup.number().required("This field is mandatoryss")
  }),

  async handleSubmit (values, { props, setErrors, setValues }) {

    if (values.phoneNumberError === 'Invalid phone number') {
      setErrors({ phone_number: values.phoneNumberError })
    }

    const data = {
      email: props.contributorData.email.trim(),
      password: values.password,
      first_name: props.contributorData.first_name,
      last_name: props.contributorData.last_name,
      phone_number: values.phone_number,
      linkedin_url: values.linkedin_url
    }

    // const response = await props.checkEmail({ email: values.email })
    // if (response && response.status === 400) {
    //   setErrors({ email: response.data.message })
    // } else {
      if (!values.phoneNumberError) {
        props.signUpStepper(
          Object.keys(props.stepperData).length > 0 ? props.stepperData : values
        )
        props.handleFirstStep(data)
      }
    // }
  }
})(ContributorSignUp)

function mapStateToProps (state) {
  return {
    stepperData: state.auth.stepperData
  }
}

export default connect(
  mapStateToProps,
  { signUpStepper, checkEmail }
)(SignUpFormik)
