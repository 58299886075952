import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import AddCreditCard from "../common/addCreditCard.js";

import Header from "../Homepage/header";
import Footer from "../Homepage/footer";
import CreditCard from "../common/CreditCard";

import {
  getUserCard,
  getPlan,
  setSubscription,
} from "../../store/actions/payment";
import { getDocumentById } from "../../store/actions/documents";
import { handleLoguot, roleChecker } from "../../helpers/improve";

class OrderDetails extends Component {
  state = {
    component: null,
    document_data: null,
    oneTimePayment: null,
    plan_id: null,
    token: "",
    role: "",
    orderInfoModal: false,
    stripe_payment_method_id: null,
    orderInfo: {
      error: "",
      success: "",
      message: "",
    },
    checked: null,
    loading: true,
    button: null,
  };

  async componentDidMount() {
    const role = roleChecker();
    const token = localStorage.auth || sessionStorage.auth;
    if (!this.props.history.location.state) {
      return this.props.history.push("/");
    }
    const {
      document_id,
      oneTimePayment,
      plan_id,
    } = this.props.history.location.state;

    this.props.getUserCard();
    this.props.getPlan(plan_id);

    this.setState({ role, token, oneTimePayment });

    if (document_id) {
      let res, document_data;
      try {
        res = await this.props.getDocumentById(document_id);
        document_data = res.payload.data;
        this.setState({ document_data });
      } catch (e) {
        console.log(e);
      }
    }
    this.setState({ loading: false });
  }

  onOpenModal = (name, component) => this.setState({ [name]: true, component });

  onCloseModal = async (name) => {
    const role = roleChecker();
    const token = localStorage.auth || sessionStorage.auth;

    this.setState({ [name]: false, role, token });
  };

  onCloseOrderModal = () => {
    this.setState({ orderInfoModal: false });
    if (this.state.button) {
      this.props.history.push("/");
    }
  };

  handleLogOut = () => {
    const { history } = this.props;
    handleLoguot();
    history.goBack();
    this.setState({ token: "", role: "" });
  };

  purchaseHandler = async (payment_id) => {
    const { document_data, checked } = this.state;
    const { plan, card } = this.props;

    const data = {
      plan_id: plan.id,
      remember_credit_card: checked || !!card,
      stripe_payment_method_id: payment_id,
    };

    if (document_data && document_data.id) {
      data.document_id = document_data.id;
    }

    this.setState({ loading: true });
    try {
      let message;
      const res = await this.props.setSubscription(data);
      if (res.status === 400) {
        if (res.data.message && res.data.message.length > 0) {
          message = res.data.message[0];
        } else {
          const errorMessage = res.data.length && res.data[0];
          const index = errorMessage.indexOf(": ");
          message = errorMessage.slice(index + 2);
        }
      }

      this.setState({
        orderInfoModal: true,
        orderInfo: {
          success:
            message ||
            (plan.title?.includes("ANNUAL")
              ? "Plan has been successfully purchased"
              : "You have successfully purchased your document."),
        },
        button: message ? "Cancel" : "Done",
      });
    } catch (e) {
      this.setState({
        orderInfoModal: true,
        orderInfo: {
          error: plan.title?.includes("ANNUAL")
            ? "Plan couldn't be purchased"
            : "Document couldn't be purchased",
        },
      });
    }
    this.setState({ loading: false });
  };

  rememberCheckHandler = () => {
    const { checked } = this.state;
    this.setState({ checked: !checked });
  };

  render() {
    const {
      component,
      modalSignIn,
      role,
      token,
      document_data,
      orderInfo,
      orderInfoModal,
      checked,
      loading,
      button,
    } = this.state;
    const { card, plan } = this.props;
    const title = plan.title?.includes("ANNUAL")
      ? "Annual subscription"
      : "One time payment";
    const isOrdered = orderInfo.error || orderInfo.success;

    return (
      <div>
        <Header
          role={role}
          token={token}
          component={component}
          showSearch={false}
          modalSignIn={modalSignIn}
          onOpenModal={this.onOpenModal}
          onCloseModal={this.onCloseModal}
          handleLoguot={this.handleLogOut}
        />

        <div className="order-details-top"> </div>

        <section className="order-details">
          <div className="container">
            <div className="card br-corner-xs">
              <div className="order-head">
                {!loading && !isOrdered && (
                  <button
                    className="btn-icon fs-xl"
                    type="button"
                    onClick={() => this.props.history.goBack()}
                  >
                    <i className="icon-arrow-back"> </i>
                  </button>
                )}
                <h3 className="color-primary text-uppercase">Order Details</h3>
              </div>
              <div className="order-body">
                {document_data && !loading && !isOrdered && (
                  <div
                    className="card br-corner py-4 px-5 flex align-center"
                    key={document_data.id + Math.random()}
                  >
                    <i className="icon-doc color-primary fs-lg mr-5" />
                    <div className="content">
                      <h4 className="title">{document_data.name}</h4>
                      <p className="sub-content">
                        {document_data.url_imported_by ? (
                          <>
                            By &nbsp;
                            <a
                              style={{ color: "red" }}
                              href={`${document_data.url_imported_by}`}
                              target="_blank"
                            >
                              {document_data.imported_by}
                            </a>
                          </>
                        ) : (
                          <>By {document_data.imported_by}</>
                        )}{" "}
                        {document_data.url_imported_with ? (
                          <>
                            and{" "}
                            <a
                              href={`${document_data.url_imported_with}`}
                              target="_blank"
                            >
                              {" "}
                              {document_data.imported_with}
                            </a>
                          </>
                        ) : (
                          <>
                            {document_data.imported_with && (
                              <>and {document_data.imported_with}</>
                            )}
                          </>
                        )}
                        {"   "}
                        {document_data.url_organization_imported_with ? (
                          <>
                            of{" "}
                            <a
                              href={`${document_data.url_organization_imported_with}`}
                              target="_blank"
                            >
                              {document_data.organization_imported_with}
                            </a>
                          </>
                        ) : (
                          <>
                            {document_data.organization_imported_with && (
                              <>of {document_data.organization_imported_with}</>
                            )}
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                )}

                <ul className="info-list mb-7">
                  <li>
                    <span>Pricing plan type</span>
                    <p>{title}</p>
                  </li>
                  <li>
                    <span>Price</span>
                    <p>$ {plan.price}</p>
                  </li>
                  <li className="total">
                    <span>Total</span>
                    <p>$ {plan.price}</p>
                  </li>
                </ul>

                {!loading &&
                  !isOrdered &&
                  (card ? (
                    <>
                      <h4 className="mb-3 text-uppercase">Credit card</h4>
                      <CreditCard card={card} />
                      <div className="text-center mt-5">
                        <button
                          type="button"
                          className="btn filled primary text-uppercase h-lg br-round shadow w-lg"
                          onClick={(e) => {
                            e.preventDefault();
                            this.purchaseHandler(card.stripe_payment_method_id);
                          }}
                        >
                          Purchase
                        </button>
                      </div>
                    </>
                  ) : (
                    <AddCreditCard
                      onClose={this.onCloseAddCardModal}
                      addCard={this.addUserCard}
                      purchase={this.purchaseHandler}
                      isOrder
                      card={!!card}
                    />
                  ))}
              </div>

              {!card && !loading && !isOrdered && (
                <label className={`checkbox round ${card ? "mt-5" : ""}`}>
                  <input
                    onChange={this.rememberCheckHandler}
                    type="checkbox"
                    className="checkbox-control"
                    name="checkbox"
                    checked={checked}
                  />
                  <span className="check-icon">{""}</span>
                  <span className="checkbox-label"> Remember Credit Card</span>
                </label>
              )}
            </div>
          </div>
          <Modal open={orderInfoModal} onClose={this.onCloseOrderModal}>
            <div className="popup-content">
              <form action="">
                <p className={`${orderInfo.error ? "warning" : ""}`}>
                  {orderInfo.error || orderInfo.success}
                </p>
                {button && (
                  <div className="buttons mt-10 text-xs-center">
                    <button
                      type="button"
                      className="btn filled primary br-round h-lg w-lg shadow text-uppercase"
                      onClick={this.onCloseOrderModal}
                    >
                      {button}
                    </button>
                  </div>
                )}
              </form>
            </div>
          </Modal>
        </section>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = ({ payment }) => ({
  card: payment.card.card_exp_month ? payment.card : null,
  plan: payment.plan,
  subscribe: payment.subscribe,
});

const mapDispatchToProps = {
  getUserCard,
  getDocumentById,
  getPlan,
  setSubscription,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
