import React, {PureComponent} from "react";
import {connect} from "react-redux";
import Modal from "react-responsive-modal";
import {Link} from "react-router-dom";

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import {getUsers, deleteUsers} from "./../../store/actions/admin";
import {resourcePaginate} from "./../../helpers/resourcePaginate";
import CreateUser from "./createUser";
import Successfull from "./successfull";
import {ORG_TYPES} from './Organizations'

class Users extends PureComponent {
  state = {
    id: null,
    data: null,
    count: null,
    error: null,
    open: false,
    users: [],
    successfull: false,
    openDeleteModal: false,
    currentUser: null,
  };

  async componentDidMount() {
    await this.props.getUsers();
  }

  onOpenModal = () => {
    this.setState({open: true});
  };

  onOpenDelModal = id => {
    const {users} = this.props.users;
    const user = users.find(user => user.id === id);
    this.setState({
      openDeleteModal: true,
      id,
      user: {
        first_name: user.first_name,
        last_name: user.last_name,
      }
    });
  };

  onCloseModal = () => {
    this.setState({open: false, successfull: false});
  };

  onCloseDelModal = () => {
    this.setState({openDeleteModal: false});
  };
  onSuccess = data => {
    this.setState(prevState => ({ successfull: !prevState.successfull, data }));
  };

  handleDelete = async () => {
    const res = await this.props.deleteUsers(this.state.id);
    if (res && res.payload && res.payload.status === 204) {
      this.onCloseDelModal();
    } else {
      this.setState({error: "Something went wrong"});
    }
  };

  render() {
    const {
      open,
      successfull,
      data,
      openDeleteModal,
      error,
      user,
    } = this.state;

    const users = this.props.users.users;
    const count = this.props.users.count;
    const index = this.props.users.next && this.props.users.next.lastIndexOf("=");
    const page = this.props.users.next && this.props.users.next.substr(index + 1);

    return (
      /* users page start */
      <div>
        <main className="admin-content manage">
          <div className="container-sm">
            <div className="flex align-center justify-between mb-5">
              <h2 className="font-secondary fw-600">Users</h2>
            </div>
            {users && (
              <PerfectScrollbar
                style={{height: "660px"}}
                onYReachEnd={() => {
                  if (page){
                    resourcePaginate(
                      users,
                      count,
                      this.props.getUsers,
                      null,
                      page
                    );
                  }
                }}
              >
                {users.map((data, index) => {
                  return (
                    <div
                      className="card br-corner manage-file"
                      key={data.id + `${index}`}
                      id="scrollbar"
                    >
                      <div className="card-item-left word-break-all mr-1">
                        <Link
                          className="inline-block"
                          to={`/admin/users-view/${data.id}`}
                        >
                          <h4 className="title">{`${data.first_name} ${
                            data.last_name
                          }`}</h4>
                        </Link>
                        <p className="sub-content">{data.email}</p>
                      </div>
                      <div className="card-item-right word-break-all ml-1 text-xs-right">
                        <p>{data.organization_name}</p>
                        <p className="sub-content">
                          {ORG_TYPES[data.organization_type]}
                        </p>
                      </div>

                      <ul className="actions">
                        {!data.is_active && (
                          <li>
                            <button
                              className="btn-icon approve"
                              onClick={() => this.onOpenAccessModal(data.id)}
                            >
                              <i className="icon-check">{""}</i>
                            </button>
                          </li>
                        )}
                        <li>
                          <button
                            className="btn-icon delete"
                            onClick={() => this.onOpenDelModal(data.id)}
                          >
                            <i className="icon-delete">{""}</i>
                          </button>
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </PerfectScrollbar>
            )}
            {/*  Delete confirmation popup start */}
            <Modal open={openDeleteModal} onClose={this.onCloseDelModal} center>
              <div className="popup-content">
                <form action="">
                  <p className="fs-lg text-xs-left color-black-60 mt-5 ml-2">
                    {user && `Are you sure that you want to delete User ${user.first_name} ${user.last_name}?`}
                  </p>
                  <div className="buttons mt-10 text-xs-right">
                    <button
                      type="button"
                      className="btn filled primary br-round h-lg w-md fs-md shadow ml-3"
                      onClick={() => this.handleDelete()}
                    >
                      Yes
                    </button>
                    <button
                      className="btn link primary h-lg w-md fs-md"
                      onClick={this.onCloseDelModal}
                      type="button"
                    >
                      No
                    </button>
                  </div>
                  {error && <p>{error}</p>}
                </form>
              </div>
            </Modal>
            {/* Delete confirmation popup end */}
            {/* <Pagination /> */}
          </div>
        </main>
      </div>
      /* users page end */
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.admin
  };
}

export default connect(
  mapStateToProps,
  {
    getUsers,
    deleteUsers,
  }
)(Users);
