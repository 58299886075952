import {
  GET_EDITORS,
  GET_CONTRIBUTING_LAWYERS,
  GET_CONTRIBUTING_LAWYER,
  GET_USERS, GET_USER,
  CLEAR_CONTRIBUTING_LAWYERS, DELETE_CONTRIBUTING_LAWYER, DELETE_USERS, GET_ABOUT_SUCCEED, DELETE_ABOUT_SUCCEED,
} from './../actions/types'

const INITIAL_STATE = {
  editors: [],
  count: null,
  next: null,
  contributingLawyers: [],
  contributingLawyer: null,
  isActive: "False"
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_EDITORS:
      return {
        ...state,
        editors: action.payload.config.url.includes("page")
          ? [...state.editors, ...action.payload.data.results]
          : [...action.payload.data.results],
        count: action.payload.data.count,
        next: action.payload.data.next
      };
      case GET_USERS:
        return {
        ...state,
        users: action.payload.config.url.includes("page")
          ? [...state.users, ...action.payload.data.results]
          : [...action.payload.data.results],
        count: action.payload.data.count,
        next: action.payload.data.next
      };
      case DELETE_USERS:
        return {
          ...state,
          users: state.users.filter( lawyer => {
            return lawyer.id !== action.payload.id
          })
        };
    case GET_CONTRIBUTING_LAWYERS:
      return {
        ...state,
        contributingLawyers: action.payload.config.url.includes("page")
          ? [...state.contributingLawyers, ...action.payload.data.results]
          : [...action.payload.data.results],
        count: action.payload.data.count,
        next: action.payload.data.next,
        isActive: action.payload.config.url.includes("True") ? "True" : "False"
      };
    case DELETE_CONTRIBUTING_LAWYER:
      return {
        ...state,
        contributingLawyers: state.contributingLawyers.filter( lawyer => {
          return lawyer.id !== action.payload.id
        })
      };
    case CLEAR_CONTRIBUTING_LAWYERS:
      return {
        ...state,
        contributingLawyers: [],
      };
    case GET_CONTRIBUTING_LAWYER:
      return { ...state, contributingLawyer: action.payload.data };
    case GET_USER:
      return { ...state, user: action.payload.data };
    case GET_ABOUT_SUCCEED:
      return { ...state, about: action.payload };
    case DELETE_ABOUT_SUCCEED:
      return { ...state, about: action.payload };
    default:
      return state;
  }
}
