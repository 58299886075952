import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled(Link)`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 22px;
  @media (min-width: 768px) {
    font-size: 14px;
  }
  line-height: 19px;
  letter-spacing: 0.35px;

  &:hover {
    color: inherit;
    opacity: inherit;
  }
`;

const NavItem = ({ to, label }) => {
  return <Container to={to}>{label}</Container>;
};

export default NavItem;
