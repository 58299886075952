import {
  SIGN_IN_USER,
  REMOVE_TOKEN,
  SAVE_RESET_TOKEN,
  SIGN_UP_STEPPER_DATA,
  EDITOR_CONFIRM_DATA,
  PROFILE_INFO,
  USER_PROFILE_INFO,
  UPDATE_CONTRIBUTOR,
  CONTRIBUTOR_CONFIRM_DATA,
  AUTH_ERROR,
  LOGGED_IN,
  LOGGED_OUT,
} from "./../actions/types";

const userId = localStorage.getItem("id");

const INITIAL_STATE = {
  user: {},
  stepperData: {},
  editorConfirmData: {},
  contributorConfirmData: {},
  profile: {},
  outError: {},
  isLoggedIn: !!userId,
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIGN_IN_USER:
      return {
        ...state,
        user: { ...state.user, token: action.payload.data.token },
      };
    case LOGGED_IN:
      return { ...state, isLoggedIn: true };
    case LOGGED_OUT:
      return { ...state, isLoggedIn: false };
    case REMOVE_TOKEN:
      return { ...state, user: {} };
    case SAVE_RESET_TOKEN:
      return { ...state, user: { ...state.user, reset_token: action.payload } };
    case SIGN_UP_STEPPER_DATA:
      return { stepperData: action.payload };
    case EDITOR_CONFIRM_DATA:
      return { ...state, editorConfirmData: action.payload };
    case PROFILE_INFO:
      return { ...state, profile: action.payload };
    case USER_PROFILE_INFO:
      return { ...state, profile: action.payload };
    case UPDATE_CONTRIBUTOR:
      return { ...state, profile: action.payload };
    case CONTRIBUTOR_CONFIRM_DATA:
      return { ...state, contributorConfirmData: action.payload };
    case AUTH_ERROR:
      return { ...state, outError: action.payload };
    default:
      return state;
  }
}
