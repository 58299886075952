import { useSelector } from "react-redux";
import styled from "styled-components";
import { colors } from "../../../styles";
import NavItem from "./NavItem";

const Container = styled.div`
  margin: 24px 0;
  color: ${colors.gray};
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    margin: 0;
    flex-direction: row;
  }
`;

// const Divider = styled.div`
//   background-color: ${colors.gray};
//   width: 0px;
//   margin: 0 16px;
// `;

const NavItemGroup = ({ children }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return (
    <Container>
      <NavItem to="/pricing" label="Pricing" />
      <NavItem to="/faq" label="FAQ" />
      <NavItem to="/contact" label="Contact" />
      {isLoggedIn && <NavItem to="/user/profile" label="User profile" />}
    </Container>
  );
};

export default NavItemGroup;
