// import { useState } from "react";
// import { toast } from "react-toastify";
import styled from "styled-components";
import Footer from "./components/Homepage/footer";
import Header from "./components/Homepage/header";
// import MainButton from "./components/shared/MainButton";
// import Textarea from "./components/shared/Textarea";
import TitleBar from "./components/shared/TitleBar";
// import { publicApi } from "./http/axios";
import { colors, fontFamilies, mainContentWidth } from "./styles";

const contactEmailAddress = "jd@jdform.com";

const MainContent = styled.div`
  width: ${mainContentWidth};
  margin: auto;
  padding-top: 128px;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-bottom: 326px;
`;

const Info = styled.div``;

const InfoHeader = styled.div`
  color: ${colors.blue};
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 18px;
`;

const InfoText = styled.div`
  font-size: 15px;
  line-height: 28px;
  font-family: ${fontFamilies.serif};
`;

const ExternalLink = styled.a`
  color: ${colors.blue};
  font-family: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  font-size: inherit;
  font-weight: 700;
`;

// const WrappableRow = styled.div`
//   display: flex;
//   gap: 32px;
//   flex-wrap: wrap;
// `;

// const Form = styled.form`
//   display: flex;
//   flex-direction: column;
//   gap: 38px;
//   width: 740px;
// `;

const Inquiries = styled.div`
  margin-top: 40px;
`;

// const shortInputWidth = "354px";

const ContactPage = () => {
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState("");
  // const [subject, setSubject] = useState("");
  // const [message, setMessage] = useState("");
  // const [formStatus, setFormStatus] = useState("ready");

  // const onSubmitContactForm = (event) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   setFormStatus("loading");
  //   publicApi
  //     .post("/contact-form", {
  //       first_name: firstName,
  //       last_name: lastName,
  //       email,
  //       phone,
  //       subject,
  //       message,
  //     })
  //     .then(() => {
  //       toast.success("Message sent.");
  //       setFormStatus("ready");
  //     })
  //     .catch(() => {
  //       toast.error("Error encountered while attempting to send message.");
  //       setFormStatus("ready");
  //     });
  // };

  return (
    <div>
      <Header />

      <TitleBar
        topMargin="160px"
        title="Contact"
        backgroundImage="/jd_form_hero_mountain.png"
      />

      <MainContent>
        {/* <Form onSubmit={onSubmitContactForm}>
          <WrappableRow>
            <Textarea
              label="First name"
              required
              width={shortInputWidth}
              value={firstName}
              onChange={setFirstName}
              id="firstName"
            />
            <Textarea
              label="Last name"
              required
              width={shortInputWidth}
              value={lastName}
              onChange={setLastName}
              id="lastName"
            />
          </WrappableRow>

          <WrappableRow>
            <Textarea
              label="Email address"
              required
              width={shortInputWidth}
              value={email}
              onChange={setEmail}
              id="email"
            />
            <Textarea
              label="Phone number"
              required
              width={shortInputWidth}
              value={phone}
              onChange={setPhone}
              id="phone"
            />
          </WrappableRow>

          <WrappableRow>
            <Textarea
              label="Subject"
              value={subject}
              onChange={setSubject}
              id="subject"
            />
          </WrappableRow>

          <WrappableRow>
            <Textarea
              label="Message"
              required
              rows={3}
              value={message}
              onChange={setMessage}
              id="subject"
            />
          </WrappableRow>

          <div>
            <MainButton disabled={formStatus === "loading"}>
              {formStatus === "loading" ? "SENDING..." : "SUBMIT"}
            </MainButton>
          </div>
        </Form> */}

        <Info>
          <InfoHeader>Location</InfoHeader>
          <InfoText>
            175 Parfitt Way SW
            <br />
            Suite N250
            <br />
            Bainbridge Island, WA 98110
          </InfoText>
          <Inquiries>
            <InfoHeader>Inquiries</InfoHeader>
            <InfoText>
              Tel:{" "}
              <ExternalLink href="tel:+12067806771" target="_blank">
                (206) 780-6771
              </ExternalLink>
            </InfoText>
            <InfoText>
              Email:{" "}
              <ExternalLink
                href={`mailto:${contactEmailAddress}`}
                target="_blank"
              >
                {contactEmailAddress}
              </ExternalLink>
            </InfoText>
          </Inquiries>
        </Info>
      </MainContent>

      <Footer />
    </div>
  );
};

export default ContactPage;
