import { useState } from "react";
import styled from "styled-components";
import { colors } from "../../../styles";
import Resources from "../homePage/resources";

const Container = styled.div`
  margin-top: 40px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.blue};
  color: white;
  padding: 0 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.35px;
  cursor: pointer;
  user-select: none;
  height: 36px;
`;

const HeaderText = styled.span`
  margin-right: 8px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: ${colors.blueLight};
  padding: 16px 16px 24px 16px;
  @media (min-width: 640px) {
    flex-direction: row;
    gap: 32px;
  }
`;

const arrowRight = <span>&#9654;</span>;
const arrowDown = <span>&#9660;</span>;

const Filter = ({
  resources,
  lowAreas,
  getResourceTypes,
  getLowAreas,
  resourcesCount,
  lawCount,
  showresults,
  resourcesPage,
  lowPage,
  isLogedOut,
  handleQuery,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [resourcesQuery, setResourcesQuery] = useState(null);
  const [lowAreasQuery, setLowAreasQuery] = useState(null);

  const onClickHeader = () => {
    setIsOpen((currentIsOpen) => !currentIsOpen);
  };

  const checkboxHandler = (_type, _query) => {
    if (_type === "resources") {
      setResourcesQuery(_query);
    } else if (_type === "lowAreas") {
      setLowAreasQuery(_query);
    }
  };

  return (
    <Container>
      <Header onClick={onClickHeader}>
        <HeaderText>FILTER DOCUMENTS</HeaderText>
        {isOpen ? arrowDown : arrowRight}
      </Header>

      {isOpen && (
        <Main>
          {resources && (
            <Resources
              query={resourcesQuery}
              resources={resources}
              type="resources"
              method={getResourceTypes}
              resourcesCount={resourcesCount}
              showresults={showresults}
              dataLength={resourcesCount}
              page={resourcesPage}
              isLogedOut={isLogedOut}
              handleQuery={handleQuery}
              checkboxHandler={checkboxHandler}
            />
          )}
          {lowAreas && (
            <Resources
              query={lowAreasQuery}
              resources={lowAreas}
              type="lowAreas"
              method={getLowAreas}
              resourcesCount={lawCount}
              showresults={showresults}
              dataLength={lawCount}
              page={lowPage}
              isLogedOut={isLogedOut}
              handleQuery={handleQuery}
              checkboxHandler={checkboxHandler}
            />
          )}
        </Main>
      )}
    </Container>
  );
};

export default Filter;
