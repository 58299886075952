import axios from 'axios'

import { handleLoguot } from "../helpers/improve";
import { BASE_URL } from '../constants'

export const privateApi = axios.create({ baseURL: BASE_URL });

privateApi.interceptors.request.use(
  function (config) {
    const token = `${localStorage.getItem('auth') || sessionStorage.getItem('auth')}`;
    if (token) config.headers.Authorization = `JWT ${token}`;
    return config;
  },
  function (error) {
      return Promise.reject(error);
  }
);

privateApi.interceptors.response.use(
  (data) => {
      return data;
  },
  function (error) {
      if (401 === error.response.status) {
          handleLoguot();
          window.location = '/';
          return ;
      }
      return error.response
  }
);

export const publicApi = axios.create({ baseURL: BASE_URL });
