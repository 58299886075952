import React, { Component } from "react";
import Header from "./header";

class searchResults extends Component {
  render() {
    return (
      /* Search results page styles */
      <div>
        <Header showSearch={true} />
        <section className="main-content search-results">
          <div className="container">
            <div className="items flex justify-between">
              <div className="filter">
                <div className="card br-round">
                  <h5 className="color-primary fw-700 font-secondary text-uppercase text-xs-center mb-3">
                    Document type
                  </h5>
                  <ul className="filter-list">
                    <li>
                      <label className="checkbox">
                        <span className="checkbox-label">
                          Standard Contract
                        </span>
                        <input
                          type="checkbox"
                          className="checkbox-control"
                          name="searchable"
                          checked
                        />
                        <span className="check-icon">{""}</span>
                      </label>
                    </li>
                    <li>
                      <label className="checkbox">
                        <span className="checkbox-label">Standard Clauses</span>
                        <input
                          type="checkbox"
                          className="checkbox-control"
                          name="searchable"
                        />
                        <span className="check-icon">{""}</span>
                      </label>
                    </li>
                  </ul>
                  <h5 className="color-primary fw-700 font-secondary text-uppercase text-xs-center mb-3">
                    Legal area
                  </h5>
                  <ul className="filter-list">
                    <li>
                      <label className="checkbox">
                        <span className="checkbox-label">
                          Commercial Transactions
                        </span>
                        <input
                          type="checkbox"
                          className="checkbox-control"
                          name="searchable"
                        />
                        <span className="check-icon">{""}</span>
                      </label>
                    </li>
                    <li>
                      <label className="checkbox">
                        <span className="checkbox-label">
                          Corporate and M & A
                        </span>
                        <input
                          type="checkbox"
                          className="checkbox-control"
                          name="searchable"
                          checked
                        />
                        <span className="check-icon">{""}</span>
                      </label>
                    </li>
                    <li>
                      <label className="checkbox">
                        <span className="checkbox-label">Employement</span>
                        <input
                          type="checkbox"
                          className="checkbox-control"
                          name="searchable"
                        />
                        <span className="check-icon">{""}</span>
                      </label>
                    </li>
                    <li>
                      <label className="checkbox">
                        <span className="checkbox-label">
                          Entertainment & Media
                        </span>
                        <input
                          type="checkbox"
                          className="checkbox-control"
                          name="searchable"
                          checked
                        />
                        <span className="check-icon">{""}</span>
                      </label>
                    </li>
                    <li>
                      <label className="checkbox">
                        <span className="checkbox-label">
                          Intellectual Property & Technology
                        </span>
                        <input
                          type="checkbox"
                          className="checkbox-control"
                          name="searchable"
                          checked
                        />
                        <span className="check-icon">{""}</span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      /* Search results page styles */
    );
  }
}

export default searchResults;
