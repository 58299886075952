import React, { Component } from 'react'
import { withFormik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import {
  createEditor,
  createLawyer,
} from './../../store/actions/admin'

class CreateUser extends Component {
  render () {
    const { values, errors, touched, user } = this.props;
    return (
      <div className="popup-content">
        <h3 className="popup-title">{` ADD NEW ${ user === 'editor'? 'EDITOR': 'CONTRIBUTING LAWYER'}`}</h3>
        <Form>
          <div
            className={`text-field br-corner ${touched.first_name &&
            errors.first_name &&
            'invalid'}`}
          >
            <Field
              type="text"
              placeholder="First name*"
              name="first_name"
              value={values.first_name}
            />
            {touched.first_name && errors.first_name && (
              <p className="error-msg">{errors.first_name}</p>
            )}
          </div>
          <div
            className={`text-field br-corner  ${touched.last_name &&
            errors.last_name &&
            'invalid'}`}
          >
            <Field
              type="text"
              placeholder="Last name*"
              name="last_name"
              value={values.last_name}
            />
            {touched.last_name && errors.last_name && (
              <p className="error-msg">{errors.last_name}</p>
            )}
          </div>
          <div
            className={`text-field br-corner ${touched.email &&
            errors.email &&
            'invalid'}`}
          >
            <Field
              type="text"
              placeholder="Email address*"
              value={values.email}
              name="email"
            />
            {touched.email && errors.email && (
              <p className="error-msg">{errors.email}</p>
            )}
          </div>
          <button className="btn filled primary br-round h-lg shadow w-lg mt-7">
            SEND INVITATION
          </button>
        </Form>
      </div>
    )
  }
}

const CreateEditorFormik = withFormik({
  mapPropsToValues ({ first_name, last_name, email }) {
    return {
      first_name: first_name || '',
      last_name: last_name || '',
      email: email || ''
    }
  },
  validationSchema: Yup.object().shape({
    first_name: Yup.string()
    .required('This field is mandatory.')
    .max(200, 'Maximum number of symbols is 200.'),
    last_name: Yup.string()
    .required('This field is mandatory.')
    .max(200, 'Maximum number of symbols is 200.'),
    email: Yup.string()
    .email('Please provide a valid email.')
    .max(200, 'Maximum number of symbols is 200.')
    .required('This field is mandatory.')
  }),

  async handleSubmit (values, { props, setErrors, setValues }) {
    const { user } = props
    const handleErrors = {
      email: 'The provided email already exists.'
    }
    const data = {
      email: values.email.trim(),
      first_name: values.first_name,
      last_name: values.last_name
    }
    const res = user === 'editor' ? await props.createEditor(data) : await props.createLawyer(data);

      if (res && res.payload.status === 201) {
      props.onSuccess(data)
    }
    if (res && res.payload.status === 400) {
      const errors = {};
      if (res.payload.data.email && res.payload.data.email.length && res.payload.data.email[0] === "Enter a valid email address."
      ){
        errors.email = 'Please provide a valid email.';
      } else {
        Object.keys(res.payload.data).map( data => {
          errors[data] = handleErrors[data];
        });
      }
      setErrors({ ...errors });
    }
  }
})(CreateUser)

export default connect(
  null,
  {
      createEditor,
      createLawyer,
  }
)(CreateEditorFormik)
