import React, { Component, Fragment } from "react";
import "react-custom-scroll/dist/customScroll.css";
import InfiniteScroll from "react-infinite-scroll-component";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { resourcePaginate } from "./../../../helpers/resourcePaginate";
import resources from "../../admin/resources";
import styled from "styled-components";
import { colors } from "../../../styles";

const Container = styled.div`
  flex: 1 1 auto;
`;

const H5 = styled.h5`
  text-align: left;
  color: ${colors.blue};
  line-height: 19px;
  letter-spacing: 0.35px;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

const CheckItemLabel = styled.label`
  display: flex;
`;

const CheckItemText = styled.span`
  font-weight: 700;
`;

export default class LowArea extends Component {
  state = {
    resources: {},
    checked: false,
  };

  settings = {
    title: {
      resources: "document type",
      lowAreas: "legal area",
    },
    query: {
      resources: "resource_type=",
      lowAreas: "law_area=",
    },
  };

  componentDidMount() {
    const { query } = this.props;
    const checkboxes = document.querySelectorAll("#filter input");
    query &&
      checkboxes.forEach((checkbox) => {
        if (query[checkbox.id]) {
          checkbox.checked = true;
        }
      });
    this.setState({ resources: query });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isLogedOut !== this.props.isLogedOut) {
      this.setState({ resources: {} });
    }
  }

  componentWillUnmount() {
    const { type } = this.props;
    const { resources } = this.state;
    this.props.checkboxHandler(type, resources);
  }

  handleChange = async (e) => {
    await this.setState({
      ...this.state,
      resources: {
        ...this.state.resources,
        [e.target.id]: e.target.checked ? e.target.value : null,
      },
    });

    const query = this.paramsQuery(this.state.resources);
    this.props.handleQuery(query, this.props.type);
  };

  paramsQuery = (params) => {
    const query = [];

    for (let elem in params) {
      if (params[elem]) {
        query.push(`${this.settings.query[this.props.type]}${params[elem]}`);
      }
    }
    return query;
  };

  renderResources = (resources, showresults, resourcesCount, method, page) => {
    return (
      /*!showresults ?*/ <Container>
        <H5 className="color-primary fw-700 font-secondary text-uppercase text-xs-center mb-3">
          {this.settings.title[this.props.type]}
        </H5>
        <div className="mb-3">
          {/* <PerfectScrollbar
            style={{ height: "180px" }}
            onYReachEnd={() => {
              resourcePaginate(resources, resourcesCount, method, null, page);
            }}
          > */}
          <ul className="filter-list">
            {resources.map((data) => {
              return (
                <li key={data.id}>
                  <CheckItemLabel>
                    <InputWrapper>
                      <input
                        type="checkbox"
                        onChange={(e) => this.handleChange(e)}
                        id={data.name}
                        value={data.id}
                      />
                    </InputWrapper>
                    <span className="check-icon" />
                    <CheckItemText>{data.name}</CheckItemText>
                  </CheckItemLabel>
                </li>
              );
            })}
          </ul>
          {/* </PerfectScrollbar> */}
        </div>
      </Container>
    );
    // ) : (
    //   /* Show the following while on search mode */
    //   <Fragment>
    //     <h5 className="color-primary fw-700 font-secondary text-uppercase mb-3">
    //       {this.settings.title[this.props.type]}
    //     </h5>
    //
    //     <div className="list">
    //       <InfiniteScroll>
    //         <ul className="filter-list">
    //           {resources.map(data => {
    //             return (
    //               <li key={data.id}>
    //                 <label className="checkbox round mb-1">
    //                   <input
    //                     type="checkbox"
    //                     className="checkbox-control"
    //                     onChange={e => this.handleChange(e)}
    //                     id={data.name}
    //                     value={data.id}
    //                   />
    //                   <span className="check-icon">{""}</span>
    //                   <span className="checkbox-label">{data.name}</span>
    //                 </label>
    //               </li>
    //             );
    //           })}
    //         </ul>
    //       </InfiniteScroll>
    //     </div>
    //   </Fragment>
    // );
  };

  render() {
    const { resources, showresults, resourcesCount, method, page } = this.props;
    return (
      resources &&
      this.renderResources(resources, showresults, resourcesCount, method, page)
    );
  }
}
