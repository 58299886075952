import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  getDocuments,
  clearDocuments,
  getResourceTypes,
  getLowAreas,
} from '../../store/actions/documents'

import Select, { components } from 'react-select'
import { resourcePaginate } from '../../helpers/resourcePaginate'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import Option from '../admin/option'
import {roleChecker} from "../../helpers/improve";

class Documents extends Component {
  state = {
    open: false,
    openClass: false,
    resources: [],
    myResources: [],
    queries: {
      resources: [],
      lowAreas: [],
    },
    query: [],
    pending: null,
    searchValue: this.props.searchValue || '',
  };

  settings = {
    title: {
      resources: 'document type',
      lowAreas: 'legal area',
    },
    query: {
      resources: 'resource_type=',
      lowAreas: 'law_area=',
    },
    isAdmin: '',
  };

  async componentDidMount () {
    const pending = (this.props.location.pathname.split('/').pop() === 'pending-documents')
    const { searchValue } = this.props
    const searchTerm = [`search_term=${searchValue}`]
    const documentSearchTrigger = document.querySelector('#searchDocument');
    const role = roleChecker();
    await this.props.getDocuments('admin', searchValue ? searchTerm : null, null, null, !pending, role)
    await this.props.getResourceTypes()
    await this.props.getLowAreas()
    this.setState({ pending });
    documentSearchTrigger && documentSearchTrigger.addEventListener('search', () => {
      const query = this.paramsQuery(this.state.myResources);
      this.handleQuery(query);
    });
    this.setState({ isAdmin: role === 'admin' });
  }

  onOpenModal = () => {
    this.setState({ open: true })
  }

  onCloseModal = () => {
    this.setState({ open: false })
  }

  handleQuery = async (query, type) => {
    const { pending, isAdmin } = this.state;

    if (this.props.searchValue) {
      query.push(`search_term=${this.props.searchValue}`)
    }
    await this.props.getDocuments('admin', query, null, null, !pending, isAdmin? 'admin': 'lawyer');
  };

  paramsQuery = (params, type) => {
    const query = []
    for (let elem in params) {
      let type = elem.split('-').shift()
      if (params[elem]) {
        query.push(`${this.settings.query[type]}${params[elem]}`)
      }
    }
    return query
  }

  onHandleChange = async (value, type) => {
    if (type.action === 'select-option') {
      await this.setState({
        myResources: {
          ...this.state.myResources,
          [type.name + '-' + type.option.label]: type.option.value
        }
      })

    } else if (type.action === 'deselect-option') {
      const { myResources } = this.state
      if (type.option) {
        delete myResources[type.name + '-' + type.option.label]
      }
      await this.setState({ myResources })
    } else {
      let myNewResources = this.state.myResources
      for (let item in myNewResources) {
        let myType = item.split('-')[0]
        if (type.name === myType) {
          delete myNewResources[item]
        }
      }
    }

    const query = this.paramsQuery(
      this.state.myResources,
      type.name
    )

    this.handleQuery(query, type.name)
    return false
  }

  documentsPaginate = async (resourcesCount, resources) => {
    const { pending, isAdmin } = this.state;
    const { searchValue } = this.props;
    const searchTerm = [`search_term=${searchValue}`];
    const documents = this.props.documents;
    const newQuery = [
      ...this.state.query,
      searchValue ? searchTerm : '',
    ];

    const documentsIndex = documents && documents.next && documents.next.indexOf('page=');
    const resourcePage = documentsIndex && documents.next.substr(documentsIndex + 5, 1);

    if (this.lastPage !== resourcePage && resources.length < resourcesCount) {
      this.lastPage = resourcePage;
      await this.props.getDocuments('admin', newQuery, resourcePage, null, !pending, isAdmin? 'admin': 'lawyer');
    }

  }

  onClickDocument = (e, docId, creator_role, creator_user_id) => {
    if (e.target.tagName === 'A') {
      return e.target.click();
    }

    const role = +(localStorage.role ? localStorage.role : sessionStorage.role)

    //Admin
    if (role === 2) {
      return this.props.history.push(`edit-document/${docId}`)
    }
    const { id } = this.props.user
    //Lawyer
    if (role === 4) {
      if (creator_user_id === id) {
        this.props.history.push(`edit-document/${docId}`)
      } else {
        this.props.history.push(`view-document/${docId}`)
      }
    }

    // Editor
    if (role === 3) {
      if (creator_role === 3 || creator_role === 4) {
        this.props.history.push(`edit-document/${docId}`)
      } else {
        this.props.history.push(`view-document/${docId}`)
      }
    }

  }

  valueContainer = ({ children, getValue, selectProps, ...props }) => {
    const { length } = getValue()
    let value = `${length} items selected`
    let initial = selectProps.name === 'resources' ? 'Document Type' : 'Legal Area'
    let title = length ? value : initial
    const options = React.Children.map(children, child => child)

    return (
      <components.ValueContainer {...props}>
        {title}
        {options}
      </components.ValueContainer>
    )
  }

  render () {
    const query = {
      resources: this.props.resources.allResources,
      lowAreas: this.props.lowAreas.allLowAreas,
      resourcesCount: this.props.resources.count,
      lowCount: this.props.lowAreas.count,
      lowNext: this.props.lowAreas.next,
      resourcesNext: this.props.resources.next
    }

    const lowAreas =
      query.lowAreas.length > 0 &&
      query.lowAreas.map(data => {
        return { value: data.id, label: data.name }
      })
    const resourceTypes =
      query.resources.length > 0 &&
      query.resources.map(data => {
        return { value: data.id, label: data.name }
      })

    const lowIndex = query.lowNext && query.lowNext.indexOf('=')
    const lowPage = lowIndex && query.lowNext.substr(lowIndex + 1)
    const resourcesIndex = query.resourcesNext && query.resourcesNext.indexOf('=')
    const resourcesPage = resourcesIndex && query.resourcesNext.substr(resourcesIndex + 1)
    const documents = [...this.props.documents.allDocuments]
    const documentsCount = this.props.documents.count
    const pending = (this.props.location.pathname.split('/').pop() === 'pending-documents')
    const { resourcesCount, lowCount } = query;
    const queryLowAreas = this.state.queries.lowAreas
    const queryResources = this.state.queries.resources
    const ValueContainer = this.valueContainer;
    const { isAdmin } = this.state;

    return (
      <div>
        <main className="admin-content manage mt-0">
          <div className="pt-7 bg-blue-light-60 mb-5">
            <div className="container-sm">
              <div className="mb-5">
                <h2 className="font-secondary fw-600">{pending ? 'PENDING ' : ''}DOCUMENTS</h2>
              </div>
              <div className="filters flex">
                <div className={`select-box br-corner check-items w-50 mr-3`}>
                  {/* Please remove class "hide" when there are checked items */}
                  <p className="value fs-md color-black hide">
                    Document Type <span className="circle">{''}</span>
                  </p>

                  {resourceTypes && lowAreas && (
                    <Select
                      components={{ Option, ValueContainer }}
                      isMulti={true}
                      placeholder={''}
                      name="resources"
                      method={this.props.getResourceTypes}
                      onChange={this.onHandleChange}
                      resourcesCount={resourcesCount}
                      resources={query.resources}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      options={resourceTypes}
                      onMenuScrollToBottom={e =>
                        resourcePaginate(
                          query.resources,
                          resourcesCount,
                          this.props.getResourceTypes,
                          null,
                          +resourcesPage
                        )
                      }
                      className="react-select-container"
                      classNamePrefix="react-select"
                    />
                  )}
                </div>
                <div className="select-box br-corner w-50 ml-3">
                  {/* Please remove class "hide" when there are checked items */}

                  <p className="value fs-md color-black hide">
                    Legal Area <span className="circle">{''}</span>
                  </p>
                  {lowAreas && resourceTypes && (
                    <Select
                      components={{ Option, ValueContainer }}
                      isMulti={true}
                      name="lowAreas"
                      placeholder=""
                      method={this.props.getLowAreas}
                      resources={query.lowAreas}
                      resourcesCount={lowCount}
                      onChange={this.onHandleChange}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      options={lowAreas}
                      onMenuScrollToBottom={e =>
                        resourcePaginate(
                          query.lowAreas,
                          lowCount,
                          this.props.getLowAreas,
                          null,
                          +lowPage
                        )
                      }
                      className="react-select-container"
                      classNamePrefix="react-select"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="container-sm ">
            <PerfectScrollbar
              style={{ height: '525px' }}
              onYReachEnd={() => this.documentsPaginate(documentsCount, documents)}
            >
              {
                documents.length ?
                  documents.map(data => {
                    return (
                      <div
                        className="card br-corner manage-file pointer hover-effect"
                        key={data.id + Math.random()}
                        onClick={(e) => this.onClickDocument(e, data.id, data.creator_role, data.creator_user_id)}

                      >
                        <i className="icon-doc color-primary fs-lg mr-5"/>
                        <div className="content">
                          <h4 className="title">{data.name}</h4>
                          <p className="sub-content">
                            {
                              data.url_imported_by ? (
                                <>
                                  By <a
                                  href={`${data.url_imported_by}`}
                                  target='_blank'
                                >{data.imported_by}</a>
                                </>
                              ) : (
                                <>
                                  By {data.imported_by}
                                </>
                              )
                            }
                            {
                              ' '
                            }
                            {
                              data.url_imported_with ? (
                                <>
                                  and <a
                                  href={`${data.url_imported_with}`}
                                  target='_blank'
                                > {data.imported_with}</a>
                                </>
                              ) : (
                                <>
                                  {
                                    data.imported_with && (<>
                                      and {data.imported_with}
                                    </>)
                                  }
                                </>
                              )
                            }
                            {
                              '   '
                            }
                            {
                              data.url_organization_imported_with ? (
                                <>
                                  of <a
                                  href={`${data.url_organization_imported_with}`}
                                  target='_blank'
                                >{data.organization_imported_with}</a>
                                </>
                              ) : (
                                <>
                                  {
                                    data.organization_imported_with &&
                                    <>
                                      of {data.organization_imported_with}
                                    </>
                                  }
                                </>
                              )
                            }
                          </p>
                        </div>

                        { data.is_sample && isAdmin &&
                          <p className="public-document">
                            <i className="icon-eye"/>
                          </p>
                        }

                        <i className="icon-arrow-right color-primary"> </i>
                      </div>
                    )
                  }) : (
                    <p className="placeholder-text">There are no results to display</p>
                  )}
            </PerfectScrollbar>
          </div>
        </main>
      </div>
      /* Editors page end */
    )
  }
}

function mapStateToProps (state) {
  return {
    user: state.auth && state.auth && state.auth.profile && state.auth.profile.data,
    documents: state.documents.documents,
    resources: state.documents.resources,
    lowAreas: state.documents.lowAreas
  }
}

export default connect(
  mapStateToProps,
  {
    getDocuments,
    getResourceTypes,
    getLowAreas
  }
)(Documents)
