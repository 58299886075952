import React from "react";
export const modalBody = (Component, onClose, isLawyerSignUp, lawyerToken, handleClick, onOpenModal, lawyerConfirm) => {
  return <Component
    onClose={onClose}
    lawyerConfirm={lawyerConfirm}
    isLawyerSignUp={isLawyerSignUp}
    lawyerToken={lawyerToken}
    handleClick={handleClick}
    onOpenModal={onOpenModal}
  />;
};
