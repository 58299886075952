import styled from "styled-components";
import { colors } from "../../styles";

const MainButton = styled.button`
  color: ${(p) => p.color || colors.blue};
  border: 1px solid ${(p) => p.color || colors.blue};
  padding: 8px 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.35px;
  background-color: white;
  cursor: pointer;
`;

export default MainButton;
