import {
  GET_PLAN_BENEFITS,
  GET_PLANS,
  GET_PLAN,
  GET_USER_CARD,
  ADD_USER_CARD,
  DELETE_USER_CARD,
  SUBSCRIBE, SUBSCRIBE_FAILED,
  GET_SUBSCRIPTION,
  REMOVE_SUBSCRIPTION, SUBSCRIBE_FAILURE, SUBSCRIBE_SUCCEED,
} from '../actions/types'

const INITIAL_STATE = {
  card: {},
  plans: [],
  plan: [],
  benefits: {},
  subscription: {},
};



export default function payment(state = INITIAL_STATE, { type, payload }) {

  switch (type) {
    case GET_USER_CARD:
      return {
        ...state,
        card: payload.data,
      };
    case ADD_USER_CARD:
      return {
        ...state,
        card: payload.data,
      };
    case DELETE_USER_CARD:
      return {
        ...state,
        card: {},
      };
    case GET_PLANS:
      return {
        ...state,
        plans: payload.data,
      };
    case GET_PLAN:
      return {
        ...state,
        plan: payload.data,
      };
    case GET_PLAN_BENEFITS:
      return {
        ...state,
        benefits: payload.data,
      };
    case SUBSCRIBE_SUCCEED:
      return {
        ...state,
        subscribe: payload,
      };
    case SUBSCRIBE_FAILURE:
      return {
        ...state,
        subscribe: payload,
      };
    case GET_SUBSCRIPTION:
      return {
        ...state,
        subscription: payload.data,
      };
    case REMOVE_SUBSCRIPTION:
      return {
        ...state,
        subscription: {},
      };
    default:
      return state;
  }
}
