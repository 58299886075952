import styled from "styled-components";
import Header from "../components/Homepage/header";
import TitleBar from "../components/shared/TitleBar";
import { colors, mainContentWidth } from "../styles";
import FeatureList from "./FeatureList";
import jdArrow from "../assets/img/jdArrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getDocumentById } from "../store/actions/documents";
import Document from "../components/common/document";
import Footer from "../components/Homepage/footer";
import { getPlans, getSubscription } from "../store/actions/payment";
import { roleChecker } from "../helpers/improve";
import SignIn from "../components/Homepage/login/login";

const PurchaseInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  margin-top: 24px;
  gap: 24px;
`;
const LeftColumn = styled.div`
  min-width: 336px;
  max-width: 584px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
const PurchaseBox = styled.div`
  box-shadow: 0px 3px 6px #00000029;
  width: 100%;
`;
const PurchaseTitleBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.blueDark};
  color: white;
  font-size: 30px;
  letter-spacing: -0.3px;
  font-weight: 700;
  height: 72px;
`;
const RightColumn = styled.div`
  width: 344px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Divider = styled.div`
  background-color: ${colors.gray};
  height: 1px;
  margin: 56px 0;
`;
const AnnualPlan = styled(Link)`
  margin-top: 32px;
`;
const AnnualPlanText = styled.div`
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 35px;
  width: 280px;
  margin: auto;
  color: ${colors.blue};
  letter-spacing: -0.18px;
`;
const DocumentTitle = styled.div`
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  color: ${colors.blueDark};
  margin-top: 80px;
`;
const JdArrow = styled.img`
  padding-left: 16px;
  margin-bottom: -4px;
`;
const MainContent = styled.div`
  width: ${mainContentWidth};
  margin: auto;
`;

const annualPlanText =
  "Need more than one document? Learn about our cost-effective Annual Subscription plan";

const price = "$49.00";

const DocumentPage = () => {
  const { id: docId } = useParams();
  const dispatch = useDispatch();
  const document = useSelector((state) => state.documents.documentById);
  const plans = useSelector((state) => state.payment.plans);
  const plan = plans.find((p) => p.title.startsWith("SINGLE"));
  const role = roleChecker();
  const documentData = document.data || {};
  const history = useHistory();

  // on mount
  useEffect(() => {
    dispatch(getDocumentById(docId));
    dispatch(getPlans());
    if (role) {
      dispatch(getSubscription());
    }
  }, [dispatch, docId, role]);

  const purchaseHandler = async (planData) => {
    const document_id = docId;

    let modalName = "modalSignIn";
    if (role === "user") {
      if (document_id) {
        return history.push("/user/order", {
          oneTimePayment: true,
          plan_id: planData.id,
          document_id,
        });
      } else {
        modalName = "modalNotify";
      }
    } else {
      this.setState({ component: SignIn });
    }
    this.setState({ [modalName]: true });
  };

  return (
    <div>
      <Header />
      <TitleBar title="" backgroundColor={colors.blueLight} topMargin="64px" />
      <MainContent>
        <DocumentTitle>{documentData.name || ""}</DocumentTitle>
        <PurchaseInfo>
          <LeftColumn>
            <PurchaseBox>
              <PurchaseTitleBar>
                <div>{documentData.is_sample ? "FREE" : price}</div>
              </PurchaseTitleBar>
              <FeatureList
                purchaseHandler={() => purchaseHandler(plan)}
                is_sample={documentData.is_sample}
              />
            </PurchaseBox>
          </LeftColumn>
          <RightColumn>
            <AnnualPlan to="/pricing">
              <AnnualPlanText>
                {annualPlanText}
                <JdArrow src={jdArrow} alt="" />
              </AnnualPlanText>
            </AnnualPlan>
          </RightColumn>
        </PurchaseInfo>
        <Divider />
        <Document />
      </MainContent>
      <Footer />
    </div>
  );
};

export default DocumentPage;
