import React, { Component, Fragment } from "react";
import done from "./../../assets/img/done.png";
import Login from "./login/login";

class SuccessRegister extends Component {
  state = {
    component: "registerPopUp"
  };

  handleComponent = component => {
    this.setState({ component });
  };

  render() {
    const { component } = this.state;
    return (
      /* Success register popup start */
      <Fragment>
        {component === "registerPopUp" && (
          <div className="popup-content">
            <h3 className="popup-title">THANK YOU</h3>
            <form action="" className="flex column align-center">
              <div className="popup-img">
                <img src={done} alt="Done_image" />
              </div>
              <div className="message pt-10">
                <p>
                  Thank you for signing up for Form. <br />
                  You may now log in.
                </p>
              </div>
              <button
                className="btn filled primary br-round shadow h-lg w-lg mb-4"
                onClick={() => this.handleComponent("login")}
              >
                LOG IN
              </button>
            </form>
          </div>
        )}
        {component === "login" && <Login {...this.props} />}
      </Fragment>
      /* Success register popup end */
    );
  }
}

export default SuccessRegister;
