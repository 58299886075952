import { combineReducers } from "redux";
import documentsReducer from "./documents";
import authReducer from "./auth";
import adminReducer from "./admin";
import paymentReducer from "./payment";
const rootReducer = combineReducers({
  documents: documentsReducer,
  auth: authReducer,
  admin: adminReducer,
  payment: paymentReducer,
});

export default rootReducer;
