import React, { Component } from "react";
import Resources from "./resources";
import Modal from "react-responsive-modal";
import { DeleteModal } from '../../components/common/modal'

class TemplateTypes extends Component {
  render() {
    const {
      open,
      openDel,
      resourceName,
      error,
      deleteId
    } = this.props.stateValue;


    const {
      type,
      modalText,
      placeholder,
      text,
      query: { resources },
      resourcesCount,
      page
    } = this.props;

    return (

      <div>
        <main className="admin-content manage">
          <div className="container-sm">
            <div className="flex align-center justify-between mb-5">
              <h2 className="font-secondary fw-600">{text}</h2>
              <button
                className="btn filled primary h-lg br-round shadow w-md flex justify-center align-center"
                onClick={this.props.onOpenModal}
              >
                <i className="icon-plus fs-xl mr-1"> </i> ADD NEW
              </button>
              {/*  Add new resource type popup start */}
              <Modal open={open} onClose={this.props.onCloseModal}>
                <div className="popup-content">
                  <h3 className="popup-title">{modalText}</h3>
                  <form action="">
                    <div className="text-field br-corner">
                      <input
                        type="text"
                        value={resourceName}
                        onChange={this.props.handleChange}
                        placeholder={placeholder}
                        autofocus="true"
                      />
                      {error && <p className="error-msg">{error}</p>}
                    </div>
                    <button
                      className="btn filled primary br-round h-lg shadow w-lg mt-7"
                      onClick={() => this.props.addResource(type)}
                      type="button"
                    >
                      SAVE
                    </button>
                  </form>
                </div>
              </Modal>
              {/* Add new resource type popup end */}
            </div>

            {resources && (
              <Resources
                resources={resources}
                getDocuments={this.props.getDocuments}
                type={type}
                page={page}
                method={this.props.method}
                resourcesCount={resourcesCount}
                onOpenModalDel={this.props.onOpenModalDel}
                onCloseModal={this.props.onCloseModal}
                dataLength={resources}
                handleQuery={this.handleQuery}
              />
            )}

            {/*  Delete confirmation popup start */}
            <Modal
                open={openDel}
                onClose={this.props.onCloseModalDel}
                center
                type={type}
            >
              <div className="popup-content">
                <p className="fs-lg text-xs-left color-black-60 mt-5 ml-2">
                  If you delete this item, document(s) related to it will be deleted too.
                </p>

                <div className="buttons mt-10 text-xs-right">
                  {error && <p>{error}</p>}
                  <button
                    className="btn filled primary br-round h-lg w-md fs-md shadow ml-3"
                    onClick={() => {
                      this.props.deleteResource(deleteId);
                    }}
                  >
                    Delete
                  </button>
                  <button
                    className="btn link primary h-lg w-md fs-md"
                    onClick={this.props.onCloseModalDel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>
            {/* Delete confirmation popup end */}
          </div>
        </main>
      </div>
      /* Resource types page end */
    );
  }
}

export default TemplateTypes;
