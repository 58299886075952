import { useState } from "react";
import styled from "styled-components";
import Documents from "../../Homepage/documents";

const Container = styled.div``;

const LoadingBar = styled.div`
  width: 264px;
  height: 3px;
  position: relative;
  overflow: hidden;
  margin: 16px auto;
  background-color: #ddd;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;

  &:before {
    content: "";
    position: absolute;
    left: -50%;
    height: 3px;
    width: 40%;
    background-color: coral;
    -webkit-animation: loadAnim 1s linear infinite;
    -moz-animation: loadAnim 1s linear infinite;
    animation: loadAnim 1s linear infinite;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
  }
`;

// The number of docs to show at first and how many more to show
// each time the "load more" button is clicked
const pageSize = 50;

const DocumentList = ({
  documents,
  getDocumentId,
  searchResults,
  token,
  handleUnAutorizeClick,
  modalDocuments,
  component,
  modalBody,
  onCloseModal,
  onHandleChange,
  showresults,
  searchValue,
  is_loading,
}) => {
  const [numDocsToShow, setNumDocsToShow] = useState(pageSize);
  const docsToShow = documents.slice(0, numDocsToShow);
  const hasMore = docsToShow.length < documents.length;
  const onClickLoadMore = () => {
    setNumDocsToShow((oldNumDocsToShow) => oldNumDocsToShow + pageSize);
  };
  return (
    <Container>
      {documents.length ? (
        <Documents
          getDocument={getDocumentId}
          documents={docsToShow}
          searchResults={searchResults}
          dataLength={docsToShow}
          token={token}
          onOpenModal={handleUnAutorizeClick}
          open={modalDocuments}
          component={component}
          modalBody={modalBody}
          onCloseModal={() => onCloseModal("modalDocuments")}
          onHandleChange={onHandleChange}
          showresults={showresults}
          searchValue={searchValue}
          onClickLoadMore={onClickLoadMore}
          hasMore={hasMore}
        />
      ) : (
        <>
        <p className="placeholder-text">
          <div>
          {is_loading
            ? "Loading list of documents..."
            : "There are no results to display"}
        </div>
        {is_loading && <LoadingBar />}
        </p>
        </>
      )}
    </Container>
  );
};

export default DocumentList;
