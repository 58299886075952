export const ORG_TYPES = [
  { value: 1, label: "Sole Practitioner" },
  { value: 2, label: "Small / Boutique Firm ( 2-10 attorneys)" },
  { value: 3, label: "Mid-sized Firm  (11-29 attorneys)" },
  { value: 4, label: "Large full-scale Firm (30 or more attorneys)" },
  { value: 5, label: "Financial institution" },
  { value: 6, label: "Government" },
  { value: 7, label: "Law School" },
  { value: 8, label: "Other corporation or Business" }
];

export const JURISDICTIONS = [
  { value: 1, label: "Alabama" },
  { value: 2, label: "Alaska" },
  { value: 3, label: "Arizona" },
  { value: 4, label: "Arkansas" },
  { value: 5, label: "California" },
  { value: 6, label: "Colorado" },
  { value: 7, label: "Connecticut" },
  { value: 8, label: "Delaware" },
  { value: 9, label: "District of Columbia" },
  { value: 10, label: "Florida" },
  { value: 11, label: "Georgia" },
  { value: 12, label: "Hawaii" },
  { value: 13, label: "Idaho" },
  { value: 14, label: "Illinois" },
  { value: 15, label: "Indiana" },
  { value: 16, label: "Iowa" },
  { value: 17, label: "Kansas" },
  { value: 18, label: "Kentucky" },
  { value: 19, label: "Louisiana" },
  { value: 20, label: "Maine" },
  { value: 21, label: "Maryland" },
  { value: 22, label: "Massachusetts" },
  { value: 23, label: "Michigan" },
  { value: 24, label: "Minnesota" },
  { value: 25, label: "Mississippi" },
  { value: 26, label: "Missouri" },
  { value: 27, label: "Montana" },
  { value: 28, label: "Nebraska" },
  { value: 29, label: "Nevada" },
  { value: 30, label: "New Hampshire" },
  { value: 31, label: "New Jersey" },
  { value: 32, label: "New Mexico" },
  { value: 33, label: "New York" },
  { value: 34, label: "North Carolina" },
  { value: 35, label: "North Dakota" },
  { value: 36, label: "Ohio" },
  { value: 37, label: "Oklahoma" },
  { value: 38, label: "Oregon" },
  { value: 39, label: "Pennsylvania" },
  { value: 40, label: "Rhode Island" },
  { value: 41, label: "South Carolina" },
  { value: 42, label: "South Dakota" },
  { value: 43, label: "Tennessee" },
  { value: 44, label: "Texas" },
  { value: 45, label: "Utah" },
  { value: 46, label: "Vermont" },
  { value: 47, label: "Virginia" },
  { value: 48, label: "Washington" },
  { value: 49, label: "West Virginia" },
  { value: 50, label: "Wisconsin" },
  { value: 51, label: "Wyoming" }
];

export const PRACTICE_AREA = [
  { value: 1, label: "Corporate" },
  { value: 2, label: "Commercial contracts" },
  { value: 3, label: "M&A" },
  { value: 4, label: "Technology transactions" },
  { value: 5, label: "Intellectual property" },
  { value: 6, label: "Entertainment" },
  { value: 7, label: "Litigation" },
  { value: 8, label: "Other" }
];

export const BOOLEAN = [
  { value: true, label: "Yes" },
  { value: false, label: "No" }
];

export const MONTHS = [
  { value: 1, label: "01" },
  { value: 2, label: "02" },
  { value: 3, label: "03" },
  { value: 4, label: "04" },
  { value: 5, label: "05" },
  { value: 6, label: "06" },
  { value: 7, label: "07" },
  { value: 8, label: "08" },
  { value: 9, label: "09" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" }
];

export const YEARS = [
  { value: 2020, label: "2020" },
  { value: 2021, label: "2021" },
  { value: 2022, label: "2022" },
  { value: 2023, label: "2023" },
  { value: 2024, label: "2024" },
  { value: 2025, label: "2025" }
];

