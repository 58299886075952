import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";

import BreadcrumbsDashboard from "./breadcrumbsDashboard";
import { ORG_TYPES, JURISDICTIONS, PRACTICE_AREA } from "./Organizations";

import {
  getUser,
  acceptContributingLawyer,
  deleteUsers
} from "./../../store/actions/admin";


class UsersView extends Component {
  state = {
    openAccess: false,
    openDelete: false,
    error: ""
  };

  async componentDidMount() {
    await this.props.getUser(this.props.match.params.id);
  }

  onOpenAccessModal = () => {
    this.setState({ openAccess: true });
  };

  onCloseAccessModal = () => {
    this.setState({ openAccess: false });
  };
  onOpenDeleteModal = () => {
    this.setState({ openDelete: true });
  };
  onCloseDeleteModal = () => {
    this.setState({ openDelete: false });
  };

  userDeleteHandler = async id => {
    const res = await this.props.deleteUsers(id);
    if (res && res.payload.status === 204) {
      this.onCloseAccessModal();
      this.props.history.push("/admin/users");
    } else {
      this.setState({ error: "somthing went wrong" });
    }
  };

  render() {
    const { user } = this.props;
    const { openAccess, openDelete, error } = this.state;

    return (
      <div>
        <BreadcrumbsDashboard type={'user'} />
        <main className="admin-content manage">
          <div className="container-sm">
            <h2 className="font-secondary fw-600 mb-5">
              USER INFORMATION
            </h2>
            {user && (
              <form action="">
                <div className="form-details flex border-bottom black-10">
                  <ul className="list-view w-50 mr-2">
                    <li>
                      <label className="label">First Name</label>
                      <p className="value">{user.first_name}</p>
                    </li>
                    <li>
                      <label className="label">Last name</label>
                      <p className="value">{user.last_name}</p>
                    </li>
                    <li>
                      <label className="label">Email address</label>
                      <p className="value">{user.email}</p>
                    </li>
                    <li>
                      <label className="label">Organization name</label>
                      <p className="value">
                        {user.organization_name}
                      </p>
                    </li>
                    <li>
                      <label className="label">Organization type</label>
                      <p className="value">
                        {ORG_TYPES[user.organization_type]}
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="buttons mt-6 flex justify-end">
                  <button
                    type="button"
                    className="btn outline border-black-20 text-warning br-round h-lg w-lg mr-2"
                    onClick={this.onOpenDeleteModal}
                  >
                    <i className="icon-close mr-3">{""}</i>DELETE
                  </button>
                  <Modal
                    open={openDelete}
                    onClose={this.onCloseDeleteModal}
                    center
                  >
                    <div className="popup-content">
                      <form action="">
                        <p className="fs-lg text-xs-left color-black-60 mt-5 ml-2">
                          {`Are you sure that you want to delete User ${user.first_name} ${
                            user.last_name
                          }?`}
                        </p>

                        <div className="buttons mt-10 text-xs-right">
                          {error && <p className="popup-error-msg">{error}</p>}
                          <button
                            className="btn filled primary br-round h-lg w-md fs-md shadow ml-3"
                            type="button"
                            onClick={() =>
                              this.userDeleteHandler(user.id)
                            }
                          >
                            Yes
                          </button>
                          <button
                            className="btn link primary h-lg w-md fs-md"
                            type="button"
                            onClick={this.onCloseDeleteModal}
                          >
                            No
                          </button>
                        </div>
                      </form>
                    </div>
                  </Modal>
                </div>
              </form>
            )}
          </div>
        </main>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.admin.user
  };
}

export default connect(
  mapStateToProps,
  {
    getUser,
    deleteUsers,
  }
)(UsersView);
