import { useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import styled from "styled-components";
import { modalBody } from "../../../helpers/modalBody";
import { colors } from "../../../styles";
import MainButton from "../../shared/MainButton";
import modalMap from "../../shared/modalMap";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
  padding-top: 16px;
  height: 328px;
`;
const Text = styled.span``;
const Feature = styled.div`
  font-size: 14px;
  font-weight: 700;
  padding-left: 40px;
  padding-right: 16px;
`;
const PurchaseButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 26px;
  flex: 1 0 auto;
`;

const features = [
  "Access to all documents for one year",
  "View drafting tips for all documents",
  "Download or print all documents as Word/PDF",
  "One-time fee for 12-month subscription (optional auto-renewal)",
];

const AnnualFeatures = ({ purchaseHandler }) => {
  const [currentModal, setCurrentModal] = useState(null);
  const onCloseModal = () => setCurrentModal(null);
  const onOpenModal = (modalName) => setCurrentModal(modalName);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const subscription = useSelector((state) => state.payment.subscription);
  const is_annual = subscription?.subscription_type === 1;

  const onClick = () => {
    if (isLoggedIn) {
      purchaseHandler();
    } else {
      setCurrentModal("modalSignUp");
    }
  };

  const ModalComponent = modalMap[currentModal];

  return (
    <Container>
      {features.map((item) => (
        <Feature key={item}>
          <Text>{item}</Text>
        </Feature>
      ))}
      <PurchaseButtonWrapper>
        {!is_annual && (
          <MainButton onClick={onClick} color={colors.red}>
            GET STARTED
          </MainButton>
        )}
      </PurchaseButtonWrapper>
      {currentModal && (
        <Modal open onClose={onCloseModal}>
          {modalBody(
            ModalComponent,
            onCloseModal,
            null,
            null,
            null,
            onOpenModal,
            null
          )}
        </Modal>
      )}
    </Container>
  );
};

export default AnnualFeatures;
