import SignUp from "../Homepage/register/signUp";
import SuccessRegister from "../Homepage/successRegisterPopup";
import ResetPassword from "../Homepage/forgot-password/reset-password";
import SignIn from "../Homepage/login/login";
import SignUpFirst from "./SignUpFirst";

const modalMap = {
  modalSignIn: SignIn,
  modalSignUp: SignUp,
  modalRegister: SuccessRegister,
  modalReset: ResetPassword,
  modalSignUpFirst: SignUpFirst,
};

export default modalMap;
