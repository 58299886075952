import { useState } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import { SITE_PASSWORD } from "./constants";

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#777777",
  },
  content: {
    width: "600px",
    margin: "auto",
    height: "320px",
  },
};

const Maintenance = styled.div`
  font-weight: 700;
  font-size: 28px;
`;

const WhoIsAllowed = styled.div`
  margin-top: 32px;
  font-size: 16px;
`;

const PasswordInput = styled.input`
  font-size: 16px;
  margin-top: 64px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Submit = styled.button`
  margin-top: 32px;
  font-size: 16px;
`;

/** Note: THIS IS NOT INTENDED TO BE SECURE!
 * It is only intended to deter casual viewers while the site is not ready.
 */
const SitePasswordModal = () => {
  const [enteredPassword, setEnteredPassword] = useState(
    sessionStorage.getItem("savedSitePassword") || ""
  );

  const onSubmit = () => {
    if (enteredPassword === SITE_PASSWORD) {
      sessionStorage.setItem("savedSitePassword", enteredPassword);
    }
    window.location.reload();
  };

  return (
    <ReactModal isOpen style={modalStyle}>
      <Container>
        <Maintenance>This site is currently under maintenance.</Maintenance>
        <WhoIsAllowed>
          Testers and admins can enter with the site password.
        </WhoIsAllowed>
        <PasswordInput
          type="password"
          value={enteredPassword}
          onChange={(e) => setEnteredPassword(e.target.value)}
        />
        <Submit onClick={onSubmit}>Submit</Submit>
      </Container>
    </ReactModal>
  );
};

export default SitePasswordModal;
