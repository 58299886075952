import React, { Component } from "react";
import { Route } from "react-router-dom";

import profile from "./profile";
import LawyerHeader from "../common/profileHeader";
import importPage from "../common/import";
import aside from "../common/aside/aside";
import Documents from "../common/documents";
import EditDocument from "../common/documentEditor/editDocument";
import Document from "../common/document";
import Footer from "../Homepage/footer";
import requireAuth from "../../helpers/requireAuth";
import { getToken, handleLoguot } from "../../helpers/improve.js";

class RouteLawyer extends Component {
  state = {
    searchValue: "",
    searching: false,
  };

  componentDidMount() {
    if (!getToken()) {
      handleLoguot();
      this.props.history.push("/");
    }
  }

  setSearchValue = (searchValue, searching) => {
    this.setState({ searchValue, searching });
  };

  render() {
    const { searchValue } = this.state;
    return (
      <>
        <Route path="/lawyer" component={aside} />
        <Route
          path="/lawyer"
          render={(props) => (
            <LawyerHeader
              {...props}
              searchValue={searchValue}
              setSearchValue={this.setSearchValue}
            />
          )}
        />
        <Route path="/lawyer" component={Footer} />
        <Route exact path="/lawyer" component={importPage} />
        <Route path="/lawyer/profile" component={profile} />
        <Route
          path="/lawyer/view-document/:id"
          render={(props) => <Document {...props} searchValue={searchValue} />}
        />
        <Route path="/lawyer/edit-document/:id" component={EditDocument} />
        <Route path="/lawyer/new-document" component={importPage} />
        <Route
          path="/lawyer/documents"
          render={(props) => <Documents {...props} searchValue={searchValue} />}
        />
        <Route
          path="/lawyer/pending-documents"
          render={(props) => <Documents {...props} searchValue={searchValue} />}
        />
      </>
    );
  }
}

export default requireAuth(RouteLawyer);
