import { privateApi, publicApi } from './../../http/axios';

import {
  SIGN_UP_USER,
  AUTH_ERROR,
  CONFIRM_USER,
  SIGN_IN_USER,
  REMOVE_TOKEN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SAVE_RESET_TOKEN,
  CONTRIBUTOR_VERIFICATION,
  CONTRIBUTOR_CONFIRM_DATA,
  CONFIRM_EDITOR,
  ERROR,
  SIGN_UP_STEPPER_DATA,
  EDITOR_CONFIRM_DATA,
  PROFILE_INFO,
  CHANGE_PASSWORD,
  USER_PROFILE_INFO,
  UPDATE_CONTRIBUTOR,
  UPDATE_USER,
  LOGGED_IN,
  LOGGED_OUT,
} from './types'

export const signUpWithGoogle = token => dispatch => {

  const data = {
    'provider': 'google-oauth2',
    'access_token': token,
  };

  const url = '/social-signup/';
  return publicApi
  .post(url, data)
  .then((res) => {
    return dispatch({
      type: SIGN_IN_USER,
      payload: res
    });
  })
  .catch(error => {
    return dispatch({ type: AUTH_ERROR, payload: error.response })
  })
};

export const signUpWithLinkedIn = token => dispatch => {
  const data = {
    'provider': 'linkedin-oauth2',
    'access_token': token,
  };

  const url = '/social-signup/';
  return publicApi
  .post(url, data)
  .then(res => {
    return dispatch({
      type: SIGN_IN_USER,
      payload: {
        ...res,
        isSocial:true,
      }
    })
  })
  .catch(error => {
    return dispatch({ type: AUTH_ERROR, payload: error.response })
  })
};

export const signupUser = (data, params) => dispatch => {
  const url = `/registration/${params}/`
  return publicApi
  .post(url, data)
  .then(res => {
    return dispatch({
      type: SIGN_UP_USER,
      payload: {
        ...res,
        isSocial:true,
      }
    })
  })
  .catch(error => {
    return dispatch({ type: AUTH_ERROR, payload: error.response })
  })
};

export const signUpStepper = payload => ({
  type: SIGN_UP_STEPPER_DATA,
  payload
});

export const confirmUser = token => dispatch => {
  const url = `/confirmation/${token}/`
  return publicApi
  .post(url)
  .then(res => {
    return dispatch({
      type: CONFIRM_USER,
      payload: res
    })
  })
  .catch(error => {
    return error.response
  })
};

export const checkEmail = email => dispatch => {
  const url = `/check_email/`
  return publicApi
  .post(url, email)
  .then(res => res)
  .catch(error => {
    return error.response
  })
};

export const emailUpdate = token => dispatch => {
  const url = `/email_update_confirmation/${token}/`
  return publicApi
  .post(url,)
  .then(res => res)
  .catch(error => {
    return error.response
  })
};

export const contributorVerification = token => dispatch => {
  const url = `/contributor_verification/${token}/`
  return publicApi
  .post(url)
  .then(res => {
    return dispatch({
      type: CONTRIBUTOR_VERIFICATION,
      payload: res
    })
  })
  .catch(error => {
  })
};

export const signinUser = data => dispatch => {
  const url = '/login/'
  return publicApi
  .post(url, data)
  .then(res => {
    return dispatch({
      type: SIGN_IN_USER,
      payload: res
    })
  })
  .catch(error => {
    return dispatch({ type: AUTH_ERROR, payload: error.response })
  })
};

export const removeToken = payload => ({
  type: REMOVE_TOKEN,
  payload
});

export const forgotPassword = data => dispatch => {
  const url = '/request_reset_pass/'
  return publicApi
  .post(url, data)
  .then(res => {
    return dispatch({
      type: FORGOT_PASSWORD,
      payload: res
    })
  })
  .catch(error => {
    return dispatch({ type: AUTH_ERROR, payload: error.response })
  })
}

export const resetPassword = (data, token) => dispatch => {
  data.activation_token = token;
  const url = '/reset_pass/'
  return publicApi
  .post(url, data)
  .then(res => {
    return dispatch({
      type: RESET_PASSWORD,
      payload: res
    })
  })
  .catch(error => {
    return dispatch({ type: AUTH_ERROR, payload: error.response })
  })
}
export const saveResetToken = payload => ({
  type: SAVE_RESET_TOKEN,
  payload
})

export const editorVerification = token => dispatch => {
  const url = `/editor_confirmation/${token}/`
  return publicApi
  .get(url, {})
  .then(res => {
    return dispatch({
      type: EDITOR_CONFIRM_DATA,
      payload: res
    })
  })
  .catch(err => {
    return dispatch({
      type: ERROR,
      payload: err.response
    })
  })
}

export const lawyerVerification = token => dispatch => {
  const url = `/lawyer_confirmation/${token}/`
  return publicApi
  .get(url, {})
  .then(res => {
    return dispatch({
      type: CONTRIBUTOR_CONFIRM_DATA,
      payload: res
    })
  })
  .catch(err => {
    return dispatch({
      type: ERROR,
      payload: err.response
    })
  })
}

export const confirmLawyer = (data, token) => dispatch => {
  const url = `/lawyer_confirmation/${token}/`
  return publicApi
  .post(url, data, {})
  .then(res => {
    return dispatch({
      type: CONFIRM_EDITOR,
      payload: res
    })
  })
  .catch(err => {
    return dispatch({
      type: ERROR,
      payload: err.response
    })
  })
}

export const confirmEditor = (data, token) => dispatch => {
  const url = `/editor_confirmation/${token}/`

  return publicApi
  .post(url, data, {})
  .then(res => {
    return dispatch({
      type: CONFIRM_EDITOR,
      payload: res
    })
  })
  .catch(err => {
    return dispatch({
      type: ERROR,
      payload: err.response
    })
  })
}

export const getEditor = id => dispatch => {
  const url = `/editors/${id}/`
  return privateApi
  .get(url, id)
  .then(res => {
    return dispatch({
      type: PROFILE_INFO,
      payload: res
    })
  })
  .catch(error => {
    return dispatch({ type: AUTH_ERROR, payload: error.response })
  })
}

export const updateEditor = (data, id) => dispatch => {
  const url = `/editors/update/${id}/`
  return privateApi
  .put(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  .then(res => {
    return dispatch({
      type: UPDATE_CONTRIBUTOR,
      payload: res
    })
  })
  .catch(error => {
    return dispatch({ type: AUTH_ERROR, payload: error.response })
  })
}

export const getContributor = id => dispatch => {
  const url = `/contributors/${id}/`
  return privateApi
  .get(url, id)
  .then(res => {
    return dispatch({
      type: PROFILE_INFO,
      payload: res
    })
  })
  .catch(error => {
    return dispatch({ type: AUTH_ERROR, payload: error.response });
  });
}

export const updateContributor = (data, id) => dispatch => {
  const url = `/contributors/update/${id}/`
  return privateApi
  .put(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  .then(res => {
    return dispatch({
      type: UPDATE_CONTRIBUTOR,
      payload: res
    })
  })
  .catch(error => {
    return dispatch({ type: AUTH_ERROR, payload: error.response });
  })
}


export const getUser = id => dispatch => {
  const url = `/users/${id}/`
  return privateApi
  .get(url)
  .then(res => {
    return dispatch({
      type: USER_PROFILE_INFO,
      payload: res
    })
  })
  .catch(error => {
    return dispatch({ type: AUTH_ERROR, payload: error.response });
  })
}

export const updateUser = (data, id) => dispatch => {
  const url = `/users/update/${id}/`
  return privateApi
  .put(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  .then(res => {
    return dispatch({
      type: UPDATE_USER,
      payload: res
    })
  })
  .catch(error => {
    return dispatch({ type: AUTH_ERROR, payload: error.response })
  })
}


// /change_password/
export const changePassword = data => dispatch => {
  const url = `/change_password/`;
  return privateApi
  .patch(url, data)
  .then(res => {
    return dispatch({
      type: CHANGE_PASSWORD,
      payload: res,
    })
  })
  .catch(err => {
    return dispatch({
      type: ERROR,
      payload: err.response
    })
  })
};

export const loggedIn = () => dispatch => {
  return dispatch({ type: LOGGED_IN, });
};

export const loggedOut = () => dispatch => {
    return dispatch({ type: LOGGED_OUT, });
};
