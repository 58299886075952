import React, {Component} from "react";
import {connect} from "react-redux";
import {withFormik, Form, Field} from "formik/dist/index";
import Modal from "react-responsive-modal";
import * as Yup from "yup";
import loadImage from "blueimp-load-image";
import avatarImage from "../../assets/img/user-avatar.png";
import ChangePassword from "../../helpers/changePassword";
import SuccessPopup from "../Homepage/successPopup";

const SUPPORTED_FORMATS = ["image/jpeg", "image/png"];

class Profile extends Component {
    state = {
        open: false,
        first: false,
        second: false,
        third: false,
        email: "",
        success: false,
        changeEmail: false,
        profile_pic: null
    };

    onOpenModal = () => {
        this.setState({open: true});
    };

    onCloseModal = () => {
        this.setState({open: false, success: false});
    };

    onPasswordSuccess = () => {
        this.setState({success: true});
    };

    upload = async e => {
        const f = e.target.files[0];
        this.props.setValues({
            ...this.props.values,
            picture: f
        });

        loadImage(
            f,
            canvas => {
                canvas.toBlob(blob => {
                    var reader = new FileReader();

                    reader.onloadend = () => {
                        const dataURL = reader.result;
                        this.props.setValues({
                            ...this.props.values,
                            profile_pic: dataURL,
                            picType: f.type
                        });
                    };
                    this.setState({profile_pic: null});
                    reader.readAsDataURL(blob);
                });
            },
            {
                canvas: true,
                orientation: true,
                maxMetaDataSize: 262144,
                maxWidth: 128,
                maxHeight: 128
            }
        );
    };

    async componentDidMount() {
        // const res = await this.props.getUser(localStorage.getItem("id"));
        // const profile = res && res.payload && res.payload.data;
        // profile.profile_pic &&
        // this.setState({
        //   profile_pic: profile.profile_pic
        // });
        // this.props.setValues({
        //   ...this.props.values,
        //   first_name: profile.first_name,
        //   last_name: profile.last_name,
        //   email: profile.email,
        //   currentEmail: profile.email,
        //   organization_name: profile.organization_name,
        //   organization_type: ORG_TYPES.filter(
        //       ({ value }) => value === profile.organization_type
        //   )[0],
        // });
        // this.setState({ email: profile.email });
    }

    handleHide = num => {
        this.setState(prevState => {
            return {[num]: !this.state[num]};
        });
    };

    render() {
        const {
            open,
            first,
            success,
            profile_pic
        } = this.state;

        const {
            values,
            errors,
            touched,
            handleSubmit
        } = this.props;

        return (
            <div>
                <main className="admin-content manage">
                    {/* {profile && ( */}
                    <div className="">
                        <h2 className="font-secondary fw-600 mb-5">PROFILE</h2>
                        <Form>

                            <div className="card br-round mb-2">
                                <div
                                    className="flex justify-between align-center pr-10 pl-10 border-bottom primary-10 pt-3 pb-3 hide">
                                    <h5 className="color-primary fw-700 font-secondary text-uppercase mr-3">
                                        PERSONAL INFORMATION
                                    </h5>
                                    <button
                                        className="btn-icon"
                                        onClick={() => this.handleHide("first")}
                                    >
                                        <i className={`icon-arrow-${first ? "down" : "up"} color-primary`}>{""}</i>
                                    </button>
                                </div>
                                <div
                                    className={`pr-10 pl-10 pt-4 pb-6 flex card-content ${
                                        first ? "hide" : ""
                                    }`}
                                >
                                    {/* User image upload start */}
                                    {/* When the user has uploaded picture in this case need to add "uploaded" class to below included "user-image" div */}
                                    <div
                                        className={`user-image text-xs-center ${
                                            values.profile_pic ? "uploaded" : ""
                                        }`}
                                    >
                                        {/* When user doesn't have uploaded picture in this case need to use user-avatar.png image as background-image */}
                                        <div
                                            className="image"
                                            style={{
                                                backgroundImage: `url(${
                                                    profile_pic
                                                        ? profile_pic
                                                        : values.profile_pic
                                                        ? values.profile_pic
                                                        : avatarImage
                                                })`
                                            }}
                                        >
                                            {""}
                                            <div className="hover-content">
                                                <label>
                                                    <input
                                                        type="file"
                                                        name="profile_pic"
                                                        onChange={this.upload}
                                                        accept="image/png, image/jpeg"
                                                    />
                                                    Upload new <br/> picture
                                                </label>
                                            </div>
                                        </div>
                                        {!values.profile_pic && (
                                            <label className="image-upload">
                                                <input
                                                    type="file"
                                                    name="profile_pic"
                                                    onChange={this.upload}
                                                    accept="image/png, image/jpeg"
                                                />
                                                <i className="icon-camera">{""}</i>
                                                Upload Image
                                            </label>
                                        )}
                                        {/* When the user has uploaded picture instead of displaying above included label with "image-upload" class need to display the following button */}
                                        {values.profile_pic && (
                                            <button className="btn-icon color-warning flex align-center mt-4">
                                                <i className="icon-delete mr-2 fs-lg">{""}</i>Delete
                                            </button>
                                        )}
                                    </div>
                                    {/* User image upload end */}
                                    {/* User details start */}
                                    <div className="user-details">
                                        <div className="flex">
                                            <div className="w-50 mr-2">
                                                {/* <div className="text-field br-corner"> */}
                                                <div
                                                    className={`text-field br-corner  ${touched.first_name &&
                                                    errors.first_name &&
                                                    "invalid"}`}
                                                >
                                                    <label>First name*</label>
                                                    <Field
                                                        type="text"
                                                        name="first_name"
                                                        value={values.first_name}
                                                    />
                                                    {touched.first_name && errors.first_name && (
                                                        <p className="error-msg">{errors.first_name}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="w-50 ml-2">
                                                <div
                                                    className={`text-field br-corner  ${touched.last_name &&
                                                    errors.last_name &&
                                                    "invalid"}`}
                                                >
                                                    <label>Last name*</label>
                                                    <Field
                                                        type="text"
                                                        name="last_name"
                                                        value={values.last_name}
                                                    />
                                                    {touched.last_name && errors.last_name && (
                                                        <p className="error-msg">{errors.last_name}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={`text-field br-corner  ${touched.email &&
                                            errors.email &&
                                            "invalid"}`}
                                        >
                                            <label>Email address*</label>
                                            <Field type="text" name="email" value={values.email}/>
                                            {touched.email && errors.email && (
                                                <p className="error-msg">{errors.email}</p>
                                            )}
                                        </div>

                                        {values.currentEmail === values.email && (
                                            <div
                                                className={`text-field br-corner ${touched.password &&
                                                errors.password &&
                                                "invalid"}`}
                                            >
                                                <label>Password*</label>
                                                <Field
                                                    type="password"
                                                    placeholder="Password"
                                                    name="password"
                                                    value={values.password}
                                                />
                                                {touched.password && errors.password && (
                                                    <p className="error-msg">{errors.password}</p>
                                                )}
                                            </div>
                                        )}

                                        <button
                                            className="btn-icon color-primary fs-md ml-5"
                                            type="button"
                                            onClick={this.onOpenModal}
                                        >
                                            {" "}
                                            <i className="icon-lock mr-3">{""}</i>Change Password
                                        </button>
                                        {/* Change password popup start */}
                                        {/* arandzin component sarqel */}
                                        <Modal open={open} onClose={this.onCloseModal} center>
                                            {!success ? (
                                                <ChangePassword
                                                    onPasswordSuccess={this.onPasswordSuccess}
                                                />
                                            ) : (
                                                <SuccessPopup
                                                    text={
                                                        "Your password has been changed successfully!"
                                                    }
                                                    onClose={this.onCloseModal}
                                                    icon="lock"
                                                    title={false}
                                                    modalName="modalSignIn"
                                                />
                                            )}
                                        </Modal>

                                        {/* Change password popup end */}
                                    </div>
                                    {errors.profile_pic && (
                                        <p className="error-msg">{errors.profile_pic}</p>
                                    )}
                                    {/* User details end */}
                                </div>
                            </div>

                            <div className="text-xs-center">
                                <button
                                    className="btn filled primary br-round shadow h-lg w-xl"
                                    onClick={handleSubmit}
                                >
                                    SAVE CHANGES
                                </button>
                            </div>
                        </Form>
                    </div>
                </main>
            </div>
        );
    }
}

const ProfileFormik = withFormik({
    mapPropsToValues({
                         first_name,
                         last_name,
                         email,
                         organization_name,
                         organization_type,
                         profile_pic
                     }) {
        return {
            first_name: first_name || "",
            last_name: last_name || "",
            email: email || "",
            profile_pic: profile_pic || "",
            organization_name: organization_name || "",
            organization_type: organization_type || "",
            password: ""
        };
    },

    validationSchema: Yup.object().shape({
        first_name: Yup.string().required("This field is mandatory."),
        last_name: Yup.string().required("This field is mandatory."),
        email: Yup.string()
            .trim()
            .required("This field is mandatory.")
            .matches(/^[^@]+@[^@]+$/, {
                message: "Please provide a valid email.",
                excludeEmptyString: true
            }),
        password: Yup.string()
            .min(6, "The Password field must be 6 or more characters.")
            .max(20, "Please provide a valid password."),

        organization_name: Yup.string()
            .required("This field is mandatory.")
            .max(200, "Maximum number of symbols is 200."),
        organization_type: Yup.string().required("This field is mandatory."),
    }),

    async handleSubmit(values, {props, setErrors, setValues, errors, resetForm, setSubmitting}) { //handleSub,i get data when data is submitted
        const data = {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            organization_name: values.organization_name,
            organization_type: values.organization_type.value,
            password: values.password,
            profile_pic: values.picture
        };

        if (!values.profile_pic) {
            delete data.profile_pic;
        }

        function getFormData(object) {
            const formData = new FormData();
            Object.keys(object).forEach(key => formData.append(key, object[key]));
            return formData;
        }

        const form_data = getFormData(data);

        if (values.email !== values.currentEmail && !values.password) {
            setErrors({...errors, password: "This field is mandatory."});
        } else if (values.picType && !SUPPORTED_FORMATS.includes(values.picType)) {
            setErrors({...errors, profile_pic: "Profile picture format should be JPG or PNG."});
        } else {
            const res = await props.updateUser(form_data, localStorage.getItem("id"));
            if (res && res.payload && res.payload.status === 200) {
                props.history.push("/");
            }
        }
    }
})(Profile);

function mapStateToProps(state) {
    return {
        profile: state.auth.profile
    };
}

export default connect(
    mapStateToProps,
    {}
)(ProfileFormik);
