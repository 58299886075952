import React, { Component } from "react";
import { components } from "react-select";

export class Option extends Component {
  state = {
    resources: {},
    checked: false
  };

  render() {
    return (
      <div>
        <components.Option {...this.props}>
          <input
            value={this.props.value}
            className="checkbox-control"
            name="organization"
            id={this.props.label}
            type="checkbox"
            checked={this.props.isSelected}
            onChange={() => false}
          />
          <span className="check-icon">{""}</span>
          <span className="checkbox-label">{this.props.label}</span>
        </components.Option>
      </div>
    );
  }
}

export default Option;
