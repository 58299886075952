import { useState } from "react";
import { Link as LinkBase } from "react-router-dom";
import styled from "styled-components";
import Footer from "./components/Homepage/footer";
import Header from "./components/Homepage/header";
import TitleBar from "./components/shared/TitleBar";
import { fontFamilies, mainContentWidth, colors } from "./styles";

const Link = styled(LinkBase)`
  text-decoration: underline;
  font-family: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  font-size: inherit;
`;

const ExternalLink = styled.a`
  text-decoration: underline;
  font-family: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  font-size: inherit;
`;

const AnswerSpan = styled.span`
  font-family: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  font-size: inherit;
`;

const faqs = [
  {
    question: "Who created JDForm?",
    answer: (
      <AnswerSpan>
        <ExternalLink href="http://jmdorsey.com">John Dorsey</ExternalLink>, a
        corporate and commercial contracts lawyer with over 17 years of
        experience, created JDForm to provide businesses and entrepreneurs with
        access to expert quality corporate legal forms.
      </AnswerSpan>
    ),
  },
  {
    question: "How many model forms are in the library?",
    answer:
      "As of mid-2022, the library contains more than 10 documents. Documents are added to the library on a monthly basis.",
  },
  {
    question: "How much does access to JDForm cost?",
    answer: (
      <AnswerSpan>
        Forms may either be purchased one-by-one or by purchasing an annual
        subscription offering access to the full library for one year. See{" "}
        <Link to="/pricing">Pricing</Link>.
      </AnswerSpan>
    ),
  },
  {
    question: "What does the purchase price include?",
    answer:
      "If you purchase an individual form, you may view, access and download the form (including drafting tips) for 10 days at JDForm. If you purchase an annual subscription, you may view, access and download all forms (including drafting tips) in the library, including forms that are subsequently added to the library during the one-year subscription period.",
  },
  {
    question: `What are “drafting tips”?`,
    answer:
      "Drafting tips are text boxes located in various sections of model documents containing optional language for inclusion in the document, negotiating tips or other helpful notes regarding the section or clause in question.",
  },
  {
    question: "What if I need a form that is not in the library?",
    answer: (
      <AnswerSpan>
        If you don&apos;t see a document you need in the library, you can
        request it <Link to="/contact">here</Link>. If we receive sufficient
        requests for the same document, we may add it to the library.
      </AnswerSpan>
    ),
  },
  {
    question: "What support is provided for each document?",
    answer: `All forms are provided on an “as-is” basis, without support beyond the drafting tips included in the document.`,
  },
  {
    question: "Does JDForm provide legal advice?",
    answer: "No, JDForm is not a law firm and does not provide legal advice.",
  },
];

const descriptionText = (
  <span>
    Please find below frequently asked questions regarding JDForm. If you have a
    question that is not answered here, please{" "}
    <Link to="/contact">contact us</Link>.
  </span>
);

const MainContent = styled.div`
  width: ${mainContentWidth};
  margin: auto;
`;

const Description = styled.div`
  font-size: 18px;
  line-height: 32px;
  margin-top: 80px;
  letter-spacing: 0;
  font-family: ${fontFamilies.serif};
  margin-bottom: 40px;
`;

const FaqSection = styled.div``;

const ItemContainer = styled.div``;

const Question = styled.div`
  display: flex;
  ${(p) => (p.selected ? `background-color: ${colors.blueLight};` : "")}
  cursor: pointer;
  padding: 32px 0 32px 24px;
  border-top: 1px solid ${colors.gray};
`;

const QuestionText = styled.div`
  flex: 1 0 auto;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
  color: ${colors.blueDark};
`;

const ExpandCollapse = styled.div`
  width: 80px;
  color: ${colors.blue};
  font-size: 24px;
  font-weight: 700;
`;

const Answer = styled.div`
  font-family: ${fontFamilies.serif};
  font-size: 16px;
  line-height: 28px;
  padding: 16px;
`;

const FaqItem = ({ question, answer, onClick, selected }) => {
  return (
    <ItemContainer>
      <Question onClick={onClick} selected={selected}>
        <QuestionText>{question}</QuestionText>
        <ExpandCollapse>{selected ? "-" : "+"}</ExpandCollapse>
      </Question>
      {selected && <Answer>{answer}</Answer>}
    </ItemContainer>
  );
};

const FaqPage = () => {
  const [selected, setSelected] = useState(null);

  const toggleSelected = (toToggle) => {
    setSelected((oldSelected) => (oldSelected === toToggle ? null : toToggle));
  };

  return (
    <div>
      <Header />

      <TitleBar
        topMargin="160px"
        title="FAQ"
        backgroundImage="/jd_form_hero_mountain.png"
      />

      <MainContent>
        <Description>{descriptionText}</Description>
        <FaqSection>
          {faqs.map((item) => (
            <FaqItem
              question={item.question}
              answer={item.answer}
              selected={selected === item.question}
              onClick={() => toggleSelected(item.question)}
              key={item.question}
            />
          ))}
        </FaqSection>
      </MainContent>

      <Footer />
    </div>
  );
};

export default FaqPage;
