import React, { Component } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  ElementsConsumer,
  CardElement,
} from "@stripe/react-stripe-js";
import { STRIPE_PUBLISHABLE_KEY } from "../../constants";

class CreditCard extends Component {
  state = {
    cardName: "",
    error: "",
    cardError: "",
  };

  constructor(props) {
    super(props);

    this.handleNameChange = this.handleNameChange.bind(this);
  }

  /**
   * @description Submit handler for Stripe.
   * @param {Object} event - Submit event of stripe.
   */
  onSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { sending } = this.state;
    if (sending) {
      return;
    }
    this.setState({ sending: true });

    const { stripe, elements, purchase, isOrder } = this.props;
    const cardElement = elements.getElement(CardElement);
    const { cardName: name } = this.state;
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: { name },
    });

    if (error) {
      if (
        error.code === "parameter_invalid_empty" &&
        error.param === "billing_details[name]"
      ) {
        this.setState({ error: "This field is mandatory." });
      }
    } else {
      if (isOrder) {
        purchase(paymentMethod.id);
      } else {
        const data = {
          stripe_payment_method_id: paymentMethod.id,
        };
        const res = await this.props.addCard(data);
        if (res.error) {
          if (res.message.indexOf("card" >= 0)) {
            this.setState({ cardError: res.message });
          } else {
            this.setState({ error: res.message });
          }
        } else {
          this.props.onClose();
        }
      }
    }
    this.setState({ sending: false });
  };

  handleNameChange = function({ target: { value } }) {
    const { error } = this.state;
    const data = { cardName: value };
    if (error) {
      data.error = "";
      data.cardError = "";
    }

    this.setState(data);
  };

  render() {
    const { isOrder } = this.props;
    const { error, cardError } = this.state;

    console.log(error);

    const CARD_OPTIONS = {
      hidePostalCode: true,
      style: {
        base: {
          color: "#000",
          fontFamily:
            "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",
          fontSize: "16px",
          padding: "10px",
          textTransform: "capitalize",
          ":-webkit-autofill": { color: "#000" },
          "::placeholder": { color: "rgba(0,0,0,0.5)", opacity: 1 },
        },
        invalid: {
          iconColor: "#E43535",
          color: "#E43535",
        },
      },
    };

    return (
      <div className="popup-content add-credit-card-popup">
        <h3 className="popup-title">Add Credit Card</h3>
        <form onSubmit={this.onSubmit}>
          <div className={`text-field br-corner ${error && "invalid"}`}>
            <input
              onChange={this.handleNameChange}
              type="text"
              placeholder="Name on Card"
              name="name"
            />
            <p className="error-msg">{error}</p>
          </div>

          <div className={`credit-card-input ${cardError && "invalid"}`}>
            <CardElement
              options={CARD_OPTIONS}
              onChange={(value) => {
                this.setState({ cardError: "" });
              }}
              onEscape={(a) => {
                console.log(a);
              }}
            />
            {cardError && <p className="error-msg">{cardError}</p>}
          </div>

          <button
            onClick={this.onSubmit}
            className={`mt-5 btn filled primary br-round h-lg shadow w-lg text-uppercase ${
              !"disabled" ? "disabled" : ""
            }`}
            type="button"
          >
            {isOrder ? "PURCHASE" : "SUBMIT"}
          </button>
        </form>
      </div>
    );
  }
}

const InjectedCheckoutForm = (props) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CreditCard stripe={stripe} elements={elements} {...props} />
    )}
  </ElementsConsumer>
);

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export default class PaymentRequest extends Component {
  render() {
    return (
      <Elements stripe={stripePromise}>
        <InjectedCheckoutForm {...this.props} />
      </Elements>
    );
  }
}
