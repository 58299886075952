import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { withFormik } from "formik";

import docs from "../../assets/img/docs.svg";
import { uploadAboutFile, getAbout, deleteAboutFile } from '../../store/actions/admin';
import { DeleteModal } from '../../components/common/modal';

class About extends Component {

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.privacy_policy_ref = createRef();
    this.terms_of_use_ref = createRef();
  }

  state = {
    deleteModal: false,
  };

  componentDidMount() {
    this.props.getAbout();
  }

  /**
   * @description Handle file uploading.
   */
  async onChange({target}){
    const file = target.files[0];
  const fileName = target.name;
  const errName = fileName + '_error';

  if (/\.(pdf)$/i.test(file.name) === false) {
    this.props.setErrors({ [errName]: "*The file format must be 'pdf'" });
  } else {
    await this.setState({[ target.name]: file });
    await this.props.setValues({
      ...this.props.values,
      file,
      fileName: fileName,
      terms_of_use_error: '',
      privacy_policy_error: '',
      });
     await this.props.handleSubmit();
   }
  }

  deleteFileHandler = async () => {
    const { documentData:{ id, type }}  = this.state;


    const removed_type = type === 1 ? 'privacy_policy': 'terms_of_use';
    await this.props.deleteFile(id);
    this.props.getAbout();
    this.setState({
      [removed_type]: null,
      deleteModal: false,
    });
  };

  onToggleModal = (id, type) => {
    this.setState({
      deleteModal: !this.state.deleteModal,
      documentData: {
        id,
        type,
      },
    });
  };

  render () {
    const { deleteModal } = this.state;
    const { values, errors, about, } = this.props;
    const privacy_policy = about.privacy_policy || this.state.privacy_policy;
    const terms_of_use = about.terms_of_use || this.state.terms_of_use;

    return (
      <div>
        <main className="admin-content manage">
          <div className="container-sm">
            <div className="mb-8">
              <h2 className="font-secondary fw-600">About</h2>
            </div>
            <DeleteModal
              openDeleteModal={deleteModal}
              onCloseDelModal={this.onToggleModal}
              onOpenDelModal={this.onToggleModal}
              content={'Are you sure that you want to delete the selected document.'}
              handleDelete={this.deleteFileHandler}
            />
            <form>
              <div className="import flex align-center mb-8">
                {/* Add disabled class when file is uploaded */}
                <div className="file-import">
                  <div className="upload-section mr-5">
                    <div className={`file-upload sm ${errors.privacy_policy_error? 'invalid': ''}`}>
                      <label htmlFor="file-upload-privacy" className="file-upload-label">
                        <input
                            id="file-upload-privacy"
                            name="privacy_policy"
                            type="file"
                            accept='.pdf'
                            className="file-upload-input"
                            onChange={this.onChange}
                        />
                        <i className="icon-upload fs-xxl"> </i>
                        <p className="fs-sm">Upload Privacy Policy</p>
                      </label>
                    </div>
                  </div>
                  { errors.privacy_policy_error && <p className='error-msg'>{errors.privacy_policy_error}</p> }
                </div>

                {/* Add disabled class when file is uploaded */}
                <div className="file-import">
                  <div className="upload-section">
                    <div className={`file-upload sm ${ errors.terms_of_use_error? 'invalid': ''}`}>
                      <label htmlFor="file-upload-terms" className="file-upload-label">
                        <input
                            id="file-upload-terms"
                            name="terms_of_use"
                            type="file"
                            className="file-upload-input"
                            accept=".pdf"
                            onChange={this.onChange}
                        />
                        <i className="icon-upload fs-xxl"> </i>
                        <p className="fs-sm">Upload Terms of Use</p>
                      </label>
                    </div>
                  </div>
                  { errors.terms_of_use_error && <p className='error-msg'>{ errors.terms_of_use_error}</p> }
                </div>
              </div>
            </form>

            <div>

              { privacy_policy && <UploadProcess
                  file={privacy_policy}
                  width={values.privacy_policy_loaded || 100}
                  is_loading={!!values.privacy_policy_loaded}
                  weight={privacy_policy.size || 1024}
                  deleteHandler={this.onToggleModal}
              /> }

              { terms_of_use && <UploadProcess
                  file={terms_of_use}
                  width={values.terms_of_use || 100}
                  is_loading={!!values.terms_of_use}
                  weight={terms_of_use.size || 1024}
                  deleteHandler={this.onToggleModal}
              /> }
            </div>
          </div>
        </main>
      </div>
    )
  }
}

const AboutFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: values => {
    return {};
  },
  async handleSubmit(values, { props , setValues, setErrors }){
    const formData = new FormData();
    const file_type = values.fileName === 'privacy_policy'? '1': '2';
    formData.append('file_type', file_type);
    formData.append('file', values.file);
    function callback(progressEvent) {
      const loadedFile = values.fileName + '_loaded';
      const percent = Math.floor(progressEvent.loaded / progressEvent.total) * 100;
      setValues({
        ...values,
        [loadedFile]: percent,
      });
    }

    try {
      const response = await props.upload(formData, callback);
      if (response.payload.status === 201){
        await setValues({ ...values, privacy_policy_loaded: false, terms_of_use_loaded: false});
        props.getAbout();
      } else if (response.payload.status === 400){
        setErrors({ response_error: response.payload.data.message[0] })
      }
    } catch (e) {
      console.log(e);
    }
  },
})(About);

const mapDispatchToProps = {
  upload: uploadAboutFile,
  getAbout: getAbout,
  deleteFile: deleteAboutFile,
}

const mapStateToProps = ({ admin: { about }}) => ({
  about: about || {},
});

const UploadProcess = ({ file, width, weight, deleteHandler, is_loading }) => {

  return (
      <div className="upload-process sm flex align-center mb-5">
        <div className="doc-image"> <img src={docs} alt="Doc file"/> </div>
        <div className="doc-info">
          <p className="font-secondary fw-600 color-grey mb-1 ellipsis">{file.name || 'not found'}</p>
          <div className="progress-bar mr-4">
            <div className="filled" style={{width: width + '%'}} />
          </div>
          <p className="fs-xs color-grey mt-1">{(weight / 1024).toFixed(2)} kb</p>
        </div>
        { !is_loading && (
            <button
                type="button"
                className="btn-icon fs-xl ml-3 p-0"
                onClick={ () => deleteHandler(file.id, file.file_type) }
            >
              <i className="icon-delete color-warning"/>
            </button>
        )}
      </div>
  );
};


export default connect(mapStateToProps, mapDispatchToProps)(AboutFormik);
