import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import { withRouter } from "react-router-dom";
import ReactToPrint from "react-to-print";

import {
  documentDownload,
  getDocumentById,
  getDocumentByIdWithPermission,
  getDocumentHTML,
} from "../../store/actions/documents";
import { getSubscription } from "../../store/actions/payment";

import { makeDocumentPrintable } from "./documentEditor/helper";

import DownloadModal from "./documentEditor/download";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import { colors } from "../../styles";
import DocButton from "./DocButton";
import { modalBody } from "../../helpers/modalBody";
import modalMap from "../shared/modalMap";
import DraftWatermark from "./DraftWatermark";

const CardHeader = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  background-color: ${colors.blueLight};
  padding: 8px 16px;
`;

const DocContent = styled.div`
  position: relative;
  ${(p) =>
    p.showPreview
      ? `
    height: 504px;
    y-overflow: hidden;
  `
      : ""}
`;

class Document extends Component {
  printableDocument = createRef();

  state = {
    documentTitle: null,
    imported_by: null,
    imported_by_name: null,
    creator_picture: null,
    creator_role: null,
    url_imported_by: null,
    organization_imported_with: null,
    url_organization_imported_with: null,
    openDownload: false,
    selectedFields: [],
    open: false,
    role: null,
    is_bought: null,
    is_sample: null,
    loading: true,
  };

  async componentDidMount() {
    const role = localStorage.getItem("role") || sessionStorage.getItem("role");
    const token =
      localStorage.getItem("auth") || sessionStorage.getItem("auth");
    let id = null;
    const styleTag = document.createElement("style");
    if (role === "1" || !role) {
      id = this.props.id ?? this.props.match.params.id;
    } else {
      id = this.props.match.params.id;
    }
    let res;

    if (role) {
      if (role === "2") {
        return this.props.history.push("/admin");
      }
      if (role !== "4") {
        await this.props.getSubscription();
      }
      res = await this.props.getDocumentByIdWithPermission(id);
    } else {
      res = await this.props.getDocumentById(id);
    }

    const response = await this.props.getDocumentHTML(
      res.payload.data.document_file
    );
    let draftingNotes = res && res.payload && res.payload.data.draftingnote;
    console.log(draftingNotes);
    const documentInner = document.querySelector("#card-body");
    const is_annual = this.props.subscription.subscription_type === 1;

    styleTag.type = "text/css";
    styleTag.innerHTML =
      "@page { margin: 90px 0, .edit-doc { display:none; } } @media print{ .edit-doc { display:none; }}";

    documentInner.innerHTML = response && response.payload.data;
    await this.setState({ loading: false });

    const docContainer = document.querySelector(".card-body");
    docContainer.innerHTML = documentInner.innerHTML;

    const annotatedParagraphs = Array.from(
      document.querySelectorAll(`.card-body [id]`)
    );
    this.annotationInsertHandler(
      annotatedParagraphs,
      draftingNotes,
      res.payload.data.is_bought,
      is_annual
    );

    const {
      printableDocument: { current },
    } = this;
    current.innerHTML = docContainer.innerHTML;

    makeDocumentPrintable(
      docContainer,
      this.printableDocument.current,
      styleTag,
      "view"
    );
    current.querySelectorAll(".newDraftingNote").forEach((note) => {
      note.style =
        "border: 1px dashed #cccccc; padding: 20px 10px; margin: 16px 0 12px";
      note.className = "";
    });

    const paragraphs = document.querySelectorAll(`.edit-doc-section`);
    paragraphs.forEach((paragraph) => {
      if (paragraph.style.lineHeight === "100%") {
        paragraph.style.lineHeight = "120%";
      }
    });

    const anchorElements = document.querySelectorAll(`.card-body a`);
    anchorElements.forEach((a) => {
      a.target = "_blank";
    });

    let imported_by_name;

    if (res && res.payload && res.payload.data) {
      const {
        creator_picture,
        last_modified,
        creator_role,
        name,
        is_bought,
        is_sample,
        imported_by,
        url_imported_by,
        imported_with,
        url_imported_with,
        organization_imported_with,
        url_organization_imported_with,
      } = res.payload.data;

      const { first_name, last_name } = res.payload.data.last_editor;
      const last_editor = first_name + " " + last_name;
      let creator, nameForBackground;
      {
        const { first_name, last_name } = res.payload.data.user;
        creator = first_name + " " + last_name;
        nameForBackground =
          first_name[0].toUpperCase() + " " + last_name[0].toUpperCase();
      }

      if (imported_by.length) {
        const name = imported_by.split(" ");
        const firstName = name[0] ? name[0][0].toUpperCase() : "";
        const lastName = name[1] ? name[1][0].toUpperCase() : "";
        imported_by_name = firstName + lastName;
      }

      this.setState({
        creator_picture,
        organization_imported_with,
        url_organization_imported_with,
        imported_by_name,
        imported_by,
        url_imported_by,
        imported_with,
        url_imported_with,
        role,
        id,
        token,
        last_modified,
        creator_role,
        last_editor,
        creator,
        nameForBackground,
        documentTitle: name,
        is_bought,
        is_sample,
        is_annual,
      });
    }
  }

  annotationInsertHandler = (
    annotatedParagraphs,
    annotations,
    is_bought,
    isAnnual
  ) => {
    annotations.forEach((note) => {
      const paragraph = annotatedParagraphs.find(
        (item) => +item.id === note.paragraph_id
      );
      const annotationContainer = document.createElement("div");
      const annotationContent = document.createElement("p");
      const annotationTitle = document.createElement("p");
      const showButton = document.createElement("button");
      showButton.className = "btn-manage btn-expand";
      annotationTitle.innerHTML = note.title;
      annotationTitle.className = "note-title";
      annotationTitle.append(showButton);
      annotationContent.innerHTML = note.content;
      annotationContent.className = "note-desc";
      annotationContainer.className = "draftingNote";
      annotationContainer.appendChild(annotationTitle);
      annotationContainer.appendChild(annotationContent);
      if (note.paragraph_id === 0) {
        const firstParagraph = document
          .querySelector(".edit-doc")
          .querySelector(".edit-doc-section");
        firstParagraph.parentNode.insertBefore(
          annotationContainer,
          firstParagraph
        );
      } else {
        insertAfter(annotationContainer, paragraph);
      }
      showButton.onclick = (e) => {
        if (annotationContainer.className.includes("expanded")) {
          annotationContainer.className = "draftingNote";
        } else {
          annotationContainer.className += " expanded";
        }
      };
    });

    // data.forEach((currentNote) => {
    //   if (!currentNote) return;
    //   const paragraph = annotatedParagraphs.find(
    //     (item) => +item.id === currentNote.paragraph_id
    //   );
    //   if (currentNote.paragraph_id !== 0 && !paragraph) return;
    //   const draftingNote = document.createElement("div");
    //   const textLength = +paragraph?.childNodes?.length;
    //   draftingNote.innerHTML = annotation;
    //   draftingNote.className = "draftingNote";
    //   draftingNote.firstElementChild.firstElementChild.value = currentNote.title;
    //   draftingNote.firstElementChild.lastElementChild.innerHTML =
    //     currentNote.content;
    //   if (currentNote.paragraph_id === 0) {
    //     const firstParagraph = document.querySelector(".edit-doc-section");
    //     firstParagraph.parentNode.insertBefore(draftingNote, firstParagraph);
    //     initializeAnnotationFunctionality(
    //       null,
    //       textLength,
    //       initializeStage,
    //       draftingNote
    //     );
    //   } else {
    //     paragraph.parentNode.insertBefore(draftingNote, paragraph.nextSibling);
    //     initializeAnnotationFunctionality(paragraph, textLength, initializeStage);
    //   }
    // });

    function insertAfter(newNode, referenceNode) {
      referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    }
  };

  onCloseErrorModal = () => {
    this.setState({ open: false });
    this.props.history.push("/");
  };
  onCloseDownload = () => {
    this.setState({ openDownload: false, selectedFields: [] });
  };

  formFieldChangeHandler = (e) => {
    const { selectedFields } = this.state;
    if (selectedFields.length < 2) {
      const name = e.target.attributes.type.ownerElement.name;
      if (name !== selectedFields[0]) {
        selectedFields.push(name);
      }
    }
    this.setState({ selectedFields });
  };

  downloadHandler = (values) => {
    const id = this.state.id;
    const defaultValue = {
      chooseOption: "false",
      chooseFormat: "word",
    };
    values = { ...defaultValue, ...values };
    this.props.documentDownload(id, values);
  };

  onBeforePrint = async () => {
    await new Promise((resolve, reject) => {
      const { is_sample, is_bought, is_annual } = this.state;
      is_sample || is_bought || is_annual ? resolve() : reject();
    });
  };

  printHandler = () => {
    const { token, is_bought, is_sample, id, is_annual, role } = this.state;

    if (role === "4" || is_sample) return;

    if (!token) {
      this.onOpenModal("modalSignIn");
    } else if (!is_bought && !is_annual) {
      this.props.history.push("/pricing", { document_id: id });
    }
  };

  showDraftWatermark = () => false;

  showPreview = () => {
    const { token, is_bought, is_annual, role, is_sample } = this.state;
    if (is_sample) return false;
    return !token || (role !== "4" && !is_bought && !is_annual);
  };

  handleDownload = () => {
    const { is_bought, id, role, is_sample } = this.state;
    const token = sessionStorage.auth || localStorage.auth;

    if (role === "4") return;

    if (is_sample) {
      return this.setState({ openDownload: !this.state.openDownload });
    } else if (token) {
      if (is_bought) {
        return this.setState({ openDownload: !this.state.openDownload });
      }
      this.props.history.push("/pricing", { document_id: id });
    } else {
      this.onOpenModal("modalSignIn");
    }
  };

  renderBySection = () => {
    const {
      imported_by,
      url_imported_by,
      imported_with,
      url_imported_with,
      organization_imported_with,
      url_organization_imported_with,
    } = this.state;

    return (
      <p className="italic">
        {url_imported_by ? (
          <>
            <a
              style={{ color: "#5386D4" }}
              href={`${url_imported_by}`}
              target="_blank"
            >
              {imported_by}
            </a>
          </>
        ) : (
          <>By {imported_by}</>
        )}{" "}
        {url_imported_with ? (
          <>
            and{" "}
            <a
              style={{ color: "#5386D4" }}
              href={`${url_imported_with}`}
              target="_blank"
            >
              {" "}
              {imported_with}
            </a>
          </>
        ) : (
          <>{imported_with && <>and {imported_with}</>}</>
        )}
        {"   "}
        {url_organization_imported_with ? (
          <>
            of{" "}
            <a
              style={{ color: "#5386D4" }}
              href={`${url_organization_imported_with}`}
              target="_blank"
            >
              {organization_imported_with}
            </a>
          </>
        ) : (
          <>
            {organization_imported_with && <>of {organization_imported_with}</>}
          </>
        )}
      </p>
    );
  };

  onCloseModal = () => this.setState({ currentModal: null });

  onOpenModal = (newModal) => this.setState({ currentModal: newModal });

  render() {
    const {
      openDownload,
      selectedFields,
      open,
      role,
      last_modified,
      documentTitle,
      is_bought,
      is_annual,
      loading,
      currentModal,
    } = this.state;

    const disabled = selectedFields.length !== 2;
    const editTime = new Date(last_modified).toLocaleDateString();

    return (
      <main
        className={`${role && role !== "1" ? "admin-content manage" : "w-100"}`}
      >
        <div className="files">
          <div id="card-body" style={{ display: "none" }}>
            {" "}
          </div>
          {loading ? (
            <div className={"loader-container"}>
              <Loader type="ThreeDots" color="#5386D4" height={20} width={60} />
            </div>
          ) : (
            <div className="edit-doc container ">
              <div className="flex justify-between edit-doc-items ">
                <div className="edit-doc-content ">
                  {role && role !== "1" && (
                    <div className="flex align-center justify-between mb-2 page-header doc-view-mode-header">
                      <h2 className="font-secondary fw-600">{documentTitle}</h2>
                      <div className="flex card pt-4 pb-4 pr-6 pl-6 ml-6">
                        <ul className="list-edit italic">
                          <li>
                            <label className="label">By: </label>
                            <span> {this.renderBySection()}</span>
                          </li>
                          <li>
                            <label className="label">Last edit:</label>
                            <span className="value">{editTime}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}

                  <CardHeader className="text-xs-right card-header pt-2 pb-2">
                    <DocButton onClick={this.handleDownload}>
                      Download
                    </DocButton>
                  </CardHeader>

                  <DocContent
                    showPreview={this.showPreview()}
                    className="card doc-content view-mode"
                  >
                    <DownloadModal
                      onClose={this.onCloseDownload}
                      open={openDownload}
                      downloadHandler={this.downloadHandler}
                      formFieldChangeHandler={this.formFieldChangeHandler}
                      disabled={disabled}
                    />
                    <Modal open={open} onClose={this.onCloseErrorModal}>
                      <div className="popup-content">
                        Something went wrong :( please try again
                      </div>
                    </Modal>

                    <div className="card-body "> </div>

                    <div
                      style={{
                        position: "absolute",
                        zIndex: 0,
                        width: 0,
                        height: 0,
                        overflow: "hidden",
                      }}
                    >
                      <div
                        id="printableDocument"
                        ref={this.printableDocument}
                        style={{ padding: "50px" }}
                      >
                        {" "}
                      </div>
                    </div>
                    {this.showDraftWatermark() && <DraftWatermark />}
                  </DocContent>
                  {/* Edit doc content end */}
                </div>
                {/*DocumentFormStart*/}
              </div>
            </div>
          )}
        </div>
        {currentModal && (
          <Modal open onClose={this.onCloseModal}>
            {modalBody(
              modalMap[currentModal],
              this.onCloseModal,
              null, // lawyerModal
              null, // lawyerToken
              null, // handleClick
              this.onOpenModal,
              null // lawyerConfirm
            )}
          </Modal>
        )}
      </main>
    );
  }
}

const mapDispatchToProps = {
  getDocumentHTML,
  documentDownload,
  getDocumentById,
  getDocumentByIdWithPermission,
  getSubscription,
};

const mapStateToProps = ({ payment, auth }) => ({
  subscription: payment.subscription || {},
  auth,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Document)
);
