import { publicApi, privateApi } from './../../http/axios'
import {
  CREATE_EDITOR,
  GET_EDITORS,
  DELETE_EDITORS,
  DELETE_USERS,
  ERROR,
  GET_CONTRIBUTING_LAWYERS,
  GET_CONTRIBUTING_LAWYER,
  ACCEPT_CONTRIBUTING_LAWYER,
  DELETE_CONTRIBUTING_LAWYER,
  GET_USERS,
  GET_USER,
  CLEAR_CONTRIBUTING_LAWYERS,
  CREATE_ABOUT_FILE_IN_PROGRESS,
  CREATE_ABOUT_FILE_SUCCEED,
  CREATE_ABOUT_FILE_FAILED,
  GET_ABOUT_SUCCEED,
  GET_ABOUT_IN_PROGRESS, GET_ABOUT_FAILED, DELETE_ABOUT_FAILED, DELETE_ABOUT_SUCCEED,
} from './types'

export const createEditor = data => dispatch => {
  const url = `/create_editor/`
  return privateApi
    .post(url, data, {})
    .then(res => {
      return dispatch({
        type: CREATE_EDITOR,
        payload: res
      })
    })
    .catch(err => {
      return dispatch({
        type: ERROR,
        payload: err.response
      })
    })
}

export const getEditors = page => dispatch => {
  const url = `/editors/${page ? `?page=${page}` : ''}`
  return privateApi
    .get(url)
    .then(res => {
      return dispatch({
        type: GET_EDITORS,
        payload: res
      })
    })
    .catch(err => {
      return dispatch({
        type: ERROR,
        payload: err.response
      })
    })
}

export const getUsers = page => dispatch => {
  const url = `/users/${page ? `?page=${page}` : ''}`
  return privateApi
    .get(url)
    .then(res => {
      return dispatch({
        type: GET_USERS,
        payload: res
      })
    })
    .catch(err => {
      return dispatch({
        type: ERROR,
        payload: err.response
      })
    })
}
export const getUser = id => dispatch => {
  const url = `/users/${id}/`
  return privateApi
    .get(url)
    .then(res => {
      return dispatch({
        type: GET_USER,
        payload: res
      })
    })
    .catch(err => {
      return dispatch({
        type: ERROR,
        payload: err.response
      })
    })
}

export const deleteUsers = id => dispatch => {
  const url = `/users/${id}/`
  return privateApi
    .delete(url)
    .then(res => {
      return dispatch({
        type: DELETE_USERS,
        payload: {id, status: res.status},
      })
    })
    .catch(err => {
      return dispatch({
        type: ERROR,
        payload: err.response
      })
    })
}
export const deleteEditors = id => dispatch => {
  const url = `/editors/${id}/`
  return privateApi
    .delete(url)
    .then(res => {
      return dispatch({
        type: DELETE_EDITORS,
        payload: res
      })
    })
    .catch(err => {
      return dispatch({
        type: ERROR,
        payload: err.response
      })
    })
}

export const createLawyer = data => dispatch => {
  const url = `/create_lawyer/`
  return privateApi
    .post(url, data, {})
    .then(res => {
      return dispatch({
        type: CREATE_EDITOR,
        payload: res
      })
    })
    .catch(err => {
      return dispatch({
        type: ERROR,
        payload: err.response
      })
    })
}

export const getContributingLawyers = (page, isActive) => dispatch => {
  const url = `/contributors/?${page ? `page=${page}` : ''}${isActive ? `&is_active=${isActive}` : ''}`

  return privateApi
    .get(url)
    .then(res => {
      return dispatch({
        type: GET_CONTRIBUTING_LAWYERS,
        payload: res
      })
    })
    .catch(err => {
      return dispatch({
        type: ERROR,
        payload: err.response
      })
    })
}

export const clearContributingLawyers = () => dispatch => {

  return dispatch({
    type: CLEAR_CONTRIBUTING_LAWYERS,
  });
}

export const getContributingLawyer = id => dispatch => {
  const url = `/contributors/${id}/`
  return privateApi
    .get(url)
    .then(res => {
      return dispatch({
        type: GET_CONTRIBUTING_LAWYER,
        payload: res
      })
    })
    .catch(err => {
      return dispatch({
        type: ERROR,
        payload: err.response
      })
    })
}
export const acceptContributingLawyer = id => dispatch => {
  const url = `/contributor_confirmation/${id}/`
  return privateApi
    .post(url)
    .then(res => {
      return dispatch({
        type: ACCEPT_CONTRIBUTING_LAWYER,
        payload: res
      })
    })
    .catch(err => {
      return dispatch({
        type: ERROR,
        payload: err.response
      })
    })
}

export const deleteContributingLawyer = id => dispatch => {
  const url = `/contributors/${id}/`
  return privateApi
    .delete(url)
    .then(res => {
      return dispatch({
        type: DELETE_CONTRIBUTING_LAWYER,
        payload: {id, status: res.status},
      })
    })
    .catch(err => {
      return dispatch({
        type: ERROR,
        payload: err.response
      })
    })
};

export const uploadAboutFile = (data, callback) => dispatch => {
  const url = '/about/';
  dispatch({ type: CREATE_ABOUT_FILE_IN_PROGRESS, });
  return privateApi
      .post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: progressEvent => callback(progressEvent)
      })
      .then(res => {
        return dispatch({
          type: CREATE_ABOUT_FILE_SUCCEED,
          payload: res
        });
      })
      .catch( err => {
        return dispatch({
          type: CREATE_ABOUT_FILE_FAILED,
          payload: err,
        });
      });
};

export const getAbout = () => dispatch => {
  const url = '/about/';
  dispatch({ type: GET_ABOUT_IN_PROGRESS, });
  return publicApi
      .get(url)
      .then(res => {
        const data = {};
        if (res.data.results.length){
          res.data.results.map(file => {
            file.file_type === 1? data.privacy_policy = file: data.terms_of_use = file;
          });
        }
        return dispatch({
          type: GET_ABOUT_SUCCEED,
          payload: data,
        });
      })
      .catch( err => {
        return dispatch({
          type: GET_ABOUT_FAILED,
          payload: err,
        });
      });
};

export const deleteAboutFile = id => dispatch => {
  const url = `/about/${id}/`;
  return privateApi
      .delete(url)
      .then(res => {
        return dispatch({
          type: DELETE_ABOUT_SUCCEED,
          payload: 'data',
        });
      })
      .catch( err => {
        return dispatch({
          type: DELETE_ABOUT_FAILED,
          payload: err,
        });
      });
};
