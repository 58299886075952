import Modal from "react-responsive-modal";
import { modalBody } from "../../../helpers/modalBody";
import MainButton from "../../shared/MainButton";
import { useState } from "react";
import modalMap from "../../shared/modalMap";

const LoginButton = ({
  lawyerModal,
  lawyerToken,
  handleClick,
  lawyerConfirm,
}) => {
  const [currentModal, setCurrentModal] = useState(null);
  const onCloseModal = () => setCurrentModal(null);
  // Purposely only taking the first arg to pass along to setCurrentModal so
  // that I can avoid passing other unwanted stuff during this frankenstein
  // code refactor.
  const onOpenModal = (modalName) => setCurrentModal(modalName);

  // const onClick = () => onOpenModal("modalSignIn", SignIn);
  const onClick = () => setCurrentModal("modalSignIn");

  const ModalComponent = modalMap[currentModal];

  return (
    <>
      <MainButton onClick={onClick}>ACCOUNT LOG IN</MainButton>
      {currentModal && (
        <Modal open onClose={onCloseModal}>
          {modalBody(
            ModalComponent,
            onCloseModal,
            lawyerModal,
            lawyerToken,
            handleClick,
            onOpenModal,
            lawyerConfirm
          )}
        </Modal>
      )}
    </>
  );
};
export default LoginButton;
