const colors = {
  blue: "#32afe3",
  blueDark: "#193e66",
  blueHover: "#32afe38c",
  blueLight: "#dcf1fa",
  gray: "#8d8e8e",
  grayLight: "#eeeeee",
  red: "#ef4237",
};

const fontFamilies = {
  serif: "Libre Baskerville",
};

const maxContentWidth = "1200px";
const mainContentWidth = "1000px";
const titleWidth = "880px";

export { colors, fontFamilies, maxContentWidth, mainContentWidth, titleWidth };
