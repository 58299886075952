import React, {Component} from "react";
import Modal from 'react-responsive-modal';
import * as ModalsContent from './documentEditor/modalContents';

export default class SaveModal extends Component {

    render() {
        const {open , onClose, modalName, error} = this.props;

        return (
            <Modal
                onClose={() => onClose(`${modalName}Open`)}
                open={modalName && open || false}
            >
                <div className="popup-content">
                    {
                        error? (
                          <p className='error'>{error}</p>
                        ):(<>
                            {ModalsContent[modalName]}
                        </>)
                    }
                </div>
            </Modal>
        );
    }
}

export const DeleteModal = ({ openDeleteModal, onCloseDelModal, handleDelete, content, error }) => {
  return (
    <Modal open={openDeleteModal} onClose={onCloseDelModal} center>
        <div className="popup-content">
            <form action="">
                <p className="fs-lg text-xs-left color-black-60 mt-5 ml-2">{content}</p>
                <div className="buttons mt-10 text-xs-right">
                    <button
                      type="button"
                      className="btn filled primary br-round h-lg w-md fs-md shadow ml-3"
                      onClick={handleDelete}
                    >
                        Delete
                    </button>
                    <button
                      className="btn link primary h-lg w-md fs-md"
                      onClick={onCloseDelModal}
                      type="button"
                    >
                        Cancel
                    </button>
                </div>
                {/*{error && <p>{error}</p>}*/}
            </form>
        </div>
    </Modal>
  );
};