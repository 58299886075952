import styled from "styled-components";
import Search from "./Search";
import Filter from "./Filter";
import DocumentList from "./DocumentList";

const Container = styled.div``;

const DocumentsWithSearchAndFilter = ({
  resources,
  lowAreas,
  getResourceTypes,
  getLowAreas,
  resourcesCount,
  lawCount,
  showresults,
  resourcesPage,
  lowPage,
  isLogedOut,
  handleQuery,
  searchResults,
  searchValue,
  onHandleChange,
  documents,
  getDocumentId,
  token,
  handleUnAutorizeClick,
  modalDocuments,
  component,
  modalBody,
  onCloseModal,
  is_loading,
}) => {
  return (
    <Container>
      <Search
        searchResults={searchResults}
        searchValue={searchValue}
        onHandleChange={onHandleChange}
      />
      <Filter
        resources={resources}
        lowAreas={lowAreas}
        getResourceTypes={getResourceTypes}
        getLowAreas={getLowAreas}
        resourcesCount={resourcesCount}
        lawCount={lawCount}
        showresults={showresults}
        resourcesPage={resourcesPage}
        lowPage={lowPage}
        isLogedOut={isLogedOut}
        handleQuery={handleQuery}
      />
      <DocumentList
        documents={documents}
        getDocumentId={getDocumentId}
        searchResults={searchResults}
        token={token}
        handleUnAutorizeClick={handleUnAutorizeClick}
        modalDocuments={modalDocuments}
        component={component}
        modalBody={modalBody}
        onCloseModal={onCloseModal}
        onHandleChange={onHandleChange}
        showresults={showresults}
        searchValue={searchValue}
        is_loading={is_loading}
      />
    </Container>
  );
};

export default DocumentsWithSearchAndFilter;
