import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../../styles";
import MainButton from "../../shared/MainButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
  padding-top: 16px;
  height: 328px;
`;
const Text = styled.span``;
const Feature = styled.div`
  font-size: 14px;
  font-weight: 700;
  padding-left: 40px;
  padding-right: 16px;
`;
const PurchaseButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 26px;
  flex: 1 0 auto;
`;

const features = [
  <span>
    Access to <i>one</i> document for 10 days
  </span>,
  "View drafting tips for the document",
  "Download or print as Word/PDF",
  "One-time fee",
];

const OneTimeFeatures = () => {
  const history = useHistory();

  return (
    <Container>
      {features.map((item) => (
        <Feature key={item}>
          <Text>{item}</Text>
        </Feature>
      ))}
      <PurchaseButtonWrapper>
        <MainButton color={colors.red} onClick={() => history.push("/")}>
          BROWSE DOCUMENTS
        </MainButton>
      </PurchaseButtonWrapper>
    </Container>
  );
};

export default OneTimeFeatures;
