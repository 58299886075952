import React, { Component, Fragment } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";


export default class Resources extends Component {
  state = {
    resources: {},
    checked: false,
    fromButtonChild: false,
    scrolled: 0
  };

  settings = {
    title: {
      resources: "document type",
      lowAreas: "legal area"
    },
    query: {
      resources: "resource_type=",
      lowAreas: "law_area="
    }
  };

  handleChange = async e => {
    await this.setState({
      ...this.state,
      resources: {
        ...this.state.resources,
        [e.target.id]: e.target.checked ? e.target.value : null
      }
    });
    const query = this.paramsQuery(this.state.resources);
    this.props.handleQuery(query, this.props.type);
  };
  paramsQuery = params => {
    const query = [];
    for (let elem in params) {
      if (params[elem]) {
        query.push(`${this.settings.query[this.props.type]}${params[elem]}`);
      }
    }
    return query;
  };

  renderResources = (resources, showresults, method, resourcesCount, page) => {

    return (
      <Fragment>

        <PerfectScrollbar
          style={{ height: "500px" }}
          onYReachEnd={() => {
            if (resources.length < resourcesCount) {
              method(page);
            }
          }}
        >
          {resources.map(data => {
            return (
              <div className="card br-corner manage-file" key={data.id}>
                <div className="content">
                  <h4 className="title">{data.name}</h4>
                </div>
                <ul className="actions">
                  <li>
                    <button className="btn-icon delete">
                      <i
                        className="icon-delete"
                        onClick={() => this.props.onOpenModalDel(data.id)}
                      >
                        {""}
                      </i>
                    </button>
                  </li>
                </ul>
              </div>
            );
          })}
        </PerfectScrollbar>
      </Fragment>
    );
  };
  render() {
    const { resources, showresults, method, resourcesCount, page } = this.props;
    return this.renderResources(
      resources,
      showresults,
      method,
      resourcesCount,
      page
    );
  }
}

