import React from 'react'
import { Link, withRouter } from 'react-router-dom'

const documents = props => {
  const { path, role } = props;
  const user = (role === '2') ? '/admin' : (role === '4') ? '/lawyer' : '/editor';

  return (
    <ul className="nav">
      <li className="title">Documents</li>
      <li className={''}>
        <Link
          to={{ pathname: `${user}/new-document`, state: 'newDoc' }}
          className={`${path === `${user}/new-document` ? 'active' : ''}`}
        >
          <i className="icon-plus"></i> Create Document
        </Link>
      </li>
      <li>
        <Link
          to={user}
          className={`${path === user ? 'active' : ''}`}
        >
          <i className="icon-upload"></i>Import document
        </Link>
      </li>
      <li>
        <Link
          to={`${user}/documents`}
          className={`${path === `${user}/documents` ? 'active' : ''}`}
        >
          <i className="icon-doc"></i>Documents
        </Link>
      </li>
      <li>
        <Link
          to={`${user}/pending-documents`}
          className={`${path === `${user}/pending-documents` ? 'active' : ''}`}
        >
          <i className="icon-clock"></i>Pending Documents
        </Link>
      </li>
      <li>
        <Link
          to={user + '/popular'}
          className={`${path === `${user}/popular` ? 'active' : ''}`}
        >
          <i className="icon-star"></i>Most Popular
        </Link>
      </li>
    </ul>
  )
}

export default withRouter(documents)