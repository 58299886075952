import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import {
    getResourceTypes,
    createResourceTypes,
    deleteResourceType,
    clearResourceTypes
} from "./../../store/actions/documents";

import TemplateTypes from "./templateofTypes";

class resourceTypes extends Component {
    state = {
        open: false,
        resources: "",
        hasMore: true,
        resourceName: "",
        deleteId: null,
        openDel: false,
        error: "",
    };

    componentDidMount() {
        this.props.getResourceTypes();
    }

    onOpenModal = () => {
        this.setState({open: true, error: "", resourceName: "", fromButton: true});
    };

    onCloseModalDel = () => {
        this.setState({deleteId: null, openDel: false,});
    };

    onOpenModalDel = id => {
        this.setState({deleteId: id, openDel: true, error: ""});
    };

    onCloseModal = () => {
        this.setState({open: false, fromButton: false});
    };

    addResource = async type => {
        const {resourceName} = this.state;
        if (resourceName && resourceName.length <= 200) {
            const res = await this.props.createResourceTypes({
                name: resourceName
            });
            if (res.payload && res.payload.status === 201) {
                this.props.clearResourceTypes();
                const resource = await this.props.getResourceTypes();
                if(resource && resource.payload && resource.payload.status === 200){
                    this.setState({
                        resourceName: "",
                        open: false,
                    });
                }
            }else {
                this.setState({ error: "Two document types can't have the same name." });
            }
        } else {
            this.setState({
                error: !this.state.resourceName
                    ? "This filed is required"
                    : "Maximum number of symbols is 200."
            });
        }
    };

    deleteResource = async id => {
        const res = await this.props.deleteResourceType(id);
        if (res.payload && res.payload.status === 204) {
            this.props.clearResourceTypes();
            const response = await this.props.getResourceTypes();
            if (response && response.payload && response.payload.status === 200) {
                await this.setState({
                    resourceName: ""
                });
                this.onCloseModalDel();
            }
        } else {
            this.setState({error: Object.values(res.payload.data)[0]});
        }
    };
    handleChange = e => {
        this.setState({
            error: "",
            resourceName: e.target.value
        });
    };

    render() {
        const resourcesCount = this.props.resources.count;

        const query = this.props.resources.allResources && {
            resources: [...this.props.resources.allResources]
        };
        let index =
            this.props.resources.next && this.props.resources.next.indexOf("=");
        const page =
            this.props.resources.next && (this.props.resources.next.substr(index + 1));

        return (
            /* Resource types page start */
            <div>
                <TemplateTypes
                    text="DOCUMENT TYPES"
                    type="resources"
                    modalText="ADD NEW DOCUMENT TYPE"
                    placeholder="New Document type name"
                    stateValue={this.state}
                    query={query}
                    resourcesCount={resourcesCount}
                    handleChange={this.handleChange}
                    addResource={this.addResource}
                    deleteResource={this.deleteResource}
                    page={page}
                    method={this.props.getResourceTypes}
                    onOpenModal={this.onOpenModal}
                    onCloseModal={this.onCloseModal}
                    onOpenModalDel={this.onOpenModalDel}
                    onCloseModalDel={this.onCloseModalDel}
                />
            </div>
            /* Resource types page end */
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        resources: state.documents.resources
    };
}

export default connect(
    mapStateToProps,
    {
        createResourceTypes,
        getResourceTypes,
        deleteResourceType,
        clearResourceTypes
    }
)(withRouter(resourceTypes));
