import { GoogleOAuthProvider } from "@react-oauth/google";
import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Homepage from "./components/Homepage/homePage/homepage";
import searchResults from "./components/Homepage/searchResults";
import ForgotPassword from "./components/Homepage/forgot-password/forgotPassword";
import Admin from "./components/admin";
import Lawyer from "./components/lawyer";
import User from "./components/user/";
import Editor from "./components/editor/";
import Pricing from "./components/Homepage/pricing/pricing";
import OrderDetails from "./components/user/orderDetails";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import NoMatch from "./helpers/NoMatch.js";
import windowScroll from "./helpers/document.js";
import requireAuth from "./helpers/requireAuth";
import DocumentPage from "./DocumentPage";
import FaqPage from "./FaqPage";
import ContactPage from "./ContactPage";
import SitePasswordModal from "./SitePasswordModal";
import { GOOGLE_CLIENT_ID, SITE_PASSWORD } from "./constants";
import ScrollToTop from "./ScrollToTop";
import SaasAgreement from "./SaasAgreement";
import PrivacyPolicy from "./PrivacyPolicy";

class App extends React.Component {
  componentDidMount() {
    windowScroll();
  }

  render() {
    if (
      SITE_PASSWORD &&
      SITE_PASSWORD !== sessionStorage.getItem("savedSitePassword")
    ) {
      return <SitePasswordModal />;
    }

    return (
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/document/:id" component={DocumentPage} />
          <Route exact path="/confirmation/:token" component={Homepage} />
          <Route exact path="/reset_pass/:token" component={Homepage} />
          <Route
            exact
            path="/contributor_verification/:token"
            component={Homepage}
          />
          <Route
            exact
            path="/user_confirmation/lawyer/:token"
            component={Homepage}
          />
          <Route
            exact
            path="/user_confirmation/editor/:token"
            component={Homepage}
          />
          <Route exact path="/email_update/:token" component={Homepage} />
          <Route exact path="/linkedin" component={LinkedInCallback} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/search-results" component={searchResults} />
          <Route path="/admin" component={Admin} />
          <Route path="/lawyer" component={Lawyer} />
          <Route path="/editor" component={Editor} />
          <Route path="/user/profile" component={User} />
          <Route path="/user/order" component={OrderDetails} />
          <Route path="/pricing" component={Pricing} />
          <Route exact path="/faq" component={FaqPage} />
          <Route exact path="/contact" component={ContactPage} />
          <Route exact path="/legal" component={SaasAgreement} />
          <Route exact path="/privacy" component={PrivacyPolicy} />
          <Route component={NoMatch} />
        </Switch>
        <ToastContainer />
      </GoogleOAuthProvider>
    );
  }
}

export default withRouter(requireAuth(App));
