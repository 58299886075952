import React from 'react'
import { Route } from 'react-router-dom'
import profile from './profile'
import header from '../common/profileHeader'
import Footer from '../Homepage/footer'

const RouteUser = () => {
  return (
    <>
      <Route path='/user/profile' component={header}/>
      <Route path="/user/profile" component={profile}/>
      <Route path="/user/profile" component={Footer}/>
    </>
  )
}

export default RouteUser