import React, { Component } from "react";
import { Link } from "react-router-dom";

const BreadcrumbsDashboard = ({ type }) => {
    return (
      <ul className="breadcrumbs dashboard">
        <li>
          <Link to={`/admin/${(type === 'user')? 'users': 'contribute-lawyers'}`}>
            {`${type === 'user'? 'Users' :'Contributing lawyers'}`}
          </Link>
        </li>
        <li>
          <a href="#" className="active">{`${type === 'user'? 'User' :'Contributing Lawyer'}`} Information </a>
        </li>
      </ul>
    );
};

export default BreadcrumbsDashboard;
