import styled from "styled-components";
import Footer from "./components/Homepage/footer";
import Header from "./components/Homepage/header";
import TitleBar from "./components/shared/TitleBar";
import { mainContentWidth } from "./styles";

const docFontSize = "11pt";
const docfontFamily = '"Calibri", sans-serif';
const indentSize = "30pt";

const MainContent = styled.div`
  max-width: ${mainContentWidth};
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Document = styled.div`
  margin: 32px 0;
  max-width: 6.5in;
  display: flex;
  flex-direction: column;
  gap: ${docFontSize};
`;

const P = styled.p`
  font-size: ${docFontSize};
  font-family: ${docfontFamily};
`;

const Indent = styled.span`
  width: ${indentSize};
  display: inline-block;
  font-size: ${docFontSize};
  font-family: ${docfontFamily};
`;

const U = styled.span`
  text-decoration: underline;
  font-size: ${docFontSize};
  font-family: ${docfontFamily};
`;

const PrivacyPolicy = () => {
  return (
    <div>
      <Header />

      <TitleBar
        topMargin="160px"
        title="Privacy Policy"
        backgroundImage="/jd_form_hero_mountain.png"
      />

      <MainContent>
        <Document>
          <P>Last modified: August 20, 2022</P>

          <P>
            JDForm LLC (“<b>we</b>”) is committed to protecting your privacy
            through this policy.
          </P>

          <P>
            This policy describes the types of information we may collect from
            you or that you may provide when you visit www.JDForm.com (our “
            <b>Site</b>”) and our practices for collecting, using, maintaining,
            protecting, and disclosing that information. This policy applies to
            information we collect on this Site and in email, text, and other
            electronic messages between you and our Site.
          </P>

          <P>
            By accessing or using this Site, you agree to this privacy policy.
            This policy may change from time to time (see{" "}
            <U>Changes to Our Privacy Policy</U>). Your continued use of this
            Site after we make changes is deemed to be acceptance of those
            changes.
          </P>

          <P>
            Our Site is not intended for children under 16 years of age. No one
            under age 16 may provide any information to the Site. We do not
            knowingly collect personal information from children under 16.
          </P>

          <P>
            <U>
              <b>Information We Collect About You and How We Collect It</b>
            </U>
          </P>

          <P>
            We collect several types of information from and about users of our
            Site, including information: (i) by which you may be personally
            identified, such as name, postal address, and email address (“
            <b>personal information</b>”); and (ii) about your internet
            connection, the equipment you use to access our Site, and usage
            details.
          </P>

          <P>
            <U>
              <b>Third-Party Data Collection; Payment Processing</b>
            </U>
          </P>

          <P>
            Some content or applications on our Site are served by
            third-parties, including payment processors and other application
            providers. These third parties may use cookies alone or in
            conjunction with web beacons or other tracking technologies to
            collect information about you when you use our Site. The information
            they collect may be associated with your personal information or
            they may collect information, including personal information, such
            as your name, email, address, phone, city, state, zip code, unique
            payment identifier, and payment provider identifier.
          </P>

          <P>
            <U>
              <b>How We Use Your Information</b>
            </U>
          </P>

          <P>
            We use information that we collect about you or that you provide to
            us, including any personal information: (i) to present our Site and
            its contents to you; (ii) to provide you with information, products,
            or services that you request from us; (iii) to fulfill any other
            purpose for which you provide it; (iv) to carry out our obligations
            and enforce our rights arising from any agreements entered into
            between you and us; (v) to notify you about changes to our Site or
            any products or services we offer or provide though it; and (vi) in
            any other way we may describe when you provide the information.
          </P>

          <P>
            <U>
              <b>Disclosure of Your Information</b>
            </U>
          </P>

          <P>
            We may disclose aggregated information about our users, and
            information that does not identify any individual, without
            restriction.
          </P>

          <P>
            We may disclose personal information that we collect or you provide
            as described in this privacy policy: (i) to our subsidiaries and
            affiliates; (ii) to contractors, service providers, and other third
            parties we use to support our business; (iii) to a buyer or other
            successor in the event of a merger or sale or transfer of some or
            all of JDForm LLC’s assets; (iv) to fulfill the purpose for which
            you provide it or for any other purpose disclosed by us when you
            provide the information; (v) to comply with any court order, law, or
            legal process, including to respond to any government or regulatory
            request; or (vi) to enforce or apply our SaaS Agreement and other
            agreements
          </P>

          <P>
            <U>
              <b>Accessing and Correcting Your Information</b>
            </U>
          </P>

          <P>
            You can review and change your personal information by logging into
            our Website and visiting your account profile page.
          </P>

          <P>
            <U>
              <b>Changes to Our Privacy Policy</b>
            </U>
          </P>

          <P>
            It is our policy to post any changes we make to our privacy policy
            on this page. If we make material changes to how we treat our users’
            personal information, we will notify you by email to the email
            address specified in your account and/or through a notice on the
            Site privacy policy page. The date the privacy policy was last
            revised is identified at the top of the page. You are responsible
            for ensuring we have an up-to-date active and deliverable email
            address for you, and for periodically visiting our Website and this
            privacy policy to check for any changes
          </P>

          <P>
            <U>
              <b>Contact Information</b>
            </U>
          </P>

          <P>
            To ask questions or comment about this privacy policy and our
            privacy practices, contact us at jd@jdform.com
          </P>
        </Document>
      </MainContent>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
