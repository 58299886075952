import React, { Component } from "react";
import { Route } from "react-router-dom";
import { withRouter } from "react-router";
import importPage from "../common/import";
import Documents from "../common/documents";
import resourceTypes from "./resourceTypes";
import areaOfLow from "./areaOfLaw";
import editors from "./editors";
import ContributeLawyers from "./contributeLawyers";
import Users from "./users";
import ContributingLawyersView from "./contributingLawyersView";
import UsersView from "./usersView";
import ProfilePage from "./profile";
import EditDocument from "../common/documentEditor/editDocument";
import Pricing from "./pricing";
import About from "./about";
import Aside from "../common/aside/aside";
import AdminHeader from "../common/profileHeader";
import requireAuth from "../../helpers/requireAuth";
import Footer from "../Homepage/footer";
import { getToken } from "../../helpers/improve";

class RouteAdmin extends Component {
  state = {
    searchValue: "",
    searching: false,
  };

  componentDidMount() {
    if (!getToken()) {
      this.props.history.push("/");
    }
  }

  setSearchValue = (searchValue) => {
    this.setState({ searchValue });
  };

  setSearch = () => {
    const { searching } = this.state;
    this.setState({ searching: !searching });
  };

  render() {
    const { searchValue, searching } = this.state;

    return (
      <>
        <Route
          path="/admin"
          render={(props) => (
            <AdminHeader
              {...props}
              searchValue={this.state.searchValue}
              setSearchValue={this.setSearchValue}
              searching={searching}
              setSearch={this.setSearch}
            />
          )}
        />
        <Route path="/admin" component={Aside} />
        <Route exact path="/admin/" component={importPage} />
        <Route
          path="/admin/documents"
          render={(props) => (
            <Documents
              {...props}
              searchValue={searchValue}
              searching={searching}
              setSearch={this.setSearch}
            />
          )}
        />
        <Route path="/admin/resource-types" component={resourceTypes} />
        <Route path="/admin/area-of-low" component={areaOfLow} />
        <Route path="/admin/edit-document/:id" component={EditDocument} />
        <Route path="/admin/new-document" component={importPage} />
        <Route path="/admin/pending-documents" component={Documents} />
        <Route path="/admin/contribute-lawyers" component={ContributeLawyers} />
        <Route path="/admin/users" component={Users} />
        <Route
          path="/admin/contributing-lawyers-view/:id"
          component={ContributingLawyersView}
        />
        <Route path="/admin/users-view/:id" component={UsersView} />
        <Route path="/admin/editors" component={editors} />
        <Route path="/admin/profile" component={ProfilePage} />
        <Route path="/admin/about" component={About} />
        <Route path="/admin/pricing" component={Pricing} />
        <Route path="/admin" component={Footer} />
      </>
    );
  }
}

export default withRouter(requireAuth(RouteAdmin));
