// auth
export const AUTH_ERROR = "auth_error";
export const SIGN_UP_USER = "sign_up_user";
export const SIGN_IN_USER = "sign_in_user";
export const CONFIRM_USER = "confirm_user";
export const REMOVE_TOKEN = "remove_token";
export const FORGOT_PASSWORD = "forgot_password";
export const RESET_PASSWORD = "reset_password";
export const SAVE_RESET_TOKEN = "save_reset_token";
export const CONTRIBUTOR_VERIFICATION = "contributor_verification";
export const SIGN_UP_STEPPER_DATA = "sign_up_stepper_data";
export const PROFILE_INFO = "profile_info";
export const UPDATE_CONTRIBUTOR = "update_contributor";
export const UPDATE_USER = "update_user";
export const USER_PROFILE_INFO = "user_profile_info";
export const CHANGE_PASSWORD = "change_password";
export const LOGGED_IN = "logged_in";
export const LOGGED_OUT = "logged_out";

// documents
export const GET_DOCUMENTS_START = "get_documents_start";
export const GET_DOCUMENTS = "get_documents";
export const GET_DOCUMENTS_FINISHED = "get_documents_finished";
export const GET_RESOURCES = "get_resources";
export const GET_LOW_AREAS = "get_low_areas";
export const GET_LOW_AREAS_BY_ID = "get_low_areas_by_id";
export const GET_RESOURCES_BY_ID = "get_low_resources_id";
export const GET_DOCUMENT_HTML = "get_document_html";
export const GET_DOCUMENT_BY_ID = "get_document_by_id";
export const CREATE_LOW_AREAS = "create_low_areas";
export const DELETE_LOW_AREAS = "delete_low_areas";
export const CREATE_DOCUMENTS = "create_documents";
export const CREATE_RESOURCES = "create_resources";
export const DELETE_RESOURCES = "delete_resources";
export const ERROR = "error";
export const CLEAR_LOWAREAS = "clear_lowareas";
export const CLEAR_RESOURCES = "clear_resources";
export const CLEAR_DOCUMENTS = "clear_documents";

// admin
export const DOCUMENT_UPDATED = "document_updated";
export const DOCUMENT_DELETED = "document_deleted";
export const CREATE_EDITOR = "create_editor";
export const CREATE_LAWYER = "create_lawyer";
export const GET_EDITORS = "get_editor";
export const GET_USERS = "get_users";
export const GET_USER = "get_user";
export const DELETE_EDITORS = "delete_editors";
export const DELETE_USERS = "delete_users";
export const CONFIRM_EDITOR = "confirm_editor";
export const EDITOR_CONFIRM_DATA = "editor_confirm_data";
export const CONTRIBUTOR_CONFIRM_DATA = "lawyer_confirm_data";
export const GET_CONTRIBUTING_LAWYERS = "get_contriubting_lawers";
export const GET_CONTRIBUTING_LAWYER = "get_contriubting_lawer";
export const ACCEPT_CONTRIBUTING_LAWYER = "accept_contriubting_lawer";
export const DELETE_CONTRIBUTING_LAWYER = "delete_contriubting_lawer";
export const CLEAR_CONTRIBUTING_LAWYERS = "clear_contriubting_lawers";

export const CREATE_ABOUT_FILE_IN_PROGRESS = 'create_about_file_in_progress';
export const CREATE_ABOUT_FILE_SUCCEED = 'create_about_file_succeed';
export const CREATE_ABOUT_FILE_FAILED = 'create_about_file_failed';

export const GET_ABOUT_IN_PROGRESS = 'get_about_in_progress';
export const GET_ABOUT_SUCCEED = 'get_about_succeed';
export const GET_ABOUT_FAILED = 'get_about_failed';

export const DELETE_ABOUT_SUCCEED = 'delete_about_succeed';
export const DELETE_ABOUT_FAILED = 'delete_about_failed';

// payment
export const GET_PLANS = 'get_plans';
export const GET_PLAN = 'get_plan';
export const GET_PLAN_BENEFITS = 'get_plan_benefits';
export const GET_USER_CARD = 'get_user_card';
export const ADD_USER_CARD = 'add_user_card';
export const DELETE_USER_CARD = 'delete_user_card';
export const SUBSCRIBE_SUCCEED = 'subscribe-succeed';
export const SUBSCRIBE_FAILURE = 'subscribe-failure';
export const GET_SUBSCRIPTION = 'get_subscription';
export const REMOVE_SUBSCRIPTION = 'remove_subscription';
export const SUBSCRIBE_FAILED = 'subscribe_failed';
