import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withFormik } from "formik";

import { signupUser } from "../../../store/actions/auth";

import Login from "../login/login";
import UserSignUp from "./userSignUp";
import ContributorSignUp from "./contributorSignUp";
import ContributurStepTwo from "./contributurStepTwo";

import { HaveAccount } from "./haveAccount";

class SignUp extends Component {
  state = {
    component: "signUp",
    selected: "1",
    success: false,
    firstStep: true,
    firstStepData: null,
    openSuccess: false,
    allData: {},
    contributorData: null,
  };

  componentDidMount() {
    if (this.props.isLawyerSignUp) {
      this.setState({
        selected: "4",
        contributorData: this.props.contributorData,
      });
    }
  }

  handleSelect = async (select) => {
    await this.props.setValues({
      ...this.props.values,
      organization_type: select.value,
    });
  };

  handleComponent = (e, component) => {
    e.preventDefault();
    this.setState({ component });
  };

  handleChange = (e) => {
    this.setState({ selected: e.target.value });
  };

  handleFirstStep = (firstStepData) => {
    this.setState((prevState) => ({
      firstStep: !prevState.firstStep,
      firstStepData,
    }));
  };

  handleSecondStep = (allData) => {
    this.setState((prevState) => ({
      firstStep: !prevState.firstStep,
    }));
  };

  openSuccess = () => {
    this.setState((prevState) => ({ openSuccess: !prevState.openSuccess }));
  };

  render() {
    const {
      component,
      selected,
      firstStep,
      firstStepData,
      contributorData,
      openSuccess,
    } = this.state;

    return (
      <Fragment>
        {component === "signUp" && (
          <div className="popup-content">
            {!openSuccess && <h3 className="popup-title">Create an account</h3>}
            {selected === "1" && (
              <UserSignUp
                onClose={this.props.onClose}
                openSuccess={this.openSuccess}
                handleComponent={this.handleComponent}
              />
            )}

            {selected === "4" && firstStep && (
              <ContributorSignUp
                handleFirstStep={this.handleFirstStep}
                handleComponent={this.handleComponent}
                contributorData={this.state.contributorData}
              />
            )}

            {selected === "4" && !firstStep && !this.props.lawyerConfirm && (
              <ContributurStepTwo
                handleClick={this.props.handleClick}
                lawyerToken={this.props.lawyerToken}
                firstStepData={firstStepData}
                handleSecondStep={this.handleSecondStep}
                onClose={this.props.onClose}
                onOpenModal={this.props.onOpenModal}
                openSuccess={this.openSuccess}
                handleComponent={this.handleComponent}
              />
            )}
            {/* <ContributorSignUp /> */}
            {/* <ContributurStepTwo /> */}
          </div>
        )}

        {component === "login" && <Login onClose={this.props.onClose} />}
      </Fragment>
    );
  }
}

const SignUpFormik = withFormik({
  mapPropsToValues({ role }) {
    return {
      role: role || "1",
    };
  },
})(SignUp);

const mapStateToProps = ({ auth }) => {
  return {
    contributorData:
      auth && auth.contributorConfirmData && auth.contributorConfirmData.data,
  };
};

export default connect(mapStateToProps, { signupUser })(SignUpFormik);
