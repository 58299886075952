import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withFormik, Form, Field } from 'formik'
import { changePassword } from '../store/actions/auth'
import * as Yup from 'yup'

class ChangePassword extends Component {
  render () {


    const { values, errors, touched } = this.props

    return (
      <div className="popup-content">
        <h3 className="popup-title">CHANGE PASSWORD</h3>
        <Form>
          <div className="text-field br-corner"/>
          <div
            className={`text-field br-corner ${touched.old_password &&
            errors.old_password &&
            'invalid'}`}
          >
            <Field
              type="password"
              placeholder="Current password*"
              name="old_password"
              value={values.old_password}
            />
            {touched.old_password && errors.old_password && (
              <p className="error-msg">{errors.old_password}</p>
            )}
          </div>
          <div className={`text-field br-corner ${touched.new_password && errors.new_password && 'invalid'}`}>
            <Field
              type="password"
              placeholder="New password*"
              name="new_password"
              value={values.new_password}
            />
            {touched.new_password && errors.new_password && (
              <p className="error-msg">{errors.new_password}</p>
            )}
          </div>
          <div className={`text-field br-corner ${touched.confirmPassword && errors.confirmPassword && 'invalid'}`}>
            <Field
              type="password"
              placeholder="Repeat new password*"
              name="confirmPassword"
              value={values.confirmPassword}
            />
            {touched.confirmPassword && errors.confirmPassword && (
              <p className="error-msg">{errors.confirmPassword}</p>
            )}
          </div>

          <button
            className="btn filled primary br-round h-lg shadow w-lg mt-6"
            type={'submit'}
            onClick={e => {
              e.preventDefault()
              this.props.handleSubmit()
            }}
          >
            SUBMIT
          </button>
        </Form>
      </div>
    )
  }
}

const ProfileFormik = withFormik({
  mapPropsToValues ({ old_password, new_password, confirmPassword }) {
    return {
      new_password: new_password || '',
      old_password: old_password || '',
      confirmPassword: confirmPassword || ''
    }
  },
  validationSchema: Yup.object().shape({
    old_password: Yup.string()
    .min(6, 'The Password field must be 6 or more characters.')
    .max(20, 'Please provide a valid password.')
    .required('This field is mandatory.'),
    new_password: Yup.string()
    .min(6, 'The Password field must be 6 or more characters.')
    .max(20, 'Please provide a valid password.')
    .required('This field is mandatory.'),
    confirmPassword: Yup.string()
    .required('This field is mandatory.')
    .oneOf(
      [Yup.ref('new_password')],
      'The confirm password confirmation does not match.'
    )
  }),

  async handleSubmit (values, { props, setErrors, setValues }) {
    const data = {
      old_password: values.old_password,
      new_password: values.new_password
    };
    const response = await props.changePassword(data);
    if (response && response.payload.status === 204) {
      props.onPasswordSuccess()
    } else if (response && response.payload.status === 404) {
      setErrors({ old_password: 'Password is not correct.' })
    } else {
      setErrors({ old_password: response.payload.data.message })
    }
  }
})(ChangePassword)

function mapStateToProps (state) {
  return {
    profile: state.auth.profile
  }
}

export default connect(
  mapStateToProps,
  { changePassword }
)(ProfileFormik)
