const BASE_URL = process?.env?.REACT_APP_BASE_URL;
const FRONTEND_BASE_URL = process?.env?.REACT_APP_FRONTEND_BASE_URL;
const GOOGLE_CLIENT_ID = process?.env?.REACT_APP_GOOGLE_CLIENT_ID;
const LINKEDIN_CLIENT_ID = process?.env?.REACT_APP_LINKEDIN_CLIENT_ID;
const SITE_PASSWORD = process?.env?.REACT_APP_SITE_PASSWORD;
const STRIPE_PUBLISHABLE_KEY = process?.env?.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export {
  BASE_URL,
  FRONTEND_BASE_URL,
  GOOGLE_CLIENT_ID,
  LINKEDIN_CLIENT_ID,
  SITE_PASSWORD,
  STRIPE_PUBLISHABLE_KEY,
};
