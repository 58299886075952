import React from 'react';
import { Link } from 'react-router-dom';


/**
 * @description Component for not found page.
 */
export default function NoMatch () {
  return (
    <div id="not-found">
      <div className="not-found">
        <h4>Oops! Page not found</h4>
        <h1>404</h1>
        <p>We are sorry, but the page you requested was not found.</p>
        <button className="btn filled primary h-sm br-round shadow w-md">
          <Link to='/'>
            Go back
          </Link>
        </button>
      </div>
    </div>
  );
};