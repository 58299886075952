import React, { Component } from "react";
import { Route } from "react-router-dom";
import { getToken, handleLoguot } from "../../helpers/improve.js";
import profile from "./profile";
import aside from "../common/aside/aside";
import EditorHeader from "../common/profileHeader";
import importPage from "../common/import";
import Documents from "../common/documents";
import Document from "../common/document";
import EditDocument from "../common/documentEditor/editDocument";

class RouteEditor extends Component {
  state = {
    searchValue: "",
  };

  componentDidMount() {
    if (!getToken()) {
      handleLoguot();
      this.props.history.push("/");
    }
  }

  setSearchValue = (searchValue) => {
    this.setState({ searchValue });
  };

  render() {
    const { searchValue } = this.state;

    return (
      <>
        <Route path="/editor" component={aside} />
        <Route
          path="/editor"
          render={(props) => (
            <EditorHeader
              {...props}
              searchValue={searchValue}
              setSearchValue={this.setSearchValue}
            />
          )}
        />
        <Route exact path="/editor" component={importPage} />
        <Route
          path="/editor/documents"
          render={(props) => <Documents {...props} searchValue={searchValue} />}
        />
        <Route
          path="/editor/view-document/:id"
          render={(props) => <Document {...props} searchValue={searchValue} />}
        />
        <Route path="/editor/pending-documents" component={Documents} />
        <Route path="/editor/document" component={document} />
        <Route path="/editor/edit-document/:id" component={EditDocument} />
        <Route path="/editor/profile" component={profile} />
      </>
    );
  }
}

export default RouteEditor;
