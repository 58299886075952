import styled from "styled-components";
import { colors } from "../../styles";

const Container = styled.button`
  display: flex;
  align-items: center;
`;

const Circle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: ${colors.blue};
  margin-right: 8px;
`;

const Text = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0.35px;
  color: ${colors.blue};
`;

const DocButton = ({ children, onClick }) => {
  return (
    <Container onClick={onClick} className="btn link primary w-sm h-sm fw-600">
      <Circle />
      <Text>{children}</Text>
    </Container>
  );
};

export default DocButton;
