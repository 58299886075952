import React from "react";
import Select from "react-select";

class ReactSelect extends React.Component {
    state = {
        selectedOption: null,
        openClassName: false,
        disabled: false,
        value: [],
    };

  handleChange = value => {
        const {parent} = this.props;
        if ( this.props.name === 'is_licensed' && parent && parent === 'contributor'){
          this.props.onChangeLicensed(value)
        }
        this.props.onChange(this.props.name, value);
    };


    handleBlur = () => {
        this.props.onBlur(this.props.name, true);
    };

    onMenuOpen = () => {
        this.setState(prevState => {
            return {openClassName: !prevState.openClassName};
        });
    };

    onMenuClose = () => {
        this.setState(prevState => {
            return {openClassName: !prevState.openClassName};
        });
    };

    render() {
        const { errors, touched, options, placeholder, value } = this.props;
        const { openClassName } = this.state;

        return (
            <div
                className={`select-box br-corner ${touched && errors && "invalid"} ${
                    openClassName ? "open" : ""
                }`}
            >
                <Select
                    clearable="true"
                    isDisabled={this.props.isDisabled}
                    placeholder={placeholder}
                    value={this.props.isDisabled? "": value}
                    isMulti={this.props.name === "jurisdictions"}
                    options={options}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    onMenuOpen={this.onMenuOpen}
                    onMenuClose={this.onMenuClose}
                    className="react-select-container"
                    classNamePrefix="react-select"
                />
                {touched && errors && <p className="error-msg">{errors}</p>}
            </div>
        );
    }
}

export default ReactSelect;
