import React, { Component, Fragment } from "react";
import styled from "styled-components";
import Login from "../Homepage/login/login";
import SignUp from "../Homepage/register/signUp";

const LinkRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

class SignUpFirst extends Component {
  state = {
    component: "signUpFirst",
    openSuccess: false,
  };

  handleComponent = (e, component) => {
    e.preventDefault();
    this.setState({ component });
  };

  openSuccess = () => {
    this.setState((prevState) => ({ openSuccess: !prevState.openSuccess }));
  };

  render() {
    const { component, openSuccess } = this.state;

    return (
      <Fragment>
        {component === "signUpFirst" && (
          <div className="popup-content">
            {!openSuccess && (
              <h3 className="popup-title">Create an account to purchase</h3>
            )}
            <p>
              To purchase this document, please create an account. After your
              account is created, you will be able to complete your purchase.
            </p>
            <LinkRow>
              <a
                href=""
                className="color-primary"
                onClick={(e) => this.handleComponent(e, "signUp")}
              >
                {" "}
                Create an account
              </a>
              <a
                href=""
                className="color-primary"
                onClick={(e) => this.handleComponent(e, "login")}
              >
                {" "}
                Log into existing account
              </a>
            </LinkRow>
          </div>
        )}
        {component === "login" && <Login onClose={this.props.onClose} />}
        {component === "signUp" && <SignUp onClose={this.props.onClose} />}
      </Fragment>
    );
  }
}

export default SignUpFirst;
