import React from "react";
import Select from "react-select";
import Option from "./option";
import {resourcePaginate} from "../../helpers/resourcePaginate";

class ReactSelect extends React.Component {
  state = {
    selectedOption: null,
  };
  handleChange = selectedOption => {
    this.setState({selectedOption});
    // this.props.onChange(this.props.type, selectedOption);
    this.props.onChange(this.props.type, selectedOption);
  };

  handleBlur = () => {
    this.props.onBlur(this.props.type, true);
  };

  render() {
    const {
      options,
      placeholder,
      value,
      values,
      touched,
      errors,
      component,
      defaultValue,
      next,
      method,
      page,
      hideLabel
    } = this.props;

    const resourcesCount = options.length;
    const right = component === 'right';
    const left = component === 'left';

    return (
      <div
        className={`select-box br-corner 
        ${(right || left) ? 'w-50' : null}
        ${right ? 'ml-3' : left ? 'mr-3' : null} 
        ${touched && errors && "invalid"} `}
      >
        {
          this.props.type && !hideLabel && (
            <label>
              {this.props.type === 'resource_type' ? 'Document type*' : 'Legal area*'}
            </label>
          )}

        <Select
          clearable="true"
          defaultValue={defaultValue}
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          options={options}
          placeholder={placeholder}
          menuIsOpen={this.props.IsOpen}
          className="react-select-container"
          classNamePrefix="react-select"
          onMenuScrollToBottom={e => {
            page && method(page);
          }}
        />
        {touched && errors && <p className="error-msg">{errors}</p>}
      </div>
    );
  }
}

export default ReactSelect;

